"use client";

import { useCallback, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcLocation, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { formatNumberWithDelimiter } from "common/util/formatHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { useLayoutStore } from "common/state/useLayoutStore";
import { useLocationStore } from "common/state/useLocationStore";
import { LocationSwitcher } from "common/components/LocationSwitcher/LocationSwitcher";

interface Props {
  location?: MhcLocationFragment | null;
  locations?: MhcLocation[];
  uiLocation: string;
  topic?: MhcTopicFragment | null;
}

export const LocationChanger: React.FC<Props> = ({ location, uiLocation, topic, locations }) => {
  const [locationSelectorOpen, setLocationChangerState] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Location Switcher",
      action: "Toggle",
      ui_location: uiLocation
    });
    setLocationChangerState(true);
  }, [uiLocation]);

  const currentLocation = useLocationStore((store) => store.currentLocation);
  const sideNavOpen = useLayoutStore((state) => state.mobileNavIsOpen || state.desktopNavIsOpen);
  const isMobile = useIsMobile();
  const { name, population } = useMemo(() => {
    if (!isNil(currentLocation))
      return { name: currentLocation.name, population: currentLocation.population };
    if (!isNil(location)) return { name: location.name, population: location.population };
    return { name: null, population: null };
  }, [currentLocation, location]);

  return (
    <>
      <Box sx={isMobile ? {} : { display: "flex", alignItems: "center", alignSelf: "stretch" }}>
        <Typography variant="body2" color="common.black" mr={0.5}>
          Selected location:
        </Typography>
        <Link
          id="location-menu-toggle"
          className="link-with-underline"
          component="button"
          aria-label="Show options for changing the geographic location associated with the current page"
          sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}
          onClick={() => handleClick()}
        >
          <Typography variant="body2">{name}</Typography>
          <ExpandMoreIcon fontSize="small" />
        </Link>
        {!sideNavOpen && population && (
          <Typography variant="body2" color="common.black" ml={0.75}>
            {" "}
            | Population: {formatNumberWithDelimiter(population)}
          </Typography>
        )}
      </Box>
      {location && (
        <LocationSwitcher
          location={location}
          topic={topic}
          locations={locations as MhcLocationFragment[]}
          open={locationSelectorOpen}
          _onClose={() => setLocationChangerState(false)}
        />
      )}
    </>
  );
};
