"use client";

import { useCallback, useState } from "react";
import { Alert, Typography } from "@mui/material";

import { useLocationNotSupportedStore } from "common/state/useLocationNotSupportedStore";
import { CloseButton } from "component/button";

export const LocationNotAvailableForTopic = () => {
  const [open, setOpen] = useState<boolean>(true);
  const { setState } = useLocationNotSupportedStore((store) => store);
  const close = useCallback(() => {
    setState({ locationNotSupported: false });
    setOpen(false);
  }, [setState]);

  return (
    <>
      {open && (
        <Alert
          severity="warning"
          action={<CloseButton sx={{ mb: "auto", ml: "auto" }} onClick={close} />}
        >
          <Typography variant="body1" fontWeight={600}>
            Redirected Selected Location to State
          </Typography>
          <Typography variant="body2" fontWeight={400}>
            The location you selected is not available for the current topic.
          </Typography>
        </Alert>
      )}
    </>
  );
};
