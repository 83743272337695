import { FormControlLabel, Stack, Switch, Tooltip, Typography } from "@mui/material";

import { sendLocationSwitcherEvent } from "common/components/LocationSwitcher/util/events";
import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { topic } from "common/util/fixtureData";
import { GaEventActions, GaEventTypes } from "common/util/googleAnalytics";

import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";
import { InfoIcon } from "common/components/InfoIcon";

interface Props {
  dataAvailableForGranularity: boolean;
  availableGeographies: NoCountry[];
  handleGeographyUpdate: (geo: NoCountry) => void;
  onlyLocationsWithData: boolean;
  noDataAvailability?: boolean;
}

export const LocationSwitcherBannerTooltip = ({
  dataAvailableForGranularity,
  availableGeographies,
  handleGeographyUpdate,
  onlyLocationsWithData,
  noDataAvailability
}: Props) => {
  const { updateState: updateLocationSwitcherState } = useLocationSwitcherStore((store) => store);

  const handleSwitchEvent = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    sendLocationSwitcherEvent({
      eventType: GaEventTypes.UserInteraction,
      parameters: {
        action: GaEventActions.ToggleClick,
        label: topic?.name
      }
    });
    updateLocationSwitcherState({ shouldShowOnlyLocationsWithData: checked });
    if (checked === true && dataAvailableForGranularity === false) {
      const geo = availableGeographies.length > 0 ? availableGeographies[0] : undefined;
      if (geo) handleGeographyUpdate(geo);
    }
  };

  return (
    <Stack flex={1} direction="row" flexGrow={1} alignContent={{ xs: "start", md: "end" }}>
      <Tooltip title={true ? "Disabled because of the lack of data availability information." : ""}>
        <FormControlLabel
          control={
            <Switch
              checked={onlyLocationsWithData}
              onChange={handleSwitchEvent}
              disabled={noDataAvailability}
            />
          }
          label="Show only locations with data for selected topic"
          sx={{ ml: { xs: 0, md: "auto" } }}
        />
      </Tooltip>
      <InfoIcon lightVariant hideArrow sx={{ my: "auto" }}>
        <Typography variant="body2" fontWeight={400} color="light.primary">
          Limit the map and search options to locations that typically have data available for the
          current topic.
        </Typography>
      </InfoIcon>
    </Stack>
  );
};
