import { Box, Typography } from "@mui/material";

export const LocationSwitcherTips = () => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "4px",
        background: "rgba(2, 136, 209, 0.04)"
      }}
    >
      <Typography fontWeight={600} color="brand.main" sx={{ mb: "10px" }}>
        Tips to change the location
      </Typography>
      <Typography variant="body2" color="light.primary">
        There are 3 different ways to change a location:
      </Typography>
      <ul style={{ margin: 0, paddingLeft: "15px", paddingTop: "5px" }}>
        <Typography component="li" variant="body2" color="light.primary">
          Select map type and then select an area on the map
        </Typography>
        <Typography component="li" variant="body2" color="light.primary">
          Search for a location or select one from the dropdown
        </Typography>
        <Typography component="li" variant="body2" color="light.primary">
          Click the link to a specific location
        </Typography>
      </ul>
    </Box>
  );
};
