"use client";
import { ReactNode, useCallback } from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image from "next/image";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import isNil from "lodash/isNil";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

interface HighlightedTopicsItemProps {
  title: string;
  image?: string | StaticImport;
  icon?: ReactNode;
  src: string;
  width?: string;
  height?: string;
}

export const HighlightedTopicsItem = ({
  title,
  image,
  icon,
  src,
  width = "64px",
  height = "64px"
}: HighlightedTopicsItemProps) => {
  const { palette } = useTheme();

  const goToTopic = useCallback(() => {
    sendGaNavigationEvent({
      category: "Button",
      action: "Homepage topics button click",
      label: title,
      ui_location: "Homepage"
    });
  }, [title]);

  return (
    <Link
      className="no-underline"
      sx={{
        cursor: "pointer",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "5px",
        flexDirection: "column",
        padding: 2,
        "&:hover, :active, :focus": {
          borderColor: "rgba(0, 0, 0, 0.36)",
          backgroundColor: palette.brand.light
        },
        height: { xs: "100%", md: "auto" }
      }}
      href={src}
      display="flex"
      flex={1}
      justifyContent="space-between"
      alignContent="center"
      gap={{ xs: 2, md: 3 }}
      onClick={goToTopic}
      component="a"
    >
      <Box
        sx={{
          width,
          height,
          borderRadius: "100%",
          mx: "auto",
          backgroundColor: palette.brand.main,
          p: 1.5
        }}
      >
        {isNil(icon) && !isNil(image) && (
          <Image
            src={image}
            alt="placeholder"
            objectFit="cover"
            style={{ marginLeft: "auto", marginRight: "auto", height: "100%", width: "100%" }}
          />
        )}
        {!isNil(icon) && icon}
      </Box>
      <Typography
        variant="h4"
        fontWeight={700}
        color={palette.brand.main}
        textAlign="center"
        sx={{ my: "auto" }}
      >
        {title}
      </Typography>
    </Link>
  );
};

interface HighlightedTopicsGridProps {
  items: HighlightedTopicsItemProps[];
}

export const HighlightedTopicsGrid = ({ items }: HighlightedTopicsGridProps) => {
  const isMobile = useIsMobile();
  if (isMobile === true) {
    return (
      <Grid container spacing={4}>
        {items.map((props) => (
          <Grid item key={props.title} xs={6}>
            <HighlightedTopicsItem {...props} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Stack
      direction="row"
      flexWrap={{ xs: "wrap", md: "nowrap" }}
      gap={4}
      justifyContent="space-between"
      display={{ xs: "grid", md: "flex" }}
      flex={1}
    >
      {items.map((props) => (
        <HighlightedTopicsItem key={props.title} {...props} />
      ))}
    </Stack>
  );
};
