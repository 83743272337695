import isNil from "lodash/isNil";

import { brandPalette, linkColor } from "theme/colors";

import { NavItem } from "layout/SideNav/SideNavMenu/SideNavMenu";

interface Props {
  level: number;
  items?: NavItem[] | undefined | null;
  active: boolean;
  selected: boolean;
  anyChildSelected: boolean;
}

export const useGetTextColor = ({ level, active, selected, anyChildSelected, items }: Props) => {
  const darkBlue = brandPalette.brand.dark;
  if (level === 1) {
    return darkBlue;
  }
  if (level === 2) {
    if (selected === true) return linkColor;
    return darkBlue;
  }
  if (level === 3) {
    if (
      (selected === true || active === true || anyChildSelected === true) &&
      !isNil(items) &&
      items.length > 0
    )
      return linkColor;
    return darkBlue;
  }
  if (level === 4) {
    if (selected === true || active === true) return linkColor;
    return darkBlue;
  }
  return darkBlue;
};
