import { MhcCategoryFragment, MhcPageFragment } from "graphqlApi/types";

export const portals: MhcCategoryFragment & { children: MhcPageFragment[] } = {
  __typename: "MhcCategory",
  id: "portals",
  label: "Portals",
  icon: null,
  href: null,
  children: [
    {
      __typename: "MhcTopic",
      parent: null,
      children: [],
      id: "42496",
      category: "portals",
      href: "/portals/cpr",
      name: "Community Profile Reports",
      pageId: "cpr",
      slug: "cpr",
      topicType: "topic",
      description:
        "The Community Profile Report (CPR) provides easily interpretable information on key indicators for all zipcodes in Delaware. This tool, provided for the community by the Delaware Department of Public Health and DHSS, allows you to view data and key findings pertaining to the health, social determinants, and demographic characteristics of communities in Delaware."
    },
    {
      __typename: "MhcTopic",
      parent: null,
      children: [
        {
          __typename: "MhcTopic",
          id: "42499",
          children: [],
          href: "/portals/ecdc/locations/state/community-overview",
          name: "Population Data",
          pageId: "ecdcOverview",
          slug: "community-overview",
          topicType: "section"
        },
        {
          __typename: "MhcTopic",
          id: "42500",
          children: [],
          href: "/portals/ecdc/locations/state/health",
          name: "Health",
          pageId: "ecdcHealth",
          slug: "health",
          topicType: "section"
        },
        {
          __typename: "MhcTopic",
          id: "42501",
          children: [],
          href: "/portals/ecdc/locations/state/education",
          name: "Education",
          pageId: "ecdcEducation",
          slug: "education",
          topicType: "section"
        },
        {
          __typename: "MhcTopic",
          id: "42502",
          children: [],
          href: "/portals/ecdc/locations/state/criminal-justice",
          name: "Criminal Justice",
          pageId: "ecdcJustice",
          slug: "criminal-justice",
          topicType: "section"
        },
        {
          __typename: "MhcTopic",
          id: "42503",
          children: [],
          href: "/portals/ecdc/locations/state/wealth-creation",
          name: "Wealth Creation",
          pageId: "ecdcWealth",
          slug: "wealth-creation",
          topicType: "section"
        }
      ],
      id: "42498",
      category: "portals",
      href: "/portals/ecdc/locations/state",
      name: "Equity Counts Data Center",
      pageId: "",
      slug: "ecdc",
      topicType: "topic",
      description:
        "ECDC presents information to help communities, organizations, and policymakers understand and promote racial equity and social justice."
    },
    {
      __typename: "MhcTopic",
      parent: null,
      children: [
        {
          __typename: "MhcTopic",
          id: "42525",
          children: [],
          href: "/stories/vaccination",
          name: "COVID-19 Vaccinations in the Hispanic Community",
          pageId: null,
          slug: "vaccination",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42526",
          children: [],
          href: "/stories/suicide",
          name: "Suicide in Delaware",
          pageId: null,
          slug: "suicide",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42527",
          children: [],
          href: "/stories/opioid-crisis",
          name: "Delaware's Opioid Crisis",
          pageId: null,
          slug: "opioid-crisis",
          topicType: "topic"
        }
      ],
      id: "42523",
      category: "portals",
      href: "/portals/stories",
      name: "Data Stories",
      pageId: "stories",
      slug: "stories",
      topicType: "topic",
      description: ""
    }
  ]
};
