// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

"use client";

// ScrollSpy component is in development
// https://github.com/mui-org/material-ui/issues/16359#issuecomment-617365879

import { useCallback, useEffect, useRef } from "react";

import { useScrollSpyStore } from "common/state/useScrollSpyStore";
import useThrottledOnScroll from "./useThrottledScroll";

const getItemsClient = (items) =>
  items.map((hash) => ({ hash, node: document.getElementById(hash) }));

interface Args {
  items: string[];
  onActiveItemChange?: (itemId) => void;
  offset?: number;
  contentStart?: number;
  containerElement?: HTMLElement | Element;
}
const useScrollSpy = ({
  items,
  onActiveItemChange = null,
  contentStart = 0,
  offset = 75,
  containerElement
}: Args) => {
  const itemsWithNodeRef = useRef([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const { active: activeState, setScrollSpyActive: setActiveState } = useScrollSpyStore(
    (store) => store
  );

  const findActiveIndex = useCallback(() => {
    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page

      const { clientHeight, scrollHeight, scrollTop } =
        containerElement || document.documentElement;

      if (scrollTop < contentStart) {
        active = { hash: null };
        break;
      }

      const item = itemsWithNodeRef.current[i];

      if (item.node && item.node.offsetTop < scrollTop + offset) {
        active = item;
        break;
      }

      // Some ui sections are not tall enough to trigger a state change
      // based on the scroll position relative to section container so
      // this condition detects when the page is at the bottom of the page.
      if (scrollHeight - scrollTop === clientHeight) {
        active = itemsWithNodeRef.current[itemsWithNodeRef.current.length - 1];
        break;
      }
    }

    const footerHash = ["aboutMhc", "whatsComing", "feedback"];
    if (footerHash.includes(active?.hash)) return;

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
      onActiveItemChange && onActiveItemChange(active.hash);
    } else if (!active || active.hash === null) {
      setActiveState(null);
      onActiveItemChange && onActiveItemChange(null);
    }
  }, [activeState, containerElement, contentStart, offset, setActiveState, onActiveItemChange]);

  useThrottledOnScroll({
    callback: items.length > 0 ? findActiveIndex : null,
    delay: 166,
    containerElement
  });
};

export default useScrollSpy;
