import isNil from "lodash/isNil";

import { NavItem } from "layout/SideNav/SideNavMenu/SideNavMenu";

export const useGetBackgroundColor = (level: number, items?: NavItem[] | undefined | null) => {
  if (level < 3 && !isNil(items) && items?.length > 0) {
    return undefined;
  }
  if (level >= 3) {
    return "#F2F7FE"; // Only used here
  }
  return "rgba(0, 96, 240, 0.05)";
};
