import { Box, Card, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { DEFAULT_MARGIN_STR } from "layout/configuration";

import { FooterButton } from "component/button/Examples/NavigationButton";
import { NavItem } from "../NavTabs";
import Row from "../Row";

interface Props {
  selectedItemId: string;
  items: NavItem[];
  footerTitle: string;
  target?: string;
}

export const PortalCardFooter: React.FC<Props> = ({
  selectedItemId,
  items,
  footerTitle,
  target
}) => {
  return (
    <Card
      sx={{
        position: "relative",
        display: {
          lg: "flex",
          xs: "none"
        },
        my: DEFAULT_MARGIN_STR
      }}
    >
      <Box
        sx={{
          bottom: "2rem",
          background: "white",
          p: 2,
          display: "flex",
          flexDirection: "column",
          mx: "auto"
        }}
      >
        <Typography variant="body2" color={grey["700"]}>
          {footerTitle}
        </Typography>
        <Row sx={{ mt: 1.5 }} gap={2}>
          {items.map((item) => (
            <FooterButton
              name={item.name}
              href={item.href ?? ""}
              key={item.id}
              selected={item.id === selectedItemId}
              target={target}
            />
          ))}
        </Row>
      </Box>
    </Card>
  );
};
