"use client";

import { MhcAttributionFragment } from "graphqlApi/types";

import { brandPalette } from "theme/colors";
import { DashboardPagesMap, DashboardTitle } from "./util/dashboardConfigurations";
import { getPageHref, GetPageHrefProps } from "./util/pageHref";

import { NavItem } from "common/components/NavTabs";
import { PageHeaderWithBanner } from "../PageHeader/PageHeader";

interface DashboardHeadProps {
  locationId: string;
  selectedId: string;
  configProps: GetPageHrefProps;
  attributions?: MhcAttributionFragment[];
  fromAppRouter?: boolean;
  redirected?: boolean;
  pageAttributionKey?: string;
}

export const DashboardHead: React.FC<DashboardHeadProps> = ({
  locationId,
  selectedId,
  configProps,
  fromAppRouter,
  attributions,
  redirected,
  pageAttributionKey
}: DashboardHeadProps) => {
  const pages = Object.values(DashboardPagesMap[configProps.dashboard] ?? []);
  const title = DashboardTitle[configProps.dashboard];
  const items: NavItem[] = pages.map((page) => ({
    ...page,
    locationId,
    href: getPageHref({ ...configProps, pageId: page.id })
  }));

  return (
    <PageHeaderWithBanner
      fromAppRouter={fromAppRouter}
      download
      attributions={attributions}
      title={title}
      subtitle="Sections in the dashboard"
      tabs={{
        items,
        selectedId
      }}
      bgColor={brandPalette.brand.light}
      selectedColor={brandPalette.brand.main}
      redirected={redirected}
      pageAttributionKey={pageAttributionKey}
    />
  );
};
