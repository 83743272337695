import { ChangeEvent, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import { MhcCategoryFragment, MhcPageFragment, MhcTopicFragment } from "graphqlApi/types";

import { MhcTopicCategories } from "layout/SideNav";
import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";
import {
  InvestigateDropdown,
  InvestigateDropdownOption
} from "common/components/charts/Investigate/InvestigateDropdown";

interface Props {
  topic?: MhcTopicFragment | null;
  items?: MhcTopicCategories;
  setTopic: (topic: MhcTopicFragment) => void;
}

export const LocationSwitcherBannerSelectTopic = ({ topic, items, setTopic }: Props) => {
  const { updateState: updateLocationSwitcherState } = useLocationSwitcherStore((store) => store);
  const [options, mappedOptions]: [
    InvestigateDropdownOption[],
    Record<string, MhcPageFragment | MhcCategoryFragment>
  ] = useMemo(() => {
    const options: InvestigateDropdownOption[] = [];
    const mappedOptions: Record<string, MhcPageFragment | MhcCategoryFragment> = {};
    if (isNil(items)) return [options, mappedOptions];

    sortBy(Object.values(items), "label").forEach((category) => {
      category.children.forEach((page) => {
        if (category.id === "all_topics") {
          page.children?.forEach((item) => {
            if (isNil(item)) return;
            options.push({
              id: item.id,
              title: item.name,
              value: item.name,
              group: page.name
            });
            mappedOptions[item.name] = item as MhcPageFragment;
            mappedOptions[page.name] = page;
          });
          return;
        }

        options.push({
          id: page.id,
          title: page.name,
          value: page.name,
          group: category.label
        });
        mappedOptions[page.name] = page;
        mappedOptions[category.label] = category;
      });
    });
    return [options, mappedOptions];
  }, [items]);

  const setNewGroup = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newGroup = mappedOptions[event.target.value];
      if (isNil(newGroup)) return;
      updateLocationSwitcherState({ shouldShowOnlyLocationsWithData: false });
      setTopic(newGroup as MhcTopicFragment);
    },
    [mappedOptions, setTopic, updateLocationSwitcherState]
  );

  return (
    <Stack flex={1} direction="row" flexGrow={1} width="100%">
      <InvestigateDropdown
        grouped
        value={topic?.name}
        title={"Select Topic"}
        onChange={setNewGroup}
        defaultValue={topic?.name}
        options={options}
        type="Chart"
        valueItemIsSelected={({ value, selectedValue }) => {
          return value === selectedValue;
        }}
        fullWidth
        sx={{ width: "100%" }}
      />
    </Stack>
  );
};
