import { SxProps, Theme } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import { useGetBackgroundColor } from "layout/SideNav/SideNavMenu/util/useGetBackgroundColor";
import { useGetBorderColor } from "layout/SideNav/SideNavMenu/util/useGetBorderColor";
import { useGetItemIndent } from "layout/SideNav/SideNavMenu/util/useGetItemIndent";
import { useGetTextColor } from "layout/SideNav/SideNavMenu/util/useGetTextColor";

import { NavItem } from "layout/SideNav/SideNavMenu/SideNavMenu";

interface Props {
  level: number;
  items?: NavItem[] | undefined | null;
  category: string;
  isSelected: boolean;
  isActive: boolean;
  anyChildSelected: boolean;
  parentSelected: boolean;
}

export const useGetSideNavItemStyles = ({
  level,
  items,
  category,
  isActive,
  isSelected,
  anyChildSelected,
  parentSelected
}: Props) => {
  const hasItems = !isEmpty(items);
  const itemIndent = useGetItemIndent(level, category);
  const borderColor = useGetBorderColor({
    isSelected,
    hasItems,
    level,
    anyChildSelected,
    items,
    parentSelected
  });
  const activeBackgroundColor = useGetBackgroundColor(level, items);
  const textColor = useGetTextColor({
    level,
    items,
    active: isActive,
    selected: isSelected,
    anyChildSelected
  });

  const showLeftBorder = level >= 3;

  const leftBorderStyle: SxProps<Theme> = {
    borderLeftStyle: "solid",
    borderLeftColor: borderColor,
    borderLeftWidth: "2px"
  };

  return {
    showLeftBorder,
    leftBorderStyle,
    itemIndent,
    borderColor,
    activeBackgroundColor,
    textColor
  };
};
