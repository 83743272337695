import React from "react";
import { Box } from "@mui/material";

import { MhcTopicCategoryEnum } from "graphqlApi/types";

import useScrollSpy from "common/util/hooks/useScrollSpy";

import { SideNavMenuMenuItemBase } from "layout/SideNav/SideNavMenu/MenuItems/ItemBase";

export interface NavItem {
  id: string;
  title?: React.ReactNode | string;
  icon?: React.ReactElement | null;
  items?: NavItem[];
  href?: string;
  type?: "topic" | "category" | "section";
  showCategory?: boolean;
  level: number;
  category: MhcTopicCategoryEnum;
}

export interface SideNavMenuProps {
  items: NavItem[];
  selected?: string | undefined | null;
}

export const getHtmlIdForItem = (navItem: NavItem) => `nav_${navItem.id}`;
const getAllItemIds = (items: NavItem[]): string[] => {
  return items.flatMap(({ id, items }) => (items ? [id, ...getAllItemIds(items)] : [id]));
};

export const SideNavMenu: React.FC<SideNavMenuProps> = ({ items, selected }) => {
  useScrollSpy({
    items: getAllItemIds(items),
    offset: 5
  });
  return (
    <Box component="nav">
      {items.map((item) => (
        <SideNavMenuMenuItemBase
          key={item.id}
          item={item}
          selected={selected}
          parentSelected={false}
        />
      ))}
    </Box>
  );
};
