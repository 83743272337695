import { MhcAlteredLocationFragment } from "graphqlApi/types";

export const censusTracts: Record<string, MhcAlteredLocationFragment> = {
  "census-tract-509-01": {
    __typename: "MhcLocation",
    id: "census-tract-101-01",
    name: "Census Tract 101.01",
    geography: "census_tract",
    mapData: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            featureType: "location",
            path: "locations/census-tract-101-01",
            name: "Census Tract 101.01",
            type: "CensusTract",
            id: "census-tract-101-01"
          },
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-75.469473, 39.814215999999995],
                  [-75.459739, 39.822742999999996],
                  [-75.446214, 39.817576],
                  [-75.433357, 39.811865999999995],
                  [-75.445261, 39.809221],
                  [-75.454279, 39.80453],
                  [-75.456954, 39.808135],
                  [-75.459446, 39.810368],
                  [-75.460363, 39.81084],
                  [-75.464446, 39.812222],
                  [-75.469473, 39.814215999999995]
                ]
              ]
            ]
          }
        }
      ]
    },
    population: 4859,
    deprecationInfo: {
      newBoundaries: {
        year: 2020,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-101-06",
                  name: "Census Tract 101.06",
                  type: "CensusTract",
                  id: "census-tract-101-06"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.462446, 39.820375],
                        [-75.459739, 39.822743],
                        [-75.449516, 39.818915],
                        [-75.45145099999999, 39.817309],
                        [-75.45315599999999, 39.815447999999996],
                        [-75.45782799999999, 39.816657],
                        [-75.464124, 39.81893],
                        [-75.462446, 39.820375]
                      ]
                    ]
                  ]
                }
              }
            ]
          },
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-101-05",
                  name: "Census Tract 101.05",
                  type: "CensusTract",
                  id: "census-tract-101-05"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.46608586634677, 39.81721177681258],
                        [-75.464124, 39.81893],
                        [-75.45782799999999, 39.816657],
                        [-75.45315599999999, 39.815447999999996],
                        [-75.45145099999999, 39.817309],
                        [-75.449516, 39.818915],
                        [-75.441767, 39.815693],
                        [-75.433357, 39.811865999999995],
                        [-75.445261, 39.809221],
                        [-75.454279, 39.80453],
                        [-75.456954, 39.808135],
                        [-75.459446, 39.810368],
                        [-75.460363, 39.81084],
                        [-75.46956, 39.814175],
                        [-75.46608586634677, 39.81721177681258]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      },
      oldBoundaries: {
        year: 2010,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-101-01",
                  name: "Census Tract 101.01",
                  type: "CensusTract",
                  id: "census-tract-101-01"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.469473, 39.814215999999995],
                        [-75.459739, 39.822742999999996],
                        [-75.446214, 39.817576],
                        [-75.433357, 39.811865999999995],
                        [-75.445261, 39.809221],
                        [-75.454279, 39.80453],
                        [-75.456954, 39.808135],
                        [-75.459446, 39.810368],
                        [-75.460363, 39.81084],
                        [-75.464446, 39.812222],
                        [-75.469473, 39.814215999999995]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      },
      status: "deprecated"
    }
  },
  "census-tract-108": {
    __typename: "MhcLocation",
    id: "census-tract-108",
    name: "Census Tract 108",
    geography: "census_tract",
    mapData: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            featureType: "location",
            path: "locations/census-tract-108",
            name: "Census Tract 108",
            type: "CensusTract",
            id: "census-tract-108"
          },
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-75.54467799999999, 39.777454999999996],
                  [-75.533583, 39.788919],
                  [-75.52390799999999, 39.791562],
                  [-75.518892, 39.78378000000001],
                  [-75.518371, 39.781423],
                  [-75.516964, 39.778303],
                  [-75.51693399999999, 39.777214],
                  [-75.510759, 39.778917],
                  [-75.50879599999999, 39.779694],
                  [-75.50623499999999, 39.780991],
                  [-75.50663, 39.776089],
                  [-75.50741699999999, 39.773258],
                  [-75.50668, 39.772825999999995],
                  [-75.503171, 39.769379],
                  [-75.51871299999999, 39.762284],
                  [-75.520026, 39.764025],
                  [-75.51996299999999, 39.764261],
                  [-75.520776, 39.765581999999995],
                  [-75.520242, 39.76572],
                  [-75.51955199999999, 39.765381999999995],
                  [-75.518372, 39.766296],
                  [-75.518076, 39.767286999999996],
                  [-75.518433, 39.767662],
                  [-75.519415, 39.768073],
                  [-75.52235, 39.768948],
                  [-75.52399799999999, 39.76992],
                  [-75.525944, 39.773007],
                  [-75.527768, 39.772301000000006],
                  [-75.529951, 39.771761999999995],
                  [-75.531673, 39.770373],
                  [-75.53227, 39.770407],
                  [-75.535187, 39.768231],
                  [-75.53451, 39.767984],
                  [-75.53296399999999, 39.769238],
                  [-75.531984, 39.767953],
                  [-75.532241, 39.767844],
                  [-75.531291, 39.766892999999996],
                  [-75.534656, 39.765356],
                  [-75.534566, 39.766281],
                  [-75.535465, 39.767267],
                  [-75.53693799999999, 39.766172999999995],
                  [-75.537409, 39.766887000000004],
                  [-75.540342, 39.76553],
                  [-75.54275299999999, 39.764613],
                  [-75.54487999999999, 39.777426],
                  [-75.54467799999999, 39.777454999999996]
                ]
              ]
            ]
          }
        }
      ]
    },
    population: 0
  },
  "Census Tract 168.08": {
    __typename: "MhcLocation",
    id: "census-tract-168-09",
    name: "Census Tract 168.09",
    geography: "census_tract",
    mapData: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            featureType: "location",
            path: "locations/census-tract-168-08",
            name: "Census Tract 168.08",
            type: "CensusTract",
            id: "census-tract-168-08"
          },
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-75.713311, 39.437285],
                  [-75.712051, 39.437866],
                  [-75.711385, 39.437954],
                  [-75.709693, 39.437659],
                  [-75.708891, 39.437334],
                  [-75.708371, 39.436657],
                  [-75.706369, 39.436343],
                  [-75.705428, 39.436483],
                  [-75.704499, 39.436851],
                  [-75.701803, 39.436769],
                  [-75.701136, 39.436938],
                  [-75.698159, 39.436972],
                  [-75.695777, 39.437494],
                  [-75.694785, 39.437516],
                  [-75.693542, 39.437974],
                  [-75.69165, 39.437935],
                  [-75.689545, 39.438538],
                  [-75.687065, 39.438278],
                  [-75.684898, 39.439297],
                  [-75.683784, 39.439327],
                  [-75.682426, 39.439014],
                  [-75.680222, 39.439049],
                  [-75.677078, 39.438614],
                  [-75.674583, 39.43922],
                  [-75.672981, 39.44062],
                  [-75.672073, 39.440647],
                  [-75.670357, 39.439541],
                  [-75.669357, 39.439571],
                  [-75.668762, 39.439392],
                  [-75.668344, 39.438549],
                  [-75.6679, 39.438346],
                  [-75.66734, 39.43819],
                  [-75.665516, 39.4382],
                  [-75.665458, 39.438337],
                  [-75.665989, 39.439088],
                  [-75.666019, 39.43953],
                  [-75.66491, 39.439977],
                  [-75.664711, 39.440821],
                  [-75.664174, 39.441487],
                  [-75.66395, 39.442471],
                  [-75.664322, 39.443453],
                  [-75.66376, 39.444198],
                  [-75.663502, 39.44432],
                  [-75.662437, 39.444164],
                  [-75.662048, 39.444286],
                  [-75.661591, 39.444775],
                  [-75.661102, 39.444996],
                  [-75.660637, 39.444939],
                  [-75.659645, 39.444263],
                  [-75.65905, 39.444225],
                  [-75.658585, 39.444611],
                  [-75.658508, 39.44495],
                  [-75.658699, 39.44529],
                  [-75.659317, 39.44545],
                  [-75.659813, 39.445827],
                  [-75.65982, 39.44628],
                  [-75.659462, 39.446732],
                  [-75.657684, 39.447323],
                  [-75.6567, 39.447411],
                  [-75.656212, 39.447693],
                  [-75.656161, 39.448155],
                  [-75.655886, 39.447906],
                  [-75.655357, 39.448531],
                  [-75.65471, 39.448023],
                  [-75.653836, 39.448299],
                  [-75.653533, 39.448887],
                  [-75.653677, 39.449658],
                  [-75.65439, 39.45119],
                  [-75.653584, 39.451932],
                  [-75.6506, 39.450367],
                  [-75.647999, 39.448335],
                  [-75.643816, 39.440946],
                  [-75.643745, 39.440475],
                  [-75.643971, 39.439841],
                  [-75.639985, 39.437539],
                  [-75.638229, 39.436207],
                  [-75.633923, 39.431529],
                  [-75.631669, 39.427048],
                  [-75.631218, 39.424267],
                  [-75.630152, 39.421999],
                  [-75.629672, 39.420312],
                  [-75.629504, 39.417331],
                  [-75.629201, 39.416198],
                  [-75.620834, 39.402475],
                  [-75.618049, 39.400841],
                  [-75.617377, 39.400946],
                  [-75.615799, 39.401985],
                  [-75.6152, 39.402129],
                  [-75.607891, 39.400674],
                  [-75.602926, 39.40092],
                  [-75.602327, 39.401264],
                  [-75.601143, 39.403968],
                  [-75.599291, 39.404973],
                  [-75.598556, 39.403302],
                  [-75.597977, 39.402397],
                  [-75.59742, 39.402065],
                  [-75.595993, 39.401909],
                  [-75.596176, 39.400963],
                  [-75.596779, 39.400341],
                  [-75.596664, 39.399067],
                  [-75.59687, 39.39735],
                  [-75.596573, 39.397079],
                  [-75.595634, 39.396969],
                  [-75.595505, 39.396729],
                  [-75.59565, 39.396423],
                  [-75.596375, 39.395847],
                  [-75.597038, 39.394444],
                  [-75.597534, 39.393909],
                  [-75.598076, 39.393749],
                  [-75.600624, 39.393753],
                  [-75.601151, 39.393047],
                  [-75.601837, 39.392574],
                  [-75.602142, 39.392612],
                  [-75.602493, 39.393063],
                  [-75.602692, 39.39444],
                  [-75.604095, 39.395168],
                  [-75.604691, 39.395321],
                  [-75.60537, 39.395229],
                  [-75.605804, 39.39402],
                  [-75.606285, 39.39357],
                  [-75.606895, 39.393539],
                  [-75.608238, 39.394211],
                  [-75.608772, 39.394192],
                  [-75.609718, 39.393951],
                  [-75.610769, 39.393304],
                  [-75.612625, 39.393589],
                  [-75.615168, 39.393446],
                  [-75.615692, 39.393585],
                  [-75.61647, 39.394901],
                  [-75.617485, 39.39521],
                  [-75.618484, 39.395267],
                  [-75.619324, 39.395065],
                  [-75.620781, 39.393433],
                  [-75.621582, 39.393124],
                  [-75.622253, 39.392639],
                  [-75.622818, 39.391937],
                  [-75.62236, 39.390743],
                  [-75.623047, 39.389107],
                  [-75.623528, 39.388603],
                  [-75.626396, 39.387466],
                  [-75.627892, 39.387398],
                  [-75.62841, 39.387119],
                  [-75.631012, 39.384892],
                  [-75.631447, 39.384838],
                  [-75.63176, 39.385124],
                  [-75.631935, 39.386761],
                  [-75.632401, 39.387562],
                  [-75.632988, 39.387924],
                  [-75.635323, 39.38868],
                  [-75.636414, 39.38876],
                  [-75.636703, 39.388653],
                  [-75.637154, 39.387817],
                  [-75.637245, 39.38588],
                  [-75.638031, 39.384209],
                  [-75.63858, 39.384193],
                  [-75.639229, 39.385567],
                  [-75.639442, 39.385784],
                  [-75.639915, 39.385876],
                  [-75.640739, 39.385128],
                  [-75.641754, 39.383072],
                  [-75.641777, 39.382652],
                  [-75.642136, 39.382183],
                  [-75.644302, 39.382263],
                  [-75.645424, 39.382133],
                  [-75.6464, 39.380192],
                  [-75.646515, 39.379669],
                  [-75.646286, 39.379295],
                  [-75.643738, 39.378384],
                  [-75.643578, 39.378113],
                  [-75.643616, 39.377716],
                  [-75.643806, 39.377254],
                  [-75.644089, 39.377014],
                  [-75.646194, 39.376431],
                  [-75.64782, 39.376465],
                  [-75.648437, 39.376617],
                  [-75.649803, 39.376118],
                  [-75.650351, 39.375691],
                  [-75.65176, 39.375848],
                  [-75.652177, 39.375512],
                  [-75.653205, 39.375213],
                  [-75.653868, 39.37519],
                  [-75.654739, 39.37556],
                  [-75.656213, 39.3749],
                  [-75.658103, 39.374796],
                  [-75.658775, 39.37416],
                  [-75.659745, 39.37383],
                  [-75.661674, 39.372649],
                  [-75.663375, 39.374517],
                  [-75.664647, 39.376761],
                  [-75.665248, 39.379173],
                  [-75.665208, 39.38058],
                  [-75.665535, 39.380812],
                  [-75.666214, 39.382067],
                  [-75.668778, 39.385422],
                  [-75.672375, 39.388493],
                  [-75.67301, 39.389336],
                  [-75.675811, 39.3921],
                  [-75.68007, 39.395846],
                  [-75.681255, 39.396871],
                  [-75.68146, 39.396678],
                  [-75.683163, 39.397883],
                  [-75.684514, 39.399492],
                  [-75.68752, 39.401339],
                  [-75.690199, 39.402581],
                  [-75.691338, 39.40394],
                  [-75.692677, 39.405024],
                  [-75.697428, 39.407303],
                  [-75.698679, 39.408051],
                  [-75.699543, 39.408754],
                  [-75.703693, 39.413181],
                  [-75.704932, 39.41625],
                  [-75.705576, 39.420011],
                  [-75.70621, 39.42251],
                  [-75.707094, 39.424897],
                  [-75.708125, 39.427114],
                  [-75.708741, 39.429284],
                  [-75.70947, 39.430803],
                  [-75.713311, 39.437285]
                ]
              ]
            ]
          }
        }
      ]
    },
    population: 0,
    deprecationInfo: {
      status: "new",
      newBoundaries: {
        year: 2020,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-168-09",
                  name: "Census Tract 168.04",
                  type: "CensusTract",
                  id: "census-tract-168-09"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.71331099999999, 39.437284999999996],
                        [-75.712051, 39.437866],
                        [-75.71138499999999, 39.437954],
                        [-75.709693, 39.437659],
                        [-75.708891, 39.437334],
                        [-75.70837100000001, 39.436657],
                        [-75.706369, 39.436343],
                        [-75.705428, 39.436482999999996],
                        [-75.704499, 39.436851],
                        [-75.701803, 39.436769],
                        [-75.70113599999999, 39.436938],
                        [-75.69815899999999, 39.436972000000004],
                        [-75.69577699999999, 39.437494],
                        [-75.694785, 39.437515999999995],
                        [-75.693542, 39.437974],
                        [-75.69165, 39.437934999999996],
                        [-75.689545, 39.438538],
                        [-75.68706499999999, 39.438278],
                        [-75.68489799999999, 39.439296999999996],
                        [-75.683784, 39.439327],
                        [-75.682426, 39.439014],
                        [-75.680222, 39.439049],
                        [-75.677078, 39.438614],
                        [-75.674583, 39.43922],
                        [-75.672981, 39.440619999999996],
                        [-75.672073, 39.440647],
                        [-75.670357, 39.439541],
                        [-75.66935699999999, 39.439571],
                        [-75.668762, 39.439392],
                        [-75.66834399999999, 39.438548999999995],
                        [-75.6679, 39.438345999999996],
                        [-75.66734, 39.43819],
                        [-75.665516, 39.4382],
                        [-75.665458, 39.438337],
                        [-75.665989, 39.439088],
                        [-75.66601899999999, 39.43953],
                        [-75.66490999999999, 39.439977],
                        [-75.664711, 39.440821],
                        [-75.664174, 39.441486999999995],
                        [-75.66395, 39.442471],
                        [-75.664322, 39.443453],
                        [-75.66376, 39.444198],
                        [-75.663502, 39.44432],
                        [-75.662437, 39.444164],
                        [-75.662048, 39.444286],
                        [-75.661591, 39.444775],
                        [-75.661102, 39.444995999999996],
                        [-75.660637, 39.444939],
                        [-75.659645, 39.444263],
                        [-75.65905, 39.444224999999996],
                        [-75.658585, 39.444610999999995],
                        [-75.65850800000001, 39.44495],
                        [-75.658699, 39.44529],
                        [-75.659317, 39.44545],
                        [-75.659813, 39.445827],
                        [-75.65982, 39.44628],
                        [-75.65946199999999, 39.446732],
                        [-75.657684, 39.447323],
                        [-75.6567, 39.447410999999995],
                        [-75.656212, 39.447693],
                        [-75.656161, 39.448155],
                        [-75.655886, 39.447905999999996],
                        [-75.655357, 39.448530999999996],
                        [-75.65471, 39.448023],
                        [-75.653836, 39.448299],
                        [-75.653533, 39.448887],
                        [-75.653677, 39.449658],
                        [-75.654263, 39.450839],
                        [-75.652642, 39.452676],
                        [-75.650318, 39.453790999999995],
                        [-75.648887, 39.454953],
                        [-75.647466, 39.456903],
                        [-75.647274, 39.45738],
                        [-75.647054, 39.45919],
                        [-75.64612, 39.45949900000001],
                        [-75.644362, 39.459779],
                        [-75.641578, 39.461364],
                        [-75.640676, 39.461532],
                        [-75.638362, 39.461349],
                        [-75.636883, 39.46051],
                        [-75.635936, 39.46045000000001],
                        [-75.635307, 39.461393],
                        [-75.63497900000002, 39.462275],
                        [-75.63421, 39.463241],
                        [-75.631749, 39.463107],
                        [-75.63021599999999, 39.463279],
                        [-75.627937, 39.462281],
                        [-75.626015, 39.462312],
                        [-75.625001, 39.462603],
                        [-75.624523, 39.462925],
                        [-75.62445699999999, 39.463215],
                        [-75.62466599999999, 39.46374],
                        [-75.625642, 39.464605999999996],
                        [-75.62584000000001, 39.465571],
                        [-75.62574099999999, 39.466276],
                        [-75.625177, 39.467031999999996],
                        [-75.624292, 39.467464],
                        [-75.623504, 39.467397],
                        [-75.62262199999999, 39.46698],
                        [-75.622103, 39.466466],
                        [-75.62143499999999, 39.466235],
                        [-75.620861, 39.466332],
                        [-75.61953799999999, 39.467419],
                        [-75.61865399999999, 39.467490999999995],
                        [-75.616573, 39.467228],
                        [-75.613603, 39.465700000000005],
                        [-75.61209099999999, 39.465225],
                        [-75.609749, 39.463577],
                        [-75.60884899999999, 39.462357],
                        [-75.608856, 39.461697],
                        [-75.60940599999999, 39.461242999999996],
                        [-75.60960399999999, 39.460487],
                        [-75.60958099999999, 39.459114],
                        [-75.609955, 39.458262999999995],
                        [-75.61166399999999, 39.456134999999996],
                        [-75.611908, 39.455254],
                        [-75.611794, 39.453644],
                        [-75.61158, 39.453196999999996],
                        [-75.609123, 39.452709],
                        [-75.607567, 39.451775],
                        [-75.606659, 39.450328999999996],
                        [-75.606155, 39.449825],
                        [-75.600937, 39.44698],
                        [-75.59975399999999, 39.446678],
                        [-75.597321, 39.448029],
                        [-75.596733, 39.448111999999995],
                        [-75.596024, 39.447941],
                        [-75.59590100000001, 39.446194],
                        [-75.595444, 39.445727999999995],
                        [-75.59465, 39.445377],
                        [-75.592216, 39.445595000000004],
                        [-75.59127000000001, 39.445984],
                        [-75.59097299999999, 39.446472],
                        [-75.59111, 39.44762],
                        [-75.59088899999999, 39.447967999999996],
                        [-75.58760099999999, 39.447994],
                        [-75.586342, 39.448429],
                        [-75.585037, 39.448456],
                        [-75.58429, 39.447353],
                        [-75.583534, 39.446884],
                        [-75.58255799999999, 39.446720000000006],
                        [-75.581886, 39.447105],
                        [-75.58140995992636, 39.44648091933865],
                        [-75.58078394674496, 39.44670192743947],
                        [-75.58040391255226, 39.44718393730273],
                        [-75.58010192182074, 39.4472139552577],
                        [-75.57995594893126, 39.447437925547064],
                        [-75.57949301011892, 39.447622907719904],
                        [-75.5792100844944, 39.447361947314334],
                        [-75.57898907663585, 39.44651595277319],
                        [-75.57859407701694, 39.4459629534252],
                        [-75.57767402230718, 39.44540691280099],
                        [-75.57697196843517, 39.44531891571521],
                        [-75.57639303317605, 39.44573890696971],
                        [-75.5756130309179, 39.445107915377804],
                        [-75.57548796621583, 39.44523387827473],
                        [-75.57507308005, 39.44505294467477],
                        [-75.57497503201614, 39.44479091586877],
                        [-75.57462392899873, 39.44512991],
                        [-75.57422497668101, 39.445045913059865],
                        [-75.57329506501148, 39.4453669269694],
                        [-75.57267611667775, 39.444614974238796],
                        [-75.57269906383314, 39.44437893653291],
                        [-75.5723530281447, 39.44401791450147],
                        [-75.57122507017188, 39.44338894083087],
                        [-75.5711550874983, 39.44311203094833],
                        [-75.57127706439535, 39.44292593702452],
                        [-75.5707471169504, 39.44263194370213],
                        [-75.57112996373472, 39.44261408255045],
                        [-75.57112110210906, 39.44249106009639],
                        [-75.5718080194599, 39.44226708805097],
                        [-75.57218502138038, 39.44228908745607],
                        [-75.57223605038034, 39.442140076827535],
                        [-75.57344900089464, 39.44164809003843],
                        [-75.57343903353741, 39.44134409514223],
                        [-75.5744670397383, 39.44073308078201],
                        [-75.574554066561, 39.44047508285406],
                        [-75.57474006026844, 39.44064606688639],
                        [-75.57530400252672, 39.44015109013389],
                        [-75.57499908355436, 39.43991796059092],
                        [-75.57499010739353, 39.43963202829935],
                        [-75.57522609607625, 39.43865501254641],
                        [-75.57545308937888, 39.43852498869681],
                        [-75.57538699999999, 39.437697],
                        [-75.57592799999999, 39.437836],
                        [-75.577141, 39.439194],
                        [-75.57790399999999, 39.439780999999996],
                        [-75.578377, 39.439938],
                        [-75.579056, 39.440028999999996],
                        [-75.57965899999999, 39.439884],
                        [-75.580719, 39.439223999999996],
                        [-75.58107, 39.438702],
                        [-75.580849, 39.438263],
                        [-75.579895, 39.437469],
                        [-75.579636, 39.436893],
                        [-75.579872, 39.43626],
                        [-75.58200099999999, 39.435612],
                        [-75.58242, 39.435192],
                        [-75.582359, 39.434849],
                        [-75.580643, 39.433186],
                        [-75.580322, 39.432528999999995],
                        [-75.58036799999999, 39.431945999999996],
                        [-75.580681, 39.431526],
                        [-75.581184, 39.431438],
                        [-75.582672, 39.431830999999995],
                        [-75.583145, 39.431736],
                        [-75.583794, 39.431228999999995],
                        [-75.584645, 39.431177999999996],
                        [-75.585304, 39.43129],
                        [-75.586647, 39.431847],
                        [-75.587769, 39.431519],
                        [-75.588959, 39.430748],
                        [-75.59082, 39.43074],
                        [-75.592209, 39.430321],
                        [-75.592461, 39.430481],
                        [-75.59316299999999, 39.431961],
                        [-75.593468, 39.432297],
                        [-75.594506, 39.432660999999996],
                        [-75.59584799999999, 39.432381],
                        [-75.595947, 39.431228999999995],
                        [-75.59537499999999, 39.430233],
                        [-75.595261, 39.429695],
                        [-75.59545899999999, 39.429489],
                        [-75.596542, 39.429500999999995],
                        [-75.597244, 39.429809999999996],
                        [-75.59760299999999, 39.43029],
                        [-75.598434, 39.429516],
                        [-75.599091, 39.429573],
                        [-75.60002899999999, 39.430626],
                        [-75.600675, 39.43081900000001],
                        [-75.601006, 39.430695],
                        [-75.601074, 39.430389],
                        [-75.600822, 39.430042],
                        [-75.599373, 39.428737999999996],
                        [-75.598991, 39.42795900000001],
                        [-75.598953, 39.427333999999995],
                        [-75.600792, 39.426907],
                        [-75.60095199999999, 39.426517],
                        [-75.60047899999999, 39.425846],
                        [-75.599915, 39.425647999999995],
                        [-75.59906, 39.425605999999995],
                        [-75.599052, 39.425132999999995],
                        [-75.599091, 39.424816],
                        [-75.601677, 39.422202999999996],
                        [-75.60170699999999, 39.420609],
                        [-75.601891, 39.420303],
                        [-75.60228699999999, 39.419903000000005],
                        [-75.603554, 39.419146999999995],
                        [-75.605667, 39.418442],
                        [-75.607491, 39.418484],
                        [-75.607773, 39.418231999999996],
                        [-75.607834, 39.417358],
                        [-75.6082, 39.416828],
                        [-75.61026, 39.416042],
                        [-75.61175500000002, 39.415928],
                        [-75.611801, 39.415543],
                        [-75.611549, 39.415431999999996],
                        [-75.610336, 39.415348],
                        [-75.610085, 39.414955],
                        [-75.610008, 39.414356000000005],
                        [-75.609589, 39.414238],
                        [-75.608971, 39.41457],
                        [-75.607224, 39.414981999999995],
                        [-75.606644, 39.415684],
                        [-75.60627699999999, 39.415749],
                        [-75.60546099999999, 39.415146],
                        [-75.60511, 39.41404],
                        [-75.605125, 39.413264999999996],
                        [-75.60481999999999, 39.412749999999996],
                        [-75.604485, 39.412746],
                        [-75.60372199999999, 39.413212],
                        [-75.603241, 39.414162],
                        [-75.603386, 39.414871],
                        [-75.602707, 39.415290999999996],
                        [-75.60247, 39.414997],
                        [-75.602493, 39.412144],
                        [-75.60224199999999, 39.411311999999995],
                        [-75.60041, 39.409866],
                        [-75.5998, 39.409206],
                        [-75.599701, 39.408798],
                        [-75.600533, 39.407513],
                        [-75.600113, 39.406956],
                        [-75.599115, 39.406726],
                        [-75.598846, 39.406479],
                        [-75.598801, 39.406009999999995],
                        [-75.59935, 39.405293],
                        [-75.598556, 39.403302],
                        [-75.597977, 39.40239700000001],
                        [-75.59742, 39.402065],
                        [-75.595993, 39.401908999999996],
                        [-75.596176, 39.400963],
                        [-75.596779, 39.400341],
                        [-75.59666399999999, 39.399066999999995],
                        [-75.59687, 39.397349999999996],
                        [-75.59657299999999, 39.397079],
                        [-75.59563399999999, 39.396969],
                        [-75.595505, 39.396729],
                        [-75.59564999999999, 39.396423],
                        [-75.596375, 39.395846999999996],
                        [-75.597038, 39.394444],
                        [-75.597534, 39.393909],
                        [-75.59807599999999, 39.393749],
                        [-75.60062400000001, 39.393753],
                        [-75.601151, 39.393046999999996],
                        [-75.601837, 39.392573999999996],
                        [-75.602142, 39.392612],
                        [-75.602493, 39.393063],
                        [-75.60269199999999, 39.394439999999996],
                        [-75.604095, 39.395168],
                        [-75.604691, 39.395320999999996],
                        [-75.60537, 39.395229],
                        [-75.60580399999999, 39.39402],
                        [-75.606285, 39.39357],
                        [-75.606895, 39.393539],
                        [-75.608238, 39.394211000000006],
                        [-75.608772, 39.394192],
                        [-75.609718, 39.393951],
                        [-75.61076899999999, 39.393304],
                        [-75.612625, 39.393589],
                        [-75.615168, 39.393446],
                        [-75.615692, 39.393585],
                        [-75.61646999999999, 39.394901],
                        [-75.617485, 39.39521],
                        [-75.618484, 39.395267],
                        [-75.61932399999999, 39.395064999999995],
                        [-75.620781, 39.393433],
                        [-75.621582, 39.393124],
                        [-75.622253, 39.392638999999996],
                        [-75.622818, 39.391937],
                        [-75.62236, 39.39074300000001],
                        [-75.623047, 39.389106999999996],
                        [-75.623528, 39.388602999999996],
                        [-75.626396, 39.387465999999996],
                        [-75.627892, 39.387398],
                        [-75.62841, 39.387119],
                        [-75.631012, 39.384892],
                        [-75.631447, 39.384837999999995],
                        [-75.63176, 39.385124],
                        [-75.631935, 39.386761],
                        [-75.632401, 39.387561999999996],
                        [-75.632988, 39.387924],
                        [-75.635323, 39.38868],
                        [-75.636414, 39.38876],
                        [-75.636703, 39.388653],
                        [-75.637154, 39.387817],
                        [-75.637245, 39.38588],
                        [-75.638031, 39.384209],
                        [-75.63857999999999, 39.384192999999996],
                        [-75.639229, 39.385567],
                        [-75.639442, 39.385784],
                        [-75.63991500000002, 39.385875999999996],
                        [-75.640739, 39.385128],
                        [-75.64175399999999, 39.383072],
                        [-75.64177699999999, 39.382652],
                        [-75.642136, 39.382183],
                        [-75.644302, 39.382262999999995],
                        [-75.64542399999999, 39.382132999999996],
                        [-75.6464, 39.380192],
                        [-75.646515, 39.379669],
                        [-75.64628600000002, 39.379295],
                        [-75.643738, 39.378384],
                        [-75.64357799999999, 39.378113],
                        [-75.643616, 39.377716],
                        [-75.643806, 39.377254],
                        [-75.644089, 39.377014],
                        [-75.646194, 39.376431],
                        [-75.64782, 39.376464999999996],
                        [-75.648437, 39.376616999999996],
                        [-75.649803, 39.376118],
                        [-75.650351, 39.375690999999996],
                        [-75.651759, 39.37585],
                        [-75.652177, 39.375512],
                        [-75.653205, 39.375212999999995],
                        [-75.653868, 39.375189999999996],
                        [-75.654739, 39.37556],
                        [-75.65621300000001, 39.374900000000004],
                        [-75.658103, 39.374795999999996],
                        [-75.65877499999999, 39.374159999999996],
                        [-75.659745, 39.37383],
                        [-75.66167399999999, 39.372648999999996],
                        [-75.663375, 39.374517],
                        [-75.664647, 39.376760999999995],
                        [-75.66524799999999, 39.379173],
                        [-75.66520799999999, 39.380579999999995],
                        [-75.66553499999999, 39.380812],
                        [-75.666214, 39.382067],
                        [-75.668778, 39.385422],
                        [-75.672375, 39.388493000000004],
                        [-75.67300999999999, 39.389336],
                        [-75.675811, 39.3921],
                        [-75.681135, 39.396783],
                        [-75.684514, 39.399491999999995],
                        [-75.68751999999999, 39.401339],
                        [-75.69019899999999, 39.402581],
                        [-75.691338, 39.40394],
                        [-75.692677, 39.405024],
                        [-75.697428, 39.407303],
                        [-75.698679, 39.408051],
                        [-75.69954299999999, 39.408753999999995],
                        [-75.703693, 39.413181],
                        [-75.704932, 39.41625],
                        [-75.705576, 39.420010999999995],
                        [-75.70621, 39.422509999999996],
                        [-75.707094, 39.424897],
                        [-75.708125, 39.427113999999996],
                        [-75.708741, 39.429283999999996],
                        [-75.70947, 39.430803],
                        [-75.71331099999999, 39.437284999999996]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      },
      oldBoundaries: {
        year: 2010,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-168-07",
                  name: "Census Tract 168.07",
                  type: "CensusTract",
                  id: "census-tract-168-07"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.653479, 39.452028],
                        [-75.652332, 39.45308],
                        [-75.651042, 39.453435],
                        [-75.648887, 39.454953],
                        [-75.647466, 39.456903],
                        [-75.647274, 39.45738],
                        [-75.647054, 39.45919],
                        [-75.646748, 39.459355],
                        [-75.644362, 39.459779],
                        [-75.641578, 39.461364],
                        [-75.640676, 39.461532],
                        [-75.638362, 39.461349],
                        [-75.636883, 39.46051],
                        [-75.635936, 39.46045],
                        [-75.635307, 39.461393],
                        [-75.634979, 39.462275],
                        [-75.63421, 39.463241],
                        [-75.631749, 39.463107],
                        [-75.630216, 39.463279],
                        [-75.627937, 39.462281],
                        [-75.626015, 39.462312],
                        [-75.625001, 39.462603],
                        [-75.624523, 39.462925],
                        [-75.624545, 39.463557],
                        [-75.625642, 39.464606],
                        [-75.62584, 39.465571],
                        [-75.625741, 39.466276],
                        [-75.625177, 39.467032],
                        [-75.624292, 39.467464],
                        [-75.623504, 39.467397],
                        [-75.622622, 39.46698],
                        [-75.622103, 39.466466],
                        [-75.621435, 39.466235],
                        [-75.620861, 39.466332],
                        [-75.619538, 39.467419],
                        [-75.618654, 39.467491],
                        [-75.616573, 39.467228],
                        [-75.615927, 39.466963],
                        [-75.613603, 39.4657],
                        [-75.612091, 39.465225],
                        [-75.609749, 39.463577],
                        [-75.608849, 39.462357],
                        [-75.608856, 39.461697],
                        [-75.609406, 39.461243],
                        [-75.609604, 39.460487],
                        [-75.609581, 39.459114],
                        [-75.609955, 39.458263],
                        [-75.611664, 39.456135],
                        [-75.611908, 39.455254],
                        [-75.611794, 39.453644],
                        [-75.61158, 39.453197],
                        [-75.609123, 39.452709],
                        [-75.607567, 39.451775],
                        [-75.606659, 39.450329],
                        [-75.606155, 39.449825],
                        [-75.603386, 39.448402],
                        [-75.601547, 39.447266],
                        [-75.599754, 39.446678],
                        [-75.597321, 39.448029],
                        [-75.596733, 39.448112],
                        [-75.596024, 39.447941],
                        [-75.595901, 39.446194],
                        [-75.595444, 39.445728],
                        [-75.59465, 39.445377],
                        [-75.592216, 39.445595],
                        [-75.59127, 39.445984],
                        [-75.590973, 39.446472],
                        [-75.59111, 39.44762],
                        [-75.590889, 39.447968],
                        [-75.587601, 39.447994],
                        [-75.586342, 39.448429],
                        [-75.585037, 39.448456],
                        [-75.58429, 39.447353],
                        [-75.583534, 39.446884],
                        [-75.582558, 39.44672],
                        [-75.581886, 39.447105],
                        [-75.58140995992636, 39.44648091933865],
                        [-75.58078394674496, 39.44670192743947],
                        [-75.58040391255226, 39.44718393730273],
                        [-75.58010192182074, 39.4472139552577],
                        [-75.57995594893126, 39.447437925547064],
                        [-75.57949301011892, 39.447622907719904],
                        [-75.5792100844944, 39.447361947314334],
                        [-75.57898907663585, 39.44651595277319],
                        [-75.57859407701694, 39.4459629534252],
                        [-75.57767402230718, 39.44540691280099],
                        [-75.57697196843517, 39.44531891571521],
                        [-75.57639303317605, 39.44573890696971],
                        [-75.5756130309179, 39.445107915377804],
                        [-75.57548796621583, 39.44523387827473],
                        [-75.57507308005, 39.44505294467477],
                        [-75.57497503201614, 39.44479091586877],
                        [-75.57462392899873, 39.44512991],
                        [-75.57422497668101, 39.445045913059865],
                        [-75.57329506501148, 39.4453669269694],
                        [-75.57267611667775, 39.444614974238796],
                        [-75.57269906383314, 39.44437893653291],
                        [-75.5723530281447, 39.44401791450147],
                        [-75.57122507017188, 39.44338894083087],
                        [-75.5711550874983, 39.44311203094833],
                        [-75.57127706439535, 39.44292593702452],
                        [-75.5707471169504, 39.44263194370213],
                        [-75.57112996373472, 39.44261408255045],
                        [-75.57112110210906, 39.44249106009639],
                        [-75.5718080194599, 39.44226708805097],
                        [-75.57218502138038, 39.44228908745607],
                        [-75.57223605038034, 39.442140076827535],
                        [-75.57344900089464, 39.44164809003843],
                        [-75.57343903353741, 39.44134409514223],
                        [-75.5744670397383, 39.44073308078201],
                        [-75.574554066561, 39.44047508285406],
                        [-75.57474006026844, 39.44064606688639],
                        [-75.57530400252672, 39.44015109013389],
                        [-75.57499908355436, 39.43991796059092],
                        [-75.57499010739353, 39.43963202829935],
                        [-75.57522609607625, 39.43865501254641],
                        [-75.57545308937888, 39.43852498869681],
                        [-75.57538709217116, 39.43769702368168],
                        [-75.575928, 39.437836],
                        [-75.577141, 39.439194],
                        [-75.577904, 39.439781],
                        [-75.578377, 39.439938],
                        [-75.579056, 39.440029],
                        [-75.579659, 39.439884],
                        [-75.580719, 39.439224],
                        [-75.58107, 39.438702],
                        [-75.580849, 39.438263],
                        [-75.579895, 39.437469],
                        [-75.579636, 39.436893],
                        [-75.579872, 39.43626],
                        [-75.582001, 39.435612],
                        [-75.58242, 39.435192],
                        [-75.582359, 39.434849],
                        [-75.580643, 39.433186],
                        [-75.580322, 39.432529],
                        [-75.580368, 39.431946],
                        [-75.580681, 39.431526],
                        [-75.581184, 39.431438],
                        [-75.582672, 39.431831],
                        [-75.583145, 39.431736],
                        [-75.583794, 39.431229],
                        [-75.584645, 39.431178],
                        [-75.585304, 39.43129],
                        [-75.586647, 39.431847],
                        [-75.587769, 39.431519],
                        [-75.588959, 39.430748],
                        [-75.59082, 39.43074],
                        [-75.592209, 39.430321],
                        [-75.592461, 39.430481],
                        [-75.593163, 39.431961],
                        [-75.593468, 39.432297],
                        [-75.594506, 39.432661],
                        [-75.595848, 39.432381],
                        [-75.595947, 39.431229],
                        [-75.595375, 39.430233],
                        [-75.595261, 39.429695],
                        [-75.595459, 39.429489],
                        [-75.596542, 39.429501],
                        [-75.597244, 39.42981],
                        [-75.597603, 39.43029],
                        [-75.598434, 39.429516],
                        [-75.599091, 39.429573],
                        [-75.600029, 39.430626],
                        [-75.600675, 39.430819],
                        [-75.601006, 39.430695],
                        [-75.601074, 39.430389],
                        [-75.600822, 39.430042],
                        [-75.599373, 39.428738],
                        [-75.598991, 39.427959],
                        [-75.598953, 39.427334],
                        [-75.600792, 39.426907],
                        [-75.600952, 39.426517],
                        [-75.600479, 39.425846],
                        [-75.599915, 39.425648],
                        [-75.59906, 39.425606],
                        [-75.599091, 39.424816],
                        [-75.601677, 39.422203],
                        [-75.601707, 39.420609],
                        [-75.602287, 39.419903],
                        [-75.603554, 39.419147],
                        [-75.605667, 39.418442],
                        [-75.607491, 39.418484],
                        [-75.607773, 39.418232],
                        [-75.607918, 39.417099],
                        [-75.6082, 39.416828],
                        [-75.609923, 39.416177],
                        [-75.610227, 39.415924],
                        [-75.610249, 39.415511],
                        [-75.610008, 39.414356],
                        [-75.609589, 39.414238],
                        [-75.608971, 39.41457],
                        [-75.607224, 39.414982],
                        [-75.606644, 39.415684],
                        [-75.606277, 39.415749],
                        [-75.605873, 39.415554],
                        [-75.605461, 39.415146],
                        [-75.60511, 39.41404],
                        [-75.605125, 39.413265],
                        [-75.60482, 39.41275],
                        [-75.604485, 39.412746],
                        [-75.603722, 39.413212],
                        [-75.603241, 39.414162],
                        [-75.603386, 39.414871],
                        [-75.602707, 39.415291],
                        [-75.60247, 39.414997],
                        [-75.602493, 39.412144],
                        [-75.602242, 39.411312],
                        [-75.60041, 39.409866],
                        [-75.5998, 39.409206],
                        [-75.599701, 39.408798],
                        [-75.600418, 39.407871],
                        [-75.600449, 39.407249],
                        [-75.600113, 39.406956],
                        [-75.599115, 39.406726],
                        [-75.598846, 39.406479],
                        [-75.598801, 39.40601],
                        [-75.59935, 39.405293],
                        [-75.599291, 39.404973],
                        [-75.601143, 39.403968],
                        [-75.602327, 39.401264],
                        [-75.602926, 39.40092],
                        [-75.607891, 39.400674],
                        [-75.6152, 39.402129],
                        [-75.615799, 39.401985],
                        [-75.617377, 39.400946],
                        [-75.618049, 39.400841],
                        [-75.618488, 39.400984],
                        [-75.620939, 39.402588],
                        [-75.629, 39.415785],
                        [-75.629504, 39.417331],
                        [-75.629672, 39.420312],
                        [-75.630152, 39.421999],
                        [-75.631218, 39.424267],
                        [-75.631669, 39.427048],
                        [-75.633923, 39.431529],
                        [-75.638229, 39.436207],
                        [-75.639985, 39.437539],
                        [-75.643971, 39.439841],
                        [-75.643745, 39.440475],
                        [-75.643816, 39.440946],
                        [-75.647999, 39.448335],
                        [-75.650273, 39.450153],
                        [-75.653584, 39.451932],
                        [-75.653479, 39.452028]
                      ]
                    ]
                  ]
                }
              }
            ]
          },
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-168-08",
                  name: "Census Tract 168.08",
                  type: "CensusTract",
                  id: "census-tract-168-08"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.713311, 39.437285],
                        [-75.712051, 39.437866],
                        [-75.711385, 39.437954],
                        [-75.709693, 39.437659],
                        [-75.708891, 39.437334],
                        [-75.708371, 39.436657],
                        [-75.706369, 39.436343],
                        [-75.705428, 39.436483],
                        [-75.704499, 39.436851],
                        [-75.701803, 39.436769],
                        [-75.701136, 39.436938],
                        [-75.698159, 39.436972],
                        [-75.695777, 39.437494],
                        [-75.694785, 39.437516],
                        [-75.693542, 39.437974],
                        [-75.69165, 39.437935],
                        [-75.689545, 39.438538],
                        [-75.687065, 39.438278],
                        [-75.684898, 39.439297],
                        [-75.683784, 39.439327],
                        [-75.682426, 39.439014],
                        [-75.680222, 39.439049],
                        [-75.677078, 39.438614],
                        [-75.674583, 39.43922],
                        [-75.672981, 39.44062],
                        [-75.672073, 39.440647],
                        [-75.670357, 39.439541],
                        [-75.669357, 39.439571],
                        [-75.668762, 39.439392],
                        [-75.668344, 39.438549],
                        [-75.6679, 39.438346],
                        [-75.66734, 39.43819],
                        [-75.665516, 39.4382],
                        [-75.665458, 39.438337],
                        [-75.665989, 39.439088],
                        [-75.666019, 39.43953],
                        [-75.66491, 39.439977],
                        [-75.664711, 39.440821],
                        [-75.664174, 39.441487],
                        [-75.66395, 39.442471],
                        [-75.664322, 39.443453],
                        [-75.66376, 39.444198],
                        [-75.663502, 39.44432],
                        [-75.662437, 39.444164],
                        [-75.662048, 39.444286],
                        [-75.661591, 39.444775],
                        [-75.661102, 39.444996],
                        [-75.660637, 39.444939],
                        [-75.659645, 39.444263],
                        [-75.65905, 39.444225],
                        [-75.658585, 39.444611],
                        [-75.658508, 39.44495],
                        [-75.658699, 39.44529],
                        [-75.659317, 39.44545],
                        [-75.659813, 39.445827],
                        [-75.65982, 39.44628],
                        [-75.659462, 39.446732],
                        [-75.657684, 39.447323],
                        [-75.6567, 39.447411],
                        [-75.656212, 39.447693],
                        [-75.656161, 39.448155],
                        [-75.655886, 39.447906],
                        [-75.655357, 39.448531],
                        [-75.65471, 39.448023],
                        [-75.653836, 39.448299],
                        [-75.653533, 39.448887],
                        [-75.653677, 39.449658],
                        [-75.65439, 39.45119],
                        [-75.653584, 39.451932],
                        [-75.6506, 39.450367],
                        [-75.647999, 39.448335],
                        [-75.643816, 39.440946],
                        [-75.643745, 39.440475],
                        [-75.643971, 39.439841],
                        [-75.639985, 39.437539],
                        [-75.638229, 39.436207],
                        [-75.633923, 39.431529],
                        [-75.631669, 39.427048],
                        [-75.631218, 39.424267],
                        [-75.630152, 39.421999],
                        [-75.629672, 39.420312],
                        [-75.629504, 39.417331],
                        [-75.629201, 39.416198],
                        [-75.620834, 39.402475],
                        [-75.618049, 39.400841],
                        [-75.617377, 39.400946],
                        [-75.615799, 39.401985],
                        [-75.6152, 39.402129],
                        [-75.607891, 39.400674],
                        [-75.602926, 39.40092],
                        [-75.602327, 39.401264],
                        [-75.601143, 39.403968],
                        [-75.599291, 39.404973],
                        [-75.598556, 39.403302],
                        [-75.597977, 39.402397],
                        [-75.59742, 39.402065],
                        [-75.595993, 39.401909],
                        [-75.596176, 39.400963],
                        [-75.596779, 39.400341],
                        [-75.596664, 39.399067],
                        [-75.59687, 39.39735],
                        [-75.596573, 39.397079],
                        [-75.595634, 39.396969],
                        [-75.595505, 39.396729],
                        [-75.59565, 39.396423],
                        [-75.596375, 39.395847],
                        [-75.597038, 39.394444],
                        [-75.597534, 39.393909],
                        [-75.598076, 39.393749],
                        [-75.600624, 39.393753],
                        [-75.601151, 39.393047],
                        [-75.601837, 39.392574],
                        [-75.602142, 39.392612],
                        [-75.602493, 39.393063],
                        [-75.602692, 39.39444],
                        [-75.604095, 39.395168],
                        [-75.604691, 39.395321],
                        [-75.60537, 39.395229],
                        [-75.605804, 39.39402],
                        [-75.606285, 39.39357],
                        [-75.606895, 39.393539],
                        [-75.608238, 39.394211],
                        [-75.608772, 39.394192],
                        [-75.609718, 39.393951],
                        [-75.610769, 39.393304],
                        [-75.612625, 39.393589],
                        [-75.615168, 39.393446],
                        [-75.615692, 39.393585],
                        [-75.61647, 39.394901],
                        [-75.617485, 39.39521],
                        [-75.618484, 39.395267],
                        [-75.619324, 39.395065],
                        [-75.620781, 39.393433],
                        [-75.621582, 39.393124],
                        [-75.622253, 39.392639],
                        [-75.622818, 39.391937],
                        [-75.62236, 39.390743],
                        [-75.623047, 39.389107],
                        [-75.623528, 39.388603],
                        [-75.626396, 39.387466],
                        [-75.627892, 39.387398],
                        [-75.62841, 39.387119],
                        [-75.631012, 39.384892],
                        [-75.631447, 39.384838],
                        [-75.63176, 39.385124],
                        [-75.631935, 39.386761],
                        [-75.632401, 39.387562],
                        [-75.632988, 39.387924],
                        [-75.635323, 39.38868],
                        [-75.636414, 39.38876],
                        [-75.636703, 39.388653],
                        [-75.637154, 39.387817],
                        [-75.637245, 39.38588],
                        [-75.638031, 39.384209],
                        [-75.63858, 39.384193],
                        [-75.639229, 39.385567],
                        [-75.639442, 39.385784],
                        [-75.639915, 39.385876],
                        [-75.640739, 39.385128],
                        [-75.641754, 39.383072],
                        [-75.641777, 39.382652],
                        [-75.642136, 39.382183],
                        [-75.644302, 39.382263],
                        [-75.645424, 39.382133],
                        [-75.6464, 39.380192],
                        [-75.646515, 39.379669],
                        [-75.646286, 39.379295],
                        [-75.643738, 39.378384],
                        [-75.643578, 39.378113],
                        [-75.643616, 39.377716],
                        [-75.643806, 39.377254],
                        [-75.644089, 39.377014],
                        [-75.646194, 39.376431],
                        [-75.64782, 39.376465],
                        [-75.648437, 39.376617],
                        [-75.649803, 39.376118],
                        [-75.650351, 39.375691],
                        [-75.65176, 39.375848],
                        [-75.652177, 39.375512],
                        [-75.653205, 39.375213],
                        [-75.653868, 39.37519],
                        [-75.654739, 39.37556],
                        [-75.656213, 39.3749],
                        [-75.658103, 39.374796],
                        [-75.658775, 39.37416],
                        [-75.659745, 39.37383],
                        [-75.661674, 39.372649],
                        [-75.663375, 39.374517],
                        [-75.664647, 39.376761],
                        [-75.665248, 39.379173],
                        [-75.665208, 39.38058],
                        [-75.665535, 39.380812],
                        [-75.666214, 39.382067],
                        [-75.668778, 39.385422],
                        [-75.672375, 39.388493],
                        [-75.67301, 39.389336],
                        [-75.675811, 39.3921],
                        [-75.68007, 39.395846],
                        [-75.681255, 39.396871],
                        [-75.68146, 39.396678],
                        [-75.683163, 39.397883],
                        [-75.684514, 39.399492],
                        [-75.68752, 39.401339],
                        [-75.690199, 39.402581],
                        [-75.691338, 39.40394],
                        [-75.692677, 39.405024],
                        [-75.697428, 39.407303],
                        [-75.698679, 39.408051],
                        [-75.699543, 39.408754],
                        [-75.703693, 39.413181],
                        [-75.704932, 39.41625],
                        [-75.705576, 39.420011],
                        [-75.70621, 39.42251],
                        [-75.707094, 39.424897],
                        [-75.708125, 39.427114],
                        [-75.708741, 39.429284],
                        [-75.70947, 39.430803],
                        [-75.713311, 39.437285]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  },
  "census-tract-105-02": {
    __typename: "MhcLocation",
    id: "census-tract-105-02",
    name: "Census Tract 105.02",
    geography: "census_tract",
    mapData: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            featureType: "location",
            path: "locations/census-tract-105-02",
            name: "Census Tract 105.02",
            type: "CensusTract",
            id: "census-tract-105-02"
          },
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-75.50939, 39.76644900000001],
                  [-75.509347, 39.76658],
                  [-75.497519, 39.772037999999995],
                  [-75.478117, 39.784859],
                  [-75.47420600000001, 39.788405999999995],
                  [-75.473394, 39.788031],
                  [-75.47287, 39.78742],
                  [-75.471501, 39.787045],
                  [-75.47137099999999, 39.786805],
                  [-75.471958, 39.786566],
                  [-75.47195400000001, 39.786271],
                  [-75.471423, 39.785669999999996],
                  [-75.47016599999999, 39.784949999999995],
                  [-75.47095007961096, 39.783848041988556],
                  [-75.47115307052377, 39.78317805661888],
                  [-75.47158102430902, 39.78289508799834],
                  [-75.47209203269841, 39.78280208392616],
                  [-75.47352306294987, 39.781635064329365],
                  [-75.47355807434657, 39.78140606156525],
                  [-75.47459507035126, 39.780108063367344],
                  [-75.4753990467383, 39.7794930769773],
                  [-75.47644208042215, 39.77805205908668],
                  [-75.47670808839283, 39.77792501710272],
                  [-75.476861106426, 39.7775360366644],
                  [-75.47714206753912, 39.77739705950544],
                  [-75.47820908034689, 39.7761290405686],
                  [-75.47894206539138, 39.774953062283316],
                  [-75.48001006419895, 39.77391806319607],
                  [-75.47965202133851, 39.773690912564746],
                  [-75.47923002795525, 39.77368686918756],
                  [-75.47910106682485, 39.77328693970897],
                  [-75.47767813556356, 39.77226898143777],
                  [-75.47783202282739, 39.772096119980574],
                  [-75.47817397509644, 39.77223209420109],
                  [-75.47911402664788, 39.77304108601948],
                  [-75.47912005434078, 39.77282907760136],
                  [-75.48026702959852, 39.77258408499483],
                  [-75.48055602296462, 39.77244008712697],
                  [-75.4805030531012, 39.772280080359],
                  [-75.4809410313898, 39.77237008436468],
                  [-75.48140597716575, 39.77213908715008],
                  [-75.48149208070998, 39.771618051195254],
                  [-75.4821780642489, 39.77114606303476],
                  [-75.48252509212313, 39.76993702527732],
                  [-75.4827451195219, 39.76951902901402],
                  [-75.48300408746485, 39.76951197841249],
                  [-75.4830310275683, 39.76908291420659],
                  [-75.48210910330721, 39.76875501015181],
                  [-75.48264207362666, 39.767539051782855],
                  [-75.4829580518308, 39.766091121103514],
                  [-75.48350009, 39.76626599715921],
                  [-75.48298709, 39.76604992144315],
                  [-75.483124088844, 39.76546102476361],
                  [-75.48513806870665, 39.76223105816627],
                  [-75.48513707972293, 39.7620080423531],
                  [-75.48568008682078, 39.76125602373223],
                  [-75.48572209049246, 39.7608610451326],
                  [-75.48637007174484, 39.760212054338574],
                  [-75.48768308200188, 39.75832403710892],
                  [-75.48814907126187, 39.75716605687406],
                  [-75.48929005581927, 39.75599407139016],
                  [-75.49041905314539, 39.7552340734516],
                  [-75.49090697458844, 39.75503210870182],
                  [-75.49112296481573, 39.755186082842975],
                  [-75.49155601184826, 39.75516708923168],
                  [-75.49196405047462, 39.75487107451788],
                  [-75.49230607695768, 39.75449504666577],
                  [-75.49237908239871, 39.754071040311075],
                  [-75.493658, 39.752587],
                  [-75.494305, 39.75275],
                  [-75.495188, 39.752683999999995],
                  [-75.495618, 39.753036],
                  [-75.497599, 39.753412999999995],
                  [-75.49763399999999, 39.75381],
                  [-75.49748799999999, 39.753972999999995],
                  [-75.495492, 39.755244],
                  [-75.495364, 39.755493],
                  [-75.495513, 39.755569],
                  [-75.496934, 39.755114999999996],
                  [-75.498221, 39.755114],
                  [-75.49820600000001, 39.755246],
                  [-75.495131, 39.756253],
                  [-75.49326599999999, 39.757099],
                  [-75.50939, 39.76644900000001]
                ]
              ]
            ]
          }
        }
      ]
    },
    population: 0,
    deprecationInfo: {
      status: "deprecated",
      newBoundaries: {
        year: 2020,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-168-04",
                  name: "Census Tract 168.04",
                  type: "CensusTract",
                  id: "census-tract-168-04"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.71331099999999, 39.437284999999996],
                        [-75.712051, 39.437866],
                        [-75.71138499999999, 39.437954],
                        [-75.709693, 39.437659],
                        [-75.708891, 39.437334],
                        [-75.70837100000001, 39.436657],
                        [-75.706369, 39.436343],
                        [-75.705428, 39.436482999999996],
                        [-75.704499, 39.436851],
                        [-75.701803, 39.436769],
                        [-75.70113599999999, 39.436938],
                        [-75.69815899999999, 39.436972000000004],
                        [-75.69577699999999, 39.437494],
                        [-75.694785, 39.437515999999995],
                        [-75.693542, 39.437974],
                        [-75.69165, 39.437934999999996],
                        [-75.689545, 39.438538],
                        [-75.68706499999999, 39.438278],
                        [-75.68489799999999, 39.439296999999996],
                        [-75.683784, 39.439327],
                        [-75.682426, 39.439014],
                        [-75.680222, 39.439049],
                        [-75.677078, 39.438614],
                        [-75.674583, 39.43922],
                        [-75.672981, 39.440619999999996],
                        [-75.672073, 39.440647],
                        [-75.670357, 39.439541],
                        [-75.66935699999999, 39.439571],
                        [-75.668762, 39.439392],
                        [-75.66834399999999, 39.438548999999995],
                        [-75.6679, 39.438345999999996],
                        [-75.66734, 39.43819],
                        [-75.665516, 39.4382],
                        [-75.665458, 39.438337],
                        [-75.665989, 39.439088],
                        [-75.66601899999999, 39.43953],
                        [-75.66490999999999, 39.439977],
                        [-75.664711, 39.440821],
                        [-75.664174, 39.441486999999995],
                        [-75.66395, 39.442471],
                        [-75.664322, 39.443453],
                        [-75.66376, 39.444198],
                        [-75.663502, 39.44432],
                        [-75.662437, 39.444164],
                        [-75.662048, 39.444286],
                        [-75.661591, 39.444775],
                        [-75.661102, 39.444995999999996],
                        [-75.660637, 39.444939],
                        [-75.659645, 39.444263],
                        [-75.65905, 39.444224999999996],
                        [-75.658585, 39.444610999999995],
                        [-75.65850800000001, 39.44495],
                        [-75.658699, 39.44529],
                        [-75.659317, 39.44545],
                        [-75.659813, 39.445827],
                        [-75.65982, 39.44628],
                        [-75.65946199999999, 39.446732],
                        [-75.657684, 39.447323],
                        [-75.6567, 39.447410999999995],
                        [-75.656212, 39.447693],
                        [-75.656161, 39.448155],
                        [-75.655886, 39.447905999999996],
                        [-75.655357, 39.448530999999996],
                        [-75.65471, 39.448023],
                        [-75.653836, 39.448299],
                        [-75.653533, 39.448887],
                        [-75.653677, 39.449658],
                        [-75.654263, 39.450839],
                        [-75.652642, 39.452676],
                        [-75.650318, 39.453790999999995],
                        [-75.648887, 39.454953],
                        [-75.647466, 39.456903],
                        [-75.647274, 39.45738],
                        [-75.647054, 39.45919],
                        [-75.64612, 39.45949900000001],
                        [-75.644362, 39.459779],
                        [-75.641578, 39.461364],
                        [-75.640676, 39.461532],
                        [-75.638362, 39.461349],
                        [-75.636883, 39.46051],
                        [-75.635936, 39.46045000000001],
                        [-75.635307, 39.461393],
                        [-75.63497900000002, 39.462275],
                        [-75.63421, 39.463241],
                        [-75.631749, 39.463107],
                        [-75.63021599999999, 39.463279],
                        [-75.627937, 39.462281],
                        [-75.626015, 39.462312],
                        [-75.625001, 39.462603],
                        [-75.624523, 39.462925],
                        [-75.62445699999999, 39.463215],
                        [-75.62466599999999, 39.46374],
                        [-75.625642, 39.464605999999996],
                        [-75.62584000000001, 39.465571],
                        [-75.62574099999999, 39.466276],
                        [-75.625177, 39.467031999999996],
                        [-75.624292, 39.467464],
                        [-75.623504, 39.467397],
                        [-75.62262199999999, 39.46698],
                        [-75.622103, 39.466466],
                        [-75.62143499999999, 39.466235],
                        [-75.620861, 39.466332],
                        [-75.61953799999999, 39.467419],
                        [-75.61865399999999, 39.467490999999995],
                        [-75.616573, 39.467228],
                        [-75.613603, 39.465700000000005],
                        [-75.61209099999999, 39.465225],
                        [-75.609749, 39.463577],
                        [-75.60884899999999, 39.462357],
                        [-75.608856, 39.461697],
                        [-75.60940599999999, 39.461242999999996],
                        [-75.60960399999999, 39.460487],
                        [-75.60958099999999, 39.459114],
                        [-75.609955, 39.458262999999995],
                        [-75.61166399999999, 39.456134999999996],
                        [-75.611908, 39.455254],
                        [-75.611794, 39.453644],
                        [-75.61158, 39.453196999999996],
                        [-75.609123, 39.452709],
                        [-75.607567, 39.451775],
                        [-75.606659, 39.450328999999996],
                        [-75.606155, 39.449825],
                        [-75.600937, 39.44698],
                        [-75.59975399999999, 39.446678],
                        [-75.597321, 39.448029],
                        [-75.596733, 39.448111999999995],
                        [-75.596024, 39.447941],
                        [-75.59590100000001, 39.446194],
                        [-75.595444, 39.445727999999995],
                        [-75.59465, 39.445377],
                        [-75.592216, 39.445595000000004],
                        [-75.59127000000001, 39.445984],
                        [-75.59097299999999, 39.446472],
                        [-75.59111, 39.44762],
                        [-75.59088899999999, 39.447967999999996],
                        [-75.58760099999999, 39.447994],
                        [-75.586342, 39.448429],
                        [-75.585037, 39.448456],
                        [-75.58429, 39.447353],
                        [-75.583534, 39.446884],
                        [-75.58255799999999, 39.446720000000006],
                        [-75.581886, 39.447105],
                        [-75.58140995992636, 39.44648091933865],
                        [-75.58078394674496, 39.44670192743947],
                        [-75.58040391255226, 39.44718393730273],
                        [-75.58010192182074, 39.4472139552577],
                        [-75.57995594893126, 39.447437925547064],
                        [-75.57949301011892, 39.447622907719904],
                        [-75.5792100844944, 39.447361947314334],
                        [-75.57898907663585, 39.44651595277319],
                        [-75.57859407701694, 39.4459629534252],
                        [-75.57767402230718, 39.44540691280099],
                        [-75.57697196843517, 39.44531891571521],
                        [-75.57639303317605, 39.44573890696971],
                        [-75.5756130309179, 39.445107915377804],
                        [-75.57548796621583, 39.44523387827473],
                        [-75.57507308005, 39.44505294467477],
                        [-75.57497503201614, 39.44479091586877],
                        [-75.57462392899873, 39.44512991],
                        [-75.57422497668101, 39.445045913059865],
                        [-75.57329506501148, 39.4453669269694],
                        [-75.57267611667775, 39.444614974238796],
                        [-75.57269906383314, 39.44437893653291],
                        [-75.5723530281447, 39.44401791450147],
                        [-75.57122507017188, 39.44338894083087],
                        [-75.5711550874983, 39.44311203094833],
                        [-75.57127706439535, 39.44292593702452],
                        [-75.5707471169504, 39.44263194370213],
                        [-75.57112996373472, 39.44261408255045],
                        [-75.57112110210906, 39.44249106009639],
                        [-75.5718080194599, 39.44226708805097],
                        [-75.57218502138038, 39.44228908745607],
                        [-75.57223605038034, 39.442140076827535],
                        [-75.57344900089464, 39.44164809003843],
                        [-75.57343903353741, 39.44134409514223],
                        [-75.5744670397383, 39.44073308078201],
                        [-75.574554066561, 39.44047508285406],
                        [-75.57474006026844, 39.44064606688639],
                        [-75.57530400252672, 39.44015109013389],
                        [-75.57499908355436, 39.43991796059092],
                        [-75.57499010739353, 39.43963202829935],
                        [-75.57522609607625, 39.43865501254641],
                        [-75.57545308937888, 39.43852498869681],
                        [-75.57538699999999, 39.437697],
                        [-75.57592799999999, 39.437836],
                        [-75.577141, 39.439194],
                        [-75.57790399999999, 39.439780999999996],
                        [-75.578377, 39.439938],
                        [-75.579056, 39.440028999999996],
                        [-75.57965899999999, 39.439884],
                        [-75.580719, 39.439223999999996],
                        [-75.58107, 39.438702],
                        [-75.580849, 39.438263],
                        [-75.579895, 39.437469],
                        [-75.579636, 39.436893],
                        [-75.579872, 39.43626],
                        [-75.58200099999999, 39.435612],
                        [-75.58242, 39.435192],
                        [-75.582359, 39.434849],
                        [-75.580643, 39.433186],
                        [-75.580322, 39.432528999999995],
                        [-75.58036799999999, 39.431945999999996],
                        [-75.580681, 39.431526],
                        [-75.581184, 39.431438],
                        [-75.582672, 39.431830999999995],
                        [-75.583145, 39.431736],
                        [-75.583794, 39.431228999999995],
                        [-75.584645, 39.431177999999996],
                        [-75.585304, 39.43129],
                        [-75.586647, 39.431847],
                        [-75.587769, 39.431519],
                        [-75.588959, 39.430748],
                        [-75.59082, 39.43074],
                        [-75.592209, 39.430321],
                        [-75.592461, 39.430481],
                        [-75.59316299999999, 39.431961],
                        [-75.593468, 39.432297],
                        [-75.594506, 39.432660999999996],
                        [-75.59584799999999, 39.432381],
                        [-75.595947, 39.431228999999995],
                        [-75.59537499999999, 39.430233],
                        [-75.595261, 39.429695],
                        [-75.59545899999999, 39.429489],
                        [-75.596542, 39.429500999999995],
                        [-75.597244, 39.429809999999996],
                        [-75.59760299999999, 39.43029],
                        [-75.598434, 39.429516],
                        [-75.599091, 39.429573],
                        [-75.60002899999999, 39.430626],
                        [-75.600675, 39.43081900000001],
                        [-75.601006, 39.430695],
                        [-75.601074, 39.430389],
                        [-75.600822, 39.430042],
                        [-75.599373, 39.428737999999996],
                        [-75.598991, 39.42795900000001],
                        [-75.598953, 39.427333999999995],
                        [-75.600792, 39.426907],
                        [-75.60095199999999, 39.426517],
                        [-75.60047899999999, 39.425846],
                        [-75.599915, 39.425647999999995],
                        [-75.59906, 39.425605999999995],
                        [-75.599052, 39.425132999999995],
                        [-75.599091, 39.424816],
                        [-75.601677, 39.422202999999996],
                        [-75.60170699999999, 39.420609],
                        [-75.601891, 39.420303],
                        [-75.60228699999999, 39.419903000000005],
                        [-75.603554, 39.419146999999995],
                        [-75.605667, 39.418442],
                        [-75.607491, 39.418484],
                        [-75.607773, 39.418231999999996],
                        [-75.607834, 39.417358],
                        [-75.6082, 39.416828],
                        [-75.61026, 39.416042],
                        [-75.61175500000002, 39.415928],
                        [-75.611801, 39.415543],
                        [-75.611549, 39.415431999999996],
                        [-75.610336, 39.415348],
                        [-75.610085, 39.414955],
                        [-75.610008, 39.414356000000005],
                        [-75.609589, 39.414238],
                        [-75.608971, 39.41457],
                        [-75.607224, 39.414981999999995],
                        [-75.606644, 39.415684],
                        [-75.60627699999999, 39.415749],
                        [-75.60546099999999, 39.415146],
                        [-75.60511, 39.41404],
                        [-75.605125, 39.413264999999996],
                        [-75.60481999999999, 39.412749999999996],
                        [-75.604485, 39.412746],
                        [-75.60372199999999, 39.413212],
                        [-75.603241, 39.414162],
                        [-75.603386, 39.414871],
                        [-75.602707, 39.415290999999996],
                        [-75.60247, 39.414997],
                        [-75.602493, 39.412144],
                        [-75.60224199999999, 39.411311999999995],
                        [-75.60041, 39.409866],
                        [-75.5998, 39.409206],
                        [-75.599701, 39.408798],
                        [-75.600533, 39.407513],
                        [-75.600113, 39.406956],
                        [-75.599115, 39.406726],
                        [-75.598846, 39.406479],
                        [-75.598801, 39.406009999999995],
                        [-75.59935, 39.405293],
                        [-75.598556, 39.403302],
                        [-75.597977, 39.40239700000001],
                        [-75.59742, 39.402065],
                        [-75.595993, 39.401908999999996],
                        [-75.596176, 39.400963],
                        [-75.596779, 39.400341],
                        [-75.59666399999999, 39.399066999999995],
                        [-75.59687, 39.397349999999996],
                        [-75.59657299999999, 39.397079],
                        [-75.59563399999999, 39.396969],
                        [-75.595505, 39.396729],
                        [-75.59564999999999, 39.396423],
                        [-75.596375, 39.395846999999996],
                        [-75.597038, 39.394444],
                        [-75.597534, 39.393909],
                        [-75.59807599999999, 39.393749],
                        [-75.60062400000001, 39.393753],
                        [-75.601151, 39.393046999999996],
                        [-75.601837, 39.392573999999996],
                        [-75.602142, 39.392612],
                        [-75.602493, 39.393063],
                        [-75.60269199999999, 39.394439999999996],
                        [-75.604095, 39.395168],
                        [-75.604691, 39.395320999999996],
                        [-75.60537, 39.395229],
                        [-75.60580399999999, 39.39402],
                        [-75.606285, 39.39357],
                        [-75.606895, 39.393539],
                        [-75.608238, 39.394211000000006],
                        [-75.608772, 39.394192],
                        [-75.609718, 39.393951],
                        [-75.61076899999999, 39.393304],
                        [-75.612625, 39.393589],
                        [-75.615168, 39.393446],
                        [-75.615692, 39.393585],
                        [-75.61646999999999, 39.394901],
                        [-75.617485, 39.39521],
                        [-75.618484, 39.395267],
                        [-75.61932399999999, 39.395064999999995],
                        [-75.620781, 39.393433],
                        [-75.621582, 39.393124],
                        [-75.622253, 39.392638999999996],
                        [-75.622818, 39.391937],
                        [-75.62236, 39.39074300000001],
                        [-75.623047, 39.389106999999996],
                        [-75.623528, 39.388602999999996],
                        [-75.626396, 39.387465999999996],
                        [-75.627892, 39.387398],
                        [-75.62841, 39.387119],
                        [-75.631012, 39.384892],
                        [-75.631447, 39.384837999999995],
                        [-75.63176, 39.385124],
                        [-75.631935, 39.386761],
                        [-75.632401, 39.387561999999996],
                        [-75.632988, 39.387924],
                        [-75.635323, 39.38868],
                        [-75.636414, 39.38876],
                        [-75.636703, 39.388653],
                        [-75.637154, 39.387817],
                        [-75.637245, 39.38588],
                        [-75.638031, 39.384209],
                        [-75.63857999999999, 39.384192999999996],
                        [-75.639229, 39.385567],
                        [-75.639442, 39.385784],
                        [-75.63991500000002, 39.385875999999996],
                        [-75.640739, 39.385128],
                        [-75.64175399999999, 39.383072],
                        [-75.64177699999999, 39.382652],
                        [-75.642136, 39.382183],
                        [-75.644302, 39.382262999999995],
                        [-75.64542399999999, 39.382132999999996],
                        [-75.6464, 39.380192],
                        [-75.646515, 39.379669],
                        [-75.64628600000002, 39.379295],
                        [-75.643738, 39.378384],
                        [-75.64357799999999, 39.378113],
                        [-75.643616, 39.377716],
                        [-75.643806, 39.377254],
                        [-75.644089, 39.377014],
                        [-75.646194, 39.376431],
                        [-75.64782, 39.376464999999996],
                        [-75.648437, 39.376616999999996],
                        [-75.649803, 39.376118],
                        [-75.650351, 39.375690999999996],
                        [-75.651759, 39.37585],
                        [-75.652177, 39.375512],
                        [-75.653205, 39.375212999999995],
                        [-75.653868, 39.375189999999996],
                        [-75.654739, 39.37556],
                        [-75.65621300000001, 39.374900000000004],
                        [-75.658103, 39.374795999999996],
                        [-75.65877499999999, 39.374159999999996],
                        [-75.659745, 39.37383],
                        [-75.66167399999999, 39.372648999999996],
                        [-75.663375, 39.374517],
                        [-75.664647, 39.376760999999995],
                        [-75.66524799999999, 39.379173],
                        [-75.66520799999999, 39.380579999999995],
                        [-75.66553499999999, 39.380812],
                        [-75.666214, 39.382067],
                        [-75.668778, 39.385422],
                        [-75.672375, 39.388493000000004],
                        [-75.67300999999999, 39.389336],
                        [-75.675811, 39.3921],
                        [-75.681135, 39.396783],
                        [-75.684514, 39.399491999999995],
                        [-75.68751999999999, 39.401339],
                        [-75.69019899999999, 39.402581],
                        [-75.691338, 39.40394],
                        [-75.692677, 39.405024],
                        [-75.697428, 39.407303],
                        [-75.698679, 39.408051],
                        [-75.69954299999999, 39.408753999999995],
                        [-75.703693, 39.413181],
                        [-75.704932, 39.41625],
                        [-75.705576, 39.420010999999995],
                        [-75.70621, 39.422509999999996],
                        [-75.707094, 39.424897],
                        [-75.708125, 39.427113999999996],
                        [-75.708741, 39.429283999999996],
                        [-75.70947, 39.430803],
                        [-75.71331099999999, 39.437284999999996]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      },
      oldBoundaries: {
        year: 2010,
        boundaries: [
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-168-07",
                  name: "Census Tract 168.07",
                  type: "CensusTract",
                  id: "census-tract-168-07"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.653479, 39.452028],
                        [-75.652332, 39.45308],
                        [-75.651042, 39.453435],
                        [-75.648887, 39.454953],
                        [-75.647466, 39.456903],
                        [-75.647274, 39.45738],
                        [-75.647054, 39.45919],
                        [-75.646748, 39.459355],
                        [-75.644362, 39.459779],
                        [-75.641578, 39.461364],
                        [-75.640676, 39.461532],
                        [-75.638362, 39.461349],
                        [-75.636883, 39.46051],
                        [-75.635936, 39.46045],
                        [-75.635307, 39.461393],
                        [-75.634979, 39.462275],
                        [-75.63421, 39.463241],
                        [-75.631749, 39.463107],
                        [-75.630216, 39.463279],
                        [-75.627937, 39.462281],
                        [-75.626015, 39.462312],
                        [-75.625001, 39.462603],
                        [-75.624523, 39.462925],
                        [-75.624545, 39.463557],
                        [-75.625642, 39.464606],
                        [-75.62584, 39.465571],
                        [-75.625741, 39.466276],
                        [-75.625177, 39.467032],
                        [-75.624292, 39.467464],
                        [-75.623504, 39.467397],
                        [-75.622622, 39.46698],
                        [-75.622103, 39.466466],
                        [-75.621435, 39.466235],
                        [-75.620861, 39.466332],
                        [-75.619538, 39.467419],
                        [-75.618654, 39.467491],
                        [-75.616573, 39.467228],
                        [-75.615927, 39.466963],
                        [-75.613603, 39.4657],
                        [-75.612091, 39.465225],
                        [-75.609749, 39.463577],
                        [-75.608849, 39.462357],
                        [-75.608856, 39.461697],
                        [-75.609406, 39.461243],
                        [-75.609604, 39.460487],
                        [-75.609581, 39.459114],
                        [-75.609955, 39.458263],
                        [-75.611664, 39.456135],
                        [-75.611908, 39.455254],
                        [-75.611794, 39.453644],
                        [-75.61158, 39.453197],
                        [-75.609123, 39.452709],
                        [-75.607567, 39.451775],
                        [-75.606659, 39.450329],
                        [-75.606155, 39.449825],
                        [-75.603386, 39.448402],
                        [-75.601547, 39.447266],
                        [-75.599754, 39.446678],
                        [-75.597321, 39.448029],
                        [-75.596733, 39.448112],
                        [-75.596024, 39.447941],
                        [-75.595901, 39.446194],
                        [-75.595444, 39.445728],
                        [-75.59465, 39.445377],
                        [-75.592216, 39.445595],
                        [-75.59127, 39.445984],
                        [-75.590973, 39.446472],
                        [-75.59111, 39.44762],
                        [-75.590889, 39.447968],
                        [-75.587601, 39.447994],
                        [-75.586342, 39.448429],
                        [-75.585037, 39.448456],
                        [-75.58429, 39.447353],
                        [-75.583534, 39.446884],
                        [-75.582558, 39.44672],
                        [-75.581886, 39.447105],
                        [-75.58140995992636, 39.44648091933865],
                        [-75.58078394674496, 39.44670192743947],
                        [-75.58040391255226, 39.44718393730273],
                        [-75.58010192182074, 39.4472139552577],
                        [-75.57995594893126, 39.447437925547064],
                        [-75.57949301011892, 39.447622907719904],
                        [-75.5792100844944, 39.447361947314334],
                        [-75.57898907663585, 39.44651595277319],
                        [-75.57859407701694, 39.4459629534252],
                        [-75.57767402230718, 39.44540691280099],
                        [-75.57697196843517, 39.44531891571521],
                        [-75.57639303317605, 39.44573890696971],
                        [-75.5756130309179, 39.445107915377804],
                        [-75.57548796621583, 39.44523387827473],
                        [-75.57507308005, 39.44505294467477],
                        [-75.57497503201614, 39.44479091586877],
                        [-75.57462392899873, 39.44512991],
                        [-75.57422497668101, 39.445045913059865],
                        [-75.57329506501148, 39.4453669269694],
                        [-75.57267611667775, 39.444614974238796],
                        [-75.57269906383314, 39.44437893653291],
                        [-75.5723530281447, 39.44401791450147],
                        [-75.57122507017188, 39.44338894083087],
                        [-75.5711550874983, 39.44311203094833],
                        [-75.57127706439535, 39.44292593702452],
                        [-75.5707471169504, 39.44263194370213],
                        [-75.57112996373472, 39.44261408255045],
                        [-75.57112110210906, 39.44249106009639],
                        [-75.5718080194599, 39.44226708805097],
                        [-75.57218502138038, 39.44228908745607],
                        [-75.57223605038034, 39.442140076827535],
                        [-75.57344900089464, 39.44164809003843],
                        [-75.57343903353741, 39.44134409514223],
                        [-75.5744670397383, 39.44073308078201],
                        [-75.574554066561, 39.44047508285406],
                        [-75.57474006026844, 39.44064606688639],
                        [-75.57530400252672, 39.44015109013389],
                        [-75.57499908355436, 39.43991796059092],
                        [-75.57499010739353, 39.43963202829935],
                        [-75.57522609607625, 39.43865501254641],
                        [-75.57545308937888, 39.43852498869681],
                        [-75.57538709217116, 39.43769702368168],
                        [-75.575928, 39.437836],
                        [-75.577141, 39.439194],
                        [-75.577904, 39.439781],
                        [-75.578377, 39.439938],
                        [-75.579056, 39.440029],
                        [-75.579659, 39.439884],
                        [-75.580719, 39.439224],
                        [-75.58107, 39.438702],
                        [-75.580849, 39.438263],
                        [-75.579895, 39.437469],
                        [-75.579636, 39.436893],
                        [-75.579872, 39.43626],
                        [-75.582001, 39.435612],
                        [-75.58242, 39.435192],
                        [-75.582359, 39.434849],
                        [-75.580643, 39.433186],
                        [-75.580322, 39.432529],
                        [-75.580368, 39.431946],
                        [-75.580681, 39.431526],
                        [-75.581184, 39.431438],
                        [-75.582672, 39.431831],
                        [-75.583145, 39.431736],
                        [-75.583794, 39.431229],
                        [-75.584645, 39.431178],
                        [-75.585304, 39.43129],
                        [-75.586647, 39.431847],
                        [-75.587769, 39.431519],
                        [-75.588959, 39.430748],
                        [-75.59082, 39.43074],
                        [-75.592209, 39.430321],
                        [-75.592461, 39.430481],
                        [-75.593163, 39.431961],
                        [-75.593468, 39.432297],
                        [-75.594506, 39.432661],
                        [-75.595848, 39.432381],
                        [-75.595947, 39.431229],
                        [-75.595375, 39.430233],
                        [-75.595261, 39.429695],
                        [-75.595459, 39.429489],
                        [-75.596542, 39.429501],
                        [-75.597244, 39.42981],
                        [-75.597603, 39.43029],
                        [-75.598434, 39.429516],
                        [-75.599091, 39.429573],
                        [-75.600029, 39.430626],
                        [-75.600675, 39.430819],
                        [-75.601006, 39.430695],
                        [-75.601074, 39.430389],
                        [-75.600822, 39.430042],
                        [-75.599373, 39.428738],
                        [-75.598991, 39.427959],
                        [-75.598953, 39.427334],
                        [-75.600792, 39.426907],
                        [-75.600952, 39.426517],
                        [-75.600479, 39.425846],
                        [-75.599915, 39.425648],
                        [-75.59906, 39.425606],
                        [-75.599091, 39.424816],
                        [-75.601677, 39.422203],
                        [-75.601707, 39.420609],
                        [-75.602287, 39.419903],
                        [-75.603554, 39.419147],
                        [-75.605667, 39.418442],
                        [-75.607491, 39.418484],
                        [-75.607773, 39.418232],
                        [-75.607918, 39.417099],
                        [-75.6082, 39.416828],
                        [-75.609923, 39.416177],
                        [-75.610227, 39.415924],
                        [-75.610249, 39.415511],
                        [-75.610008, 39.414356],
                        [-75.609589, 39.414238],
                        [-75.608971, 39.41457],
                        [-75.607224, 39.414982],
                        [-75.606644, 39.415684],
                        [-75.606277, 39.415749],
                        [-75.605873, 39.415554],
                        [-75.605461, 39.415146],
                        [-75.60511, 39.41404],
                        [-75.605125, 39.413265],
                        [-75.60482, 39.41275],
                        [-75.604485, 39.412746],
                        [-75.603722, 39.413212],
                        [-75.603241, 39.414162],
                        [-75.603386, 39.414871],
                        [-75.602707, 39.415291],
                        [-75.60247, 39.414997],
                        [-75.602493, 39.412144],
                        [-75.602242, 39.411312],
                        [-75.60041, 39.409866],
                        [-75.5998, 39.409206],
                        [-75.599701, 39.408798],
                        [-75.600418, 39.407871],
                        [-75.600449, 39.407249],
                        [-75.600113, 39.406956],
                        [-75.599115, 39.406726],
                        [-75.598846, 39.406479],
                        [-75.598801, 39.40601],
                        [-75.59935, 39.405293],
                        [-75.599291, 39.404973],
                        [-75.601143, 39.403968],
                        [-75.602327, 39.401264],
                        [-75.602926, 39.40092],
                        [-75.607891, 39.400674],
                        [-75.6152, 39.402129],
                        [-75.615799, 39.401985],
                        [-75.617377, 39.400946],
                        [-75.618049, 39.400841],
                        [-75.618488, 39.400984],
                        [-75.620939, 39.402588],
                        [-75.629, 39.415785],
                        [-75.629504, 39.417331],
                        [-75.629672, 39.420312],
                        [-75.630152, 39.421999],
                        [-75.631218, 39.424267],
                        [-75.631669, 39.427048],
                        [-75.633923, 39.431529],
                        [-75.638229, 39.436207],
                        [-75.639985, 39.437539],
                        [-75.643971, 39.439841],
                        [-75.643745, 39.440475],
                        [-75.643816, 39.440946],
                        [-75.647999, 39.448335],
                        [-75.650273, 39.450153],
                        [-75.653584, 39.451932],
                        [-75.653479, 39.452028]
                      ]
                    ]
                  ]
                }
              }
            ]
          },
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  featureType: "location",
                  path: "locations/census-tract-105-02",
                  name: "Census Tract 105.02",
                  type: "CensusTract",
                  id: "census-tract-105-02"
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: [
                    [
                      [
                        [-75.50939, 39.76644900000001],
                        [-75.509347, 39.76658],
                        [-75.497519, 39.772037999999995],
                        [-75.478117, 39.784859],
                        [-75.47420600000001, 39.788405999999995],
                        [-75.473394, 39.788031],
                        [-75.47287, 39.78742],
                        [-75.471501, 39.787045],
                        [-75.47137099999999, 39.786805],
                        [-75.471958, 39.786566],
                        [-75.47195400000001, 39.786271],
                        [-75.471423, 39.785669999999996],
                        [-75.47016599999999, 39.784949999999995],
                        [-75.47095007961096, 39.783848041988556],
                        [-75.47115307052377, 39.78317805661888],
                        [-75.47158102430902, 39.78289508799834],
                        [-75.47209203269841, 39.78280208392616],
                        [-75.47352306294987, 39.781635064329365],
                        [-75.47355807434657, 39.78140606156525],
                        [-75.47459507035126, 39.780108063367344],
                        [-75.4753990467383, 39.7794930769773],
                        [-75.47644208042215, 39.77805205908668],
                        [-75.47670808839283, 39.77792501710272],
                        [-75.476861106426, 39.7775360366644],
                        [-75.47714206753912, 39.77739705950544],
                        [-75.47820908034689, 39.7761290405686],
                        [-75.47894206539138, 39.774953062283316],
                        [-75.48001006419895, 39.77391806319607],
                        [-75.47965202133851, 39.773690912564746],
                        [-75.47923002795525, 39.77368686918756],
                        [-75.47910106682485, 39.77328693970897],
                        [-75.47767813556356, 39.77226898143777],
                        [-75.47783202282739, 39.772096119980574],
                        [-75.47817397509644, 39.77223209420109],
                        [-75.47911402664788, 39.77304108601948],
                        [-75.47912005434078, 39.77282907760136],
                        [-75.48026702959852, 39.77258408499483],
                        [-75.48055602296462, 39.77244008712697],
                        [-75.4805030531012, 39.772280080359],
                        [-75.4809410313898, 39.77237008436468],
                        [-75.48140597716575, 39.77213908715008],
                        [-75.48149208070998, 39.771618051195254],
                        [-75.4821780642489, 39.77114606303476],
                        [-75.48252509212313, 39.76993702527732],
                        [-75.4827451195219, 39.76951902901402],
                        [-75.48300408746485, 39.76951197841249],
                        [-75.4830310275683, 39.76908291420659],
                        [-75.48210910330721, 39.76875501015181],
                        [-75.48264207362666, 39.767539051782855],
                        [-75.4829580518308, 39.766091121103514],
                        [-75.48350009, 39.76626599715921],
                        [-75.48298709, 39.76604992144315],
                        [-75.483124088844, 39.76546102476361],
                        [-75.48513806870665, 39.76223105816627],
                        [-75.48513707972293, 39.7620080423531],
                        [-75.48568008682078, 39.76125602373223],
                        [-75.48572209049246, 39.7608610451326],
                        [-75.48637007174484, 39.760212054338574],
                        [-75.48768308200188, 39.75832403710892],
                        [-75.48814907126187, 39.75716605687406],
                        [-75.48929005581927, 39.75599407139016],
                        [-75.49041905314539, 39.7552340734516],
                        [-75.49090697458844, 39.75503210870182],
                        [-75.49112296481573, 39.755186082842975],
                        [-75.49155601184826, 39.75516708923168],
                        [-75.49196405047462, 39.75487107451788],
                        [-75.49230607695768, 39.75449504666577],
                        [-75.49237908239871, 39.754071040311075],
                        [-75.493658, 39.752587],
                        [-75.494305, 39.75275],
                        [-75.495188, 39.752683999999995],
                        [-75.495618, 39.753036],
                        [-75.497599, 39.753412999999995],
                        [-75.49763399999999, 39.75381],
                        [-75.49748799999999, 39.753972999999995],
                        [-75.495492, 39.755244],
                        [-75.495364, 39.755493],
                        [-75.495513, 39.755569],
                        [-75.496934, 39.755114999999996],
                        [-75.498221, 39.755114],
                        [-75.49820600000001, 39.755246],
                        [-75.495131, 39.756253],
                        [-75.49326599999999, 39.757099],
                        [-75.50939, 39.76644900000001]
                      ]
                    ]
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  }
};
