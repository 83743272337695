import { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { MhcFeatureCollection } from "graphqlApi/types";

import { GeoMap } from "./GeoMap";
import { featuredPolygonFeatureStyle } from "./mapStyles";

export interface ThumbnailMapTitle {
  text: string;
  active: boolean;
}

interface Props {
  title?: ThumbnailMapTitle;
  geoJSON: MhcFeatureCollection;
  size?: number;
}

const ThumbnailGeoMap: React.FC<Props> = ({ title, geoJSON, size = 130 }) => {
  const titleFontWeight = title?.active ? 700 : "inherit";
  const featureId = useMemo(() => {
    return geoJSON.features.length > 1
      ? geoJSON.features[1]?.properties.id
      : geoJSON.features[0]?.properties.id;
  }, [geoJSON]);
  return (
    <Box sx={{ width: size }}>
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "borders.light",
          height: size,
          borderRadius: "4px",
          overflow: "hidden"
        }}
      >
        <GeoMap
          height={"125px"}
          minHeight={"125px"}
          geoJSON={geoJSON}
          zoomControl={false}
          dragging={false}
          zoomToFeature
          loadingIndicator={{ height: "50%", sx: { pt: 0 } }}
          featureStyle={() => featuredPolygonFeatureStyle}
          selectedFeatureId={featureId}
        />
      </Box>
      {title && (
        <Typography mt={1} variant="body2" fontWeight={titleFontWeight} textAlign="center">
          {title.text}
        </Typography>
      )}
    </Box>
  );
};

export default ThumbnailGeoMap;
