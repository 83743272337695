"use client";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// ScrollSpy component is in development
// https://github.com/mui-org/material-ui/issues/16359#issuecomment-617365879

import { useEffect, useMemo } from "react";
import throttle from "lodash/throttle";

type NoOp = () => void;

const noop: NoOp = () => {
  return;
};

interface Args {
  callback: () => void;
  delay: number;
  containerElement?: HTMLElement | Window;
}

const useThrottledScroll = ({ callback, delay, containerElement }: Args) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    const _containerElement = containerElement ?? document;
    _containerElement?.addEventListener("scroll", throttledCallback);
    return () => {
      _containerElement?.removeEventListener("scroll", throttledCallback);
      throttledCallback && typeof throttledCallback === "function"
        ? throttledCallback()
        : (throttledCallback as ReturnType<typeof throttle>).cancel();
    };
  }, [throttledCallback, containerElement]);
};

export default useThrottledScroll;
