export const useGetItemIndent = (level: number, category: string) => {
  if (level === 1) {
    return "16px";
  }
  if (level === 2) {
    return "40px";
  }
  if (level === 3) {
    return "49px";
  }
  if (category !== "all_topics") {
    return "20px";
  }
  return "0px";
};
