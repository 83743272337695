"use client";

import { useMemo } from "react";
import { usePathname } from "next/navigation";
import isEmpty from "lodash/isEmpty";

import { getIsActive, getIsChildSelected, getIsDirectChildSelected, getIsSelected } from "../utils";

import { NavItem } from "../SideNavMenu";

export type Options = {
  item: NavItem;
  selected?: string | undefined | null;
  parentSelected: boolean;
};

export const useItemSelectionStatus = ({ item, selected, parentSelected }: Options) => {
  const pathname = usePathname() as string;
  const hasItems = useMemo(() => !isEmpty(item.items), [item]);

  // Item is directly selected
  const isSelected = useMemo(
    () => getIsSelected(item, parentSelected, selected, pathname),
    [item, parentSelected, selected, pathname]
  );

  // Item is selected or child item is selected
  const isActive = useMemo(
    () => getIsActive(item, parentSelected, selected, pathname),
    [item, parentSelected, selected, pathname]
  );

  // Direct child is selected
  const isDirectChildSelected = useMemo(
    () => getIsDirectChildSelected(item, parentSelected, selected, pathname),
    [item, parentSelected, selected, pathname]
  );

  // Any of the child items is selected, includes nested items
  const anyChildSelected = useMemo(() => {
    return getIsChildSelected(item, parentSelected, selected, pathname);
  }, [item, parentSelected, pathname, selected]);

  return useMemo(
    () => ({
      hasItems,
      isSelected,
      isActive,
      isDirectChildSelected,
      anyChildSelected
    }),
    [hasItems, isSelected, isActive, isDirectChildSelected, anyChildSelected]
  );
};
