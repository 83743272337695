import { Typography } from "@mui/material";

import { NAV_MARGIN } from "layout/configuration";

interface Props {
  title: string;
}

export const SideNavHeading: React.FC<Props> = ({ title }) => (
  <Typography
    variant="caption"
    component="div"
    sx={{
      color: "light.secondary",
      fontWeight: 400,
      lineHeight: "18px",
      mb: 0,
      px: NAV_MARGIN
    }}
  >
    {title}
  </Typography>
);
