import React from "react";
import { Alert, Typography } from "@mui/material";

import { MhcGeographyEnum } from "graphqlApi/types";

import { getReadableGeographyName } from "common/util/geographyHelpers";

type LocationSwitcherAlertProps = {
  topicName?: string;
  selectedGeography?: MhcGeographyEnum;
  noDataAvailability: unknown;
  dataAvailableForGranularity: unknown;
  onlyLocationsWithData: unknown;
};

const LocationSwitcherAlert: React.FC<LocationSwitcherAlertProps> = ({
  topicName,
  selectedGeography,
  noDataAvailability,
  dataAvailableForGranularity,
  onlyLocationsWithData
}) => {
  if ((noDataAvailability && dataAvailableForGranularity !== false) || !onlyLocationsWithData)
    return null;
  return (
    <>
      {onlyLocationsWithData && (
        <Alert severity="info">
          <Typography variant="body2" fontWeight={400}>
            Only showing locations for which data is typically available
          </Typography>
        </Alert>
      )}
      {!noDataAvailability && dataAvailableForGranularity === false && (
        <Alert severity="warning">
          <Typography variant="body2" fontWeight={400}>
            {topicName} <b>data is not available</b> for{" "}
            {selectedGeography && getReadableGeographyName(selectedGeography)}
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default LocationSwitcherAlert;
