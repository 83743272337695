import { MhcCategoryFragment, MhcPageFragment } from "graphqlApi/types";

import { allTopics } from "./MhcTopic";

export const allTopicsCategory: MhcCategoryFragment & { children: MhcPageFragment[] } = {
  __typename: "MhcCategory",
  id: "all_topics",
  label: "All Topics",
  icon: "Dashboard",
  href: "/locations/state/topics",
  children: allTopics
};
