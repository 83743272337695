"use client";

import { create } from "zustand";

import { MhcLocationFragment } from "graphqlApi/types";

type State = {
  currentLocation: MhcLocationFragment | null;
};

type Actions = {
  setLocation: (location: MhcLocationFragment | null) => void;
};

export const useLocationStore = create<State & Actions>((set) => ({
  currentLocation: null,
  setLocation: (location: MhcLocationFragment | null) =>
    set((state) => ({ ...state, currentLocation: location }))
}));
