"use client";

import React from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { Box, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import { MhcAttributionFragment } from "graphqlApi/types";

import { defaultContainerMargin } from "layout/configuration";
import { envIsDev, envIsProd, getEnv } from "common/util/envHelpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { useNoteStore } from "common/state/useNotesStore";
import { LocationNotAvailableForTopic } from "common/components/Alerts/LocationNotAvailableForTopic";
import Note from "common/components/Alerts/Note";
import { DemoNoticeBanner } from "common/components/DemoNoticeBanner/DemoNoticeBanner";
import DownloadDataButton from "common/components/DownloadData/DownloadDataButton";
import DownloadDataForPage from "common/components/DownloadData/DownloadDataForPage";
import { NavDropdown } from "common/components/NavDropdown";
import { NavTabs, Props as NavTabsProps } from "common/components/NavTabs";
import PageLoadingProgress from "common/components/PageLoadingProgress/legacy/PageLoadingProgress";
import { PageHeaderFallback } from "./PageHeaderFallback";

export type PageHeaderProps = {
  attributions?: Array<MhcAttributionFragment>;
  attributionsLabel?: React.ReactNode;
  banners?: React.ReactNode;
  bgColor?: string;
  download?: boolean;
  fromAppRouter?: boolean;
  pageAttributionKey?: string;
  redirected?: boolean;
  selectedColor?: string;
  showPageLoadProgress?: boolean;
  subtitle?: React.ReactNode;
  tabs?: NavTabsProps;
  title: React.ReactNode;
};

export const pageHeaderWrapperStyle = (bgColor?: PageHeaderProps["bgColor"]) => ({
  boxShadow:
    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  px: defaultContainerMargin,
  backgroundColor: bgColor,
  maxWidth: "100vw"
});

export const PageHeader: React.FC<PageHeaderProps> = ({
  attributions,
  attributionsLabel,
  banners,
  bgColor,
  download,
  fromAppRouter = false,
  pageAttributionKey,
  selectedColor,
  showPageLoadProgress = true,
  subtitle,
  tabs,
  title
}) => {
  const isMobile = useIsMobile();

  if (!title) return <PageHeaderFallback />;

  return (
    <Box component="header" id="page-header">
      <Box sx={pageHeaderWrapperStyle(bgColor)}>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            gap: { xs: 1, md: 2 }
          }}
        >
          {banners}
        </Box>
        <Box
          sx={{
            pt: 2,
            pb: 4,
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            flexWrap: "wrap",
            rowGap: 2
          }}
        >
          <Typography variant="h1" component="h1">
            {title}
          </Typography>
          {fromAppRouter && pageAttributionKey && (
            <DownloadDataForPage baseKey={pageAttributionKey} />
          )}
          {!fromAppRouter && download && attributions && !isEmpty(attributions) && (
            <DownloadDataButton
              attributions={attributions}
              color={selectedColor}
              label={attributionsLabel}
            />
          )}
        </Box>
        {subtitle && (
          <Box sx={{ mb: { sm: 1, md: 2 } }}>
            <Typography color="light.primary">{subtitle}</Typography>
          </Box>
        )}
        {tabs &&
          (isMobile ? (
            <Box pb={4}>
              <NavDropdown {...tabs} />
            </Box>
          ) : (
            <NavTabs {...tabs} selectedColor={selectedColor} />
          ))}
      </Box>
      <Box id="progress-container" sx={{ height: "5px", position: "relative" }}>
        {showPageLoadProgress && !fromAppRouter && (
          <PageLoadingProgress container="#progress-container" />
        )}
        {showPageLoadProgress && fromAppRouter && (
          <ProgressBar
            height="5px"
            color="#245194"
            options={{ showSpinner: false, parent: "#progress-container" }}
            shallowRouting
          />
        )}
      </Box>
    </Box>
  );
};

export const PageHeaderWithBanner: React.FC<PageHeaderProps> = ({ banners, ...props }) => {
  const demoBanner = !envIsProd() && !envIsDev() && getEnv() !== undefined && <DemoNoticeBanner />;
  const notes = useNoteStore((store) => store.notes);

  return (
    <PageHeader
      {...props}
      banners={
        demoBanner || banners ? (
          <>
            {demoBanner}
            {banners}
            {props.redirected === true && <LocationNotAvailableForTopic />}
            {notes.map((note, i) => (
              <Note {...note} key={`note-${i}`} />
            ))}
          </>
        ) : undefined
      }
    />
  );
};
