import React, { useCallback } from "react";
import { CircularProgress, Link, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcGeographyEnum, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { sendLocationSwitcherEvent } from "./util/events";
import { updateLocationOnCurrentPath } from "./util/updateLocationOnCurrentPath";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { GaEventActions } from "common/util/googleAnalytics";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";

interface LocationSwitcherTopLocationsProps {
  locations?: MhcLocationFragment[];
  selectedGeography?: MhcGeographyEnum;
  topic?: MhcTopicFragment | null;
  navigateToLocation: (location?: MhcLocationFragment) => void;
  forHomePage: boolean;
}

const LocationSwitcherTopLocations: React.FC<LocationSwitcherTopLocationsProps> = ({
  locations,
  selectedGeography,
  topic,
  navigateToLocation,
  forHomePage
}) => {
  const { pathWithParamKeys, params } = useRouterPath();
  const newPath = useCallback(
    (location: MhcLocationFragment) => {
      if (forHomePage === true && !isNil(topic)) {
        if (!isNil(topic.href)) return topic.href.replace("state", location.id);
        return `/topics/${topic.slug}/locations/${location.id}`;
      }
      return updateLocationOnCurrentPath({
        locationId: location.id ?? "",
        pathname: pathWithParamKeys,
        params
      });
    },
    [forHomePage, params, pathWithParamKeys, topic]
  );
  return (
    <Stack sx={{ height: "100%", width: "100%" }} flex={1} flexGrow={1}>
      <Typography fontWeight={600} sx={{ mb: "10px" }}>
        {selectedGeography === "state"
          ? "D. Most Searched Locations"
          : `D. Most Populated ${
              selectedGeography ? getReadableGeographyName(selectedGeography, true) : ""
            }`}
      </Typography>
      <Stack gap="10px" sx={{ justifyContent: "start", height: "80%" }} direction="column">
        {locations?.map((location, index) => {
          return (
            <Link
              key={index}
              href={newPath(location)}
              sx={{ width: "100%", textDecoration: "underline" }}
              onClick={(event) => {
                event.preventDefault();
                navigateToLocation(location);
                sendLocationSwitcherEvent({
                  parameters: {
                    action: GaEventActions.LocationClick,
                    label: location.name,
                    ui_location: topic?.name
                  }
                });
              }}
            >
              <Typography fontWeight={400} component="span">
                {location.name}
              </Typography>
            </Link>
          );
        })}
        {(!locations || locations?.length === 0) && (
          <CircularProgress sx={{ my: "auto", mx: "auto" }} />
        )}
      </Stack>
    </Stack>
  );
};

export default LocationSwitcherTopLocations;
