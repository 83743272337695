"use client";

import { create } from "zustand";
import { persist } from "zustand/middleware";

type State = {
  locationNotSupported?: boolean | null;
  locationName?: string | null;
  destination?: string;
  pathname?: string | null;
};

type Actions = {
  setState: (state: State) => void;
};

export const useLocationNotSupportedStore = create(
  persist<State & Actions>(
    (set, get) => ({
      locationNotSupported: null,
      locationName: null,
      setState: (state: State) => set({ ...get(), ...state })
    }),
    {
      name: "use_location_not_supported_store"
    }
  )
);
