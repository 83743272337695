// TODO: Refactor to not use `styled`
"use client";

import { forwardRef } from "react";
import { Link, LinkProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const GoToCommunityButtonBase = styled(Link)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 12px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "4px",
  background: theme.palette.component.links,
  textDecoration: "none",
  marginTop: "10px",
  marginBottom: "10px",
  color: theme.palette.brand.contrastText,
  "&:hover": {
    color: theme.palette.brand.contrastText,
    background: theme.palette.brand.main
  }
}));

const FGoToCommunityButton = forwardRef<HTMLLinkElement, LinkProps>(
  ({ children, ...props }, ref) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <GoToCommunityButtonBase aria-label="close" ref={ref as any} {...props}>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {children}
        </Typography>
      </GoToCommunityButtonBase>
    );
  }
);

FGoToCommunityButton.displayName = "GoToCommunityButton";

export const GoToCommunityButton = FGoToCommunityButton;
