import { MhcTopicCategories } from "layout/SideNav";
import { portals } from "./portals";
import { dataDownload } from "./dataDownload";
import { reference } from "./reference";
import { allTopicsCategory } from "__test__/fixtures/MhcCategory";

export const FixtureSideNavItems: MhcTopicCategories = {
  all_topics: allTopicsCategory,
  portals: portals,
  data_download: dataDownload,
  reference: reference
};
