import { MhcCategoryFragment, MhcPageFragment } from "graphqlApi/types";

export const reference: MhcCategoryFragment & { children: MhcPageFragment[] } = {
  __typename: "MhcCategory",
  id: "reference",
  label: "Reference",
  icon: null,
  href: null,
  children: [
    {
      __typename: "MhcTopic",
      parent: null,
      children: [],
      id: "43013",
      category: "reference",
      href: "/about",
      name: "About MHC",
      pageId: "aboutMhc",
      slug: "about",
      topicType: "topic",
      description: null
    },
    {
      __typename: "MhcTopic",
      parent: null,
      children: [],
      id: "43014",
      category: "reference",
      href: "/news",
      name: "What's Coming",
      pageId: "whatsComing",
      slug: "news",
      topicType: "topic",
      description: null
    },
    {
      __typename: "MhcTopic",
      parent: null,
      children: [],
      id: "43015",
      category: "reference",
      href: "/feedback",
      name: "Feedback",
      pageId: "feedback",
      slug: "feedback",
      topicType: "topic",
      description: null
    }
  ]
};
