"use client";
import { ReactNode, useCallback, useState } from "react";
import { Card, Stack, Typography, TypographyProps } from "@mui/material";
import isNil from "lodash/isNil";

import { defaultCardPadding } from "layout/configuration";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { Dropdown } from "common/components/NavDropdown/Dropdown";
import { TabbedMenuButton } from "common/components/TabbedMenu/TabbedMenuButton";

export interface TabMenuWithContent {
  selected?: boolean;
  title: string;
  content: ReactNode;
  description?: string;
  selectTab?: (tab: TabMenuWithContent) => void;
}

interface Props {
  buttons: TabMenuWithContent[];
  title?: string;
  titleVariant?: TypographyProps["variant"];
  hideSelectedTitle?: boolean;
  cardPadding?: {
    xs: string | number;
    md?: string | number;
  };
}

export const TabbedMenu = ({
  buttons,
  title,
  titleVariant = "h3",
  hideSelectedTitle = false,
  cardPadding = defaultCardPadding
}: Props) => {
  const isMobile = useIsMobile();
  const [selectedTab, setSelectedTab] = useState<TabMenuWithContent | undefined>(buttons[0]);

  const handleTabSelect = useCallback((tab: TabMenuWithContent | undefined) => {
    if (isNil(tab)) return;
    sendGaUserInteractionEvent({
      category: "Tab",
      action: "Homepage topic tab click",
      label: tab?.title,
      ui_location: "Homepage"
    });
    setSelectedTab(tab);
  }, []);

  return (
    <Stack direction="column">
      <Stack direction="row" gap={2}>
        {!isMobile &&
          buttons.map((props, i) => (
            <TabbedMenuButton
              key={i}
              {...props}
              selectTab={handleTabSelect}
              selected={props.title === selectedTab?.title}
            />
          ))}
      </Stack>
      <Card elevation={0} sx={{ borderRadius: 0, p: cardPadding }} variant="outlined">
        {isMobile === true && title && (
          <Typography variant="h2" sx={{ mb: 2 }}>
            {title}
          </Typography>
        )}
        {isMobile && (
          <Stack direction="column" gap={2} sx={{ mb: 4 }}>
            {!title && <Typography variant="h2">My Healthy Community Topics</Typography>}
            <Stack direction="column" gap={1}>
              <Typography variant="body2">Select a category</Typography>
              <Dropdown
                selectedId={selectedTab?.title}
                items={buttons.map(({ title }) => ({ id: title, name: title }))}
                handleItemClick={(item) => {
                  const tab = buttons.find(({ title }) => item.id === title);
                  handleTabSelect(tab);
                }}
              />
            </Stack>
          </Stack>
        )}
        <Stack direction="column" gap={2}>
          {hideSelectedTitle === false && (
            <Typography variant={titleVariant}>{selectedTab?.title}</Typography>
          )}
          {selectedTab?.description && (
            <Typography variant="body1">{selectedTab?.description}</Typography>
          )}
          {selectedTab?.content}
        </Stack>
      </Card>
    </Stack>
  );
};
