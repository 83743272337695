import {
  GaClickEventParameters,
  GaEventCategories,
  GaEventTypes,
  sendGaEvent
} from "common/util/googleAnalytics";

type SendLocationSwitcherEvent = {
  // This is definitely exported from `common/util/googleAnalytics` and imported above :/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  eventType?: GaEventTypes.Navigation | GaEventTypes.UserInteraction;
  parameters: Omit<GaClickEventParameters, "category">;
};

export const sendLocationSwitcherEvent = ({
  eventType = GaEventTypes.Navigation,
  parameters
}: SendLocationSwitcherEvent) => {
  sendGaEvent({
    eventType,
    parameters: { category: GaEventCategories.LocationSwitcher, ...parameters }
  });
};
