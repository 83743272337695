// TODO: Refactor to not use `styled`
"use client";

import { forwardRef } from "react";
import { Link, LinkProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ViewCommunityProfileReportButtonBase = styled(Link)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 12px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "4px",
  background: theme.palette.brand.light,
  textDecoration: "none",
  marginTop: "10px",
  marginBottom: "10px",
  color: theme.palette.brand.main,
  "&:hover": {
    background: "#E0E5EE",
    color: "#16407E"
  }
}));

const FViewCommunityProfileReportButton = forwardRef<HTMLLinkElement, LinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <ViewCommunityProfileReportButtonBase
        aria-label="View Community Profile Report(s)"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        {...props}
      >
        <Typography variant="body2" sx={{ textAlign: "center", fontWeight: 600 }}>
          {children}
        </Typography>
      </ViewCommunityProfileReportButtonBase>
    );
  }
);

FViewCommunityProfileReportButton.displayName = "ViewCommunityProfileReportButton";

export const ViewCommunityProfileReportButton = FViewCommunityProfileReportButton;
