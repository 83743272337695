import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { MhcPageFragment, MhcTopicCategoryEnum } from "graphqlApi/types";

import { MhcIconTypeMap } from "common/util/mui";

import { SideNavMenuProps } from "./SideNavMenu";

interface TransformTopicsToNavItems {
  topics: MhcPageFragment[] | NonNullable<MhcPageFragment["children"]>;
  level: number;
  category: MhcTopicCategoryEnum;
}

export const transformTopicsToNavItems = ({
  topics,
  level,
  category
}: TransformTopicsToNavItems): SideNavMenuProps["items"] => {
  return topics.map((topic) => {
    const icon = topic.pageId || topic.slug;
    const IconComponent = !isEmpty(icon) && !isNil(icon) ? MhcIconTypeMap[icon] : null;
    return {
      id: topic.slug || topic.id || topic.pageId || "",
      title: topic.name,
      type: topic.topicType,
      href: topic.href,
      icon: !isNil(IconComponent) ? <IconComponent /> : null,
      items:
        "children" in topic && topic.children
          ? transformTopicsToNavItems({
              topics: topic.children,
              level: category === "all_topics" ? level + 1 : 4,
              category: category
            })
          : undefined,
      level: level,
      category
    };
  });
};
