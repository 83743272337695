import HomeIcon from "@mui/icons-material/Home";

import { MhcGeographyEnum, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { STATE_LOCATION_NAME } from "common/constants";

export * from "./censusTracts";
export * from "./navItems";

export const topic: MhcTopicFragment = {
  __typename: "MhcTopic",
  id: "ecdc_overview",
  name: "Community Overview",
  href: "#",
  pageId: "",
  slug: "overview",
  category: "portals",
  topicType: "topic",
  resources: []
};

export const location: MhcLocationFragment = {
  __typename: "MhcLocation",
  name: "Delaware",
  id: "state",
  geography: "state",
  population: 234234
};

export const relatedLocations: MhcLocationFragment[] = [
  {
    __typename: "MhcLocation",
    name: STATE_LOCATION_NAME,
    id: "state",
    geography: MhcGeographyEnum.State
  },
  {
    __typename: "MhcLocation",
    name: "New Castle County",
    id: "county-new-castle",
    geography: MhcGeographyEnum.County
  },
  {
    __typename: "MhcLocation",
    name: "Zip Code 19702",
    geography: MhcGeographyEnum.ZipCode,
    id: "zip-code-19702"
  }
];

export const pageSections = {
  items: [
    {
      id: "ecdc",
      name: "Equity Counts Data Center",
      icon: HomeIcon,
      href: "#"
    },
    {
      id: "ecdcOverview",
      name: "Community Overview",
      href: "#"
    },
    {
      id: "ecdcHealth",
      name: "Health",
      href: "#"
    },
    {
      id: "ecdcEducation",
      name: "Education",
      href: "#"
    },
    {
      id: "ecdcJustice",
      name: "Criminal Justice",
      href: "#"
    },
    {
      id: "ecdcWealth",
      name: "Wealth Creation",
      href: "#"
    }
  ]
};

export const exampleAttributions = [
  {
    __typename: "MhcAttribution",
    id: "1",
    name: "Test Source",
    url: "https://example.com"
  }
];
