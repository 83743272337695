import { Box, Link, Skeleton, Stack, Typography } from "@mui/material";

import { SideNavHeading } from "layout/SideNav/SideNavHeading";
import Row from "common/components/Row";

const SideNavMapFallback = () => (
  <Box sx={{ boxShadow: 1, pb: "10px" }}>
    <SideNavHeading title="Currently Selected Location" />
    <Box mx="20px">
      <Box pb="7px" pt="3px">
        <Skeleton
          variant="text"
          sx={{ lineHeight: "24px", fontSize: "18px", minWidth: "50px", maxWidth: "30%" }}
        />
        <Row py="1px" px={0} mb={1}>
          <Typography variant="caption" fontWeight={600} component="span">
            Population:{" "}
          </Typography>
          <Skeleton
            variant="text"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              minWidth: "50px",
              maxWidth: "100px",
              mr: "auto",
              ml: "5px"
            }}
          />
          <Link
            href={`/locations/state/community-characteristics`}
            target="__blank"
            variant="caption"
            component="a"
            color="light.primary"
          >
            More Details
          </Link>
        </Row>
        <Stack gap={1}>
          <Skeleton variant="rectangular" height={100} width="100%" />{" "}
          <Skeleton variant="rectangular" sx={{ height: 50, width: "100%" }} />{" "}
        </Stack>
      </Box>
    </Box>
  </Box>
);

export default SideNavMapFallback;
