"use client";
// https://www.gosink.in/next-js-make-your-own-progress-bar-indicator-component-easily/
// https://www.npmjs.com/package/nprogress

import { Component } from "react";
import { withRouter } from "next/router";
import NProgress from "nprogress";

/**
 * @deprecated Shouldn't be implemented on the new app router
 */
class PageLoadingProgress extends Component {
  timer = null;

  routeChangeStart = () => {
    NProgress.set(0.3);
    NProgress.start();
  };

  routeChangeEnd = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      NProgress.done(true);
    }, 200);
  };

  render() {
    return;
  }

  componentDidMount() {
    const { router, container } = this.props;

    NProgress.configure({ showSpinner: false, parent: container });

    router.events.on("routeChangeStart", this.routeChangeStart);
    router.events.on("routeChangeComplete", this.routeChangeEnd);
    router.events.on("routeChangeError", this.routeChangeEnd);
  }
}

/**
 * @deprecated Shouldn't be implemented on the new app router
 */
export default withRouter(PageLoadingProgress);
