import { useMemo } from "react";
import compact from "lodash/compact";

import { MhcAttributionFragment } from "graphqlApi/types";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import { useAttributionsStore } from "common/state/useAttributionsStore";
import DownloadDataButton, {
  DownloadDataButtonFallback
} from "common/components/DownloadData/DownloadDataButton";

const DownloadDataForPage = ({ baseKey = "" }: { baseKey?: string }) => {
  const {
    params: { subTopicId = "" }
  } = useRouterPath();
  const key = useMemo(() => compact([baseKey, subTopicId]).join("-"), [baseKey, subTopicId]);

  const { getAttributionsByKey } = useAttributionsStore((s) => s);
  const attributions = getAttributionsByKey(key);

  if (!attributions?.length) {
    return <DownloadDataButtonFallback />;
  }

  return <DownloadDataButton attributions={attributions as MhcAttributionFragment[]} />;
};

export default DownloadDataForPage;
