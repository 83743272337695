import { Stack, Typography } from "@mui/material";

export const StepLabel: React.FC<{ step: string }> = ({ step }) => {
  return (
    <Stack
      direction={{ xs: "row", md: "column" }}
      flex={1}
      width="min-content"
      flexGrow={0}
      gap={{ xs: 0.5, md: 0 }}
    >
      <Typography variant="body2" color="text.light" textAlign={{ xs: "left", md: "center" }}>
        STEP
      </Typography>
      <Typography variant="body2" color="text.light" textAlign={{ xs: "left", md: "center" }}>
        {step}
      </Typography>
    </Stack>
  );
};
