import React from "react";
import { Divider, Stack } from "@mui/material";

import { MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { ECDC_NAME } from "common/constants";
import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

import { MhcTopicCategories } from "layout/SideNav";
import { LocationSwitcherBannerSelectTopic } from "common/components/LocationSwitcher/LocationSwitcherBannerSelectTopic";
import { LocationSwitcherBannerTooltip } from "common/components/LocationSwitcher/LocationSwitcherBannerTooltip";
import { LocationSwitcherBannerTopic } from "common/components/LocationSwitcher/LocationSwitcherBannerTopic";
import { StepLabel } from "common/components/LocationSwitcher/StepLabel";
import { CPR_NAME } from "@/CPR/config";

interface LocationSwitcherBannerProps {
  location?: MhcLocationFragment;
  onlyLocationsWithData: boolean;
  dataAvailableForGranularity: boolean;
  availableGeographies: NoCountry[];
  topic?: MhcTopicFragment | null;
  items?: MhcTopicCategories;
  noDataAvailability?: boolean;
  handleGeographyUpdate: (geo: NoCountry) => void;
  setTopic: (topic: MhcTopicFragment) => void;
  forHomePage?: boolean;
  showStepLabel: boolean;
}

export const LocationSwitcherBanner: React.FC<LocationSwitcherBannerProps> = ({
  location,
  onlyLocationsWithData,
  dataAvailableForGranularity,
  availableGeographies,
  topic,
  items,
  handleGeographyUpdate,
  setTopic,
  noDataAvailability = false,
  forHomePage,
  showStepLabel
}) => {
  let parentName = "";
  if (topic?.category === "portals" || topic?.topicType === "section") {
    parentName = topic?.parent?.name ?? "";
  }
  if (topic?.id.toLowerCase().includes("ecdc")) {
    parentName = ECDC_NAME;
  } else if (topic?.id.toLowerCase().includes("cpr")) {
    parentName = CPR_NAME;
  }

  return (
    <Stack direction="column" flex={1} gap={3}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        flex={1}
        justifyContent="space-between"
        flexGrow={1}
        gap={{ xs: 2, md: 4 }}
        sx={{
          backgroundColor: forHomePage ? undefined : "#F8F8F8",
          px: forHomePage ? 0 : 2,
          py: forHomePage ? 0 : 1,
          borderRadius: "4px",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" }
        }}
      >
        {showStepLabel && <StepLabel step="1" />}
        {forHomePage === false && (
          <LocationSwitcherBannerTopic parentName={parentName} location={location} topic={topic} />
        )}
        {forHomePage === true && (
          <LocationSwitcherBannerSelectTopic setTopic={setTopic} topic={topic} items={items} />
        )}
        <LocationSwitcherBannerTooltip
          dataAvailableForGranularity={dataAvailableForGranularity}
          availableGeographies={availableGeographies}
          handleGeographyUpdate={handleGeographyUpdate}
          onlyLocationsWithData={onlyLocationsWithData}
          noDataAvailability={noDataAvailability}
        />
      </Stack>
      <Divider />
    </Stack>
  );
};
