"use client";

import { create } from "zustand";

import { MhcNote } from "graphqlApi/types";

type State = {
  notes: MhcNote[];
};

type Actions = {
  setNotes: (notes: MhcNote[]) => void;
  addNote: (note: MhcNote) => void;
};

export const useNoteStore = create<State & Actions>((set) => ({
  notes: [],
  setNotes: (notes: MhcNote[]) => set({ notes }),
  addNote: (note: MhcNote) => set((state) => ({ notes: [...state.notes, note] }))
}));
