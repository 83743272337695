"use client";

import { useEffect } from "react";
import isNil from "lodash/isNil";

import { MhcLocationFragment } from "graphqlApi/types";

import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";

export const UpdateLocationSwitcherState = ({
  location
}: {
  location?: MhcLocationFragment | null;
}) => {
  const updateState = useLocationSwitcherStore((store) => store.updateState);

  useEffect(() => {
    if (isNil(location) || isNil(location?.geography)) return;
    updateState({
      locationSwitcherSelectedGeography: location.geography as NoCountry,
      selectedId: location.id
    });
  }, [location, location?.geography, updateState]);

  return <></>;
};
