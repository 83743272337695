import { LinkProps, Typography } from "@mui/material";

import { BreadcrumbLink } from "./types";

import { theme } from "theme/theme";
import { sendGaNavigationEvent } from "common/util/googleAnalytics";

import { AppNavLink } from "common/components/AppNavLink";

export interface BreadcrumbLinkProps {
  link: BreadcrumbLink;
  underline?: LinkProps["underline"];
}

export const BreadcrumbLinkComponent = ({
  link: { icon, title, href, last },
  underline = "none"
}: BreadcrumbLinkProps) => {
  const color = last ? theme.palette.brand.main : theme.palette.light.primary;
  if (!href) {
    return (
      <Typography variant="body2" fontWeight={700}>
        {title}
      </Typography>
    );
  }
  return (
    <AppNavLink
      sx={{ display: "flex", alignItems: "center" }}
      color={color}
      href={href}
      underline={underline}
      onClick={() => {
        sendGaNavigationEvent({
          category: "Headers",
          action: "Breadcrumb Click",
          label: title
        });
      }}
    >
      {icon}
      <Typography variant="body2" fontWeight={last ? 700 : 400}>
        {title}
      </Typography>
    </AppNavLink>
  );
};
