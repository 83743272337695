"use client";

import { useMemo, useState } from "react";
import { Box, Link, Typography } from "@mui/material";

import { MhcLocation, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { formatNumberWithDelimiter } from "common/util/formatHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import { GeoMap } from "common/components/GeoMap/GeoMap";
import { LocationSwitcher } from "common/components/LocationSwitcher/LocationSwitcher";

interface Props {
  location: MhcLocationFragment;
  locations?: MhcLocation[];
  allowLocationChange?: boolean;
  showPopulation?: boolean;
  height?: string;
  width?: string;
  topic?: MhcTopicFragment | null;
}

const MiniMapWithLoadedLocation: React.FC<Props> = ({
  location,
  locations = [],
  allowLocationChange = true,
  showPopulation = true,
  height,
  width,
  topic
}) => {
  const [locationSelectorOpen, setLocationChangerState] = useState<boolean>(false);
  const handleLocationChangerClick = () => {
    setLocationChangerState((prevState) => !prevState);
    sendGaUserInteractionEvent({
      category: "Location Switcher",
      action: "Toggle",
      label: "Mini Map"
    });
  };

  const maxZoomBasedOnGeo = useMemo(() => {
    switch (location.geography) {
      case "state":
        return 6;
      case "county":
        return 9;
      case "senate_district":
        return 10;
      default:
        return 13;
    }
  }, [location.geography]);

  return (
    <>
      <Box pb="7px" pt="3px">
        <Typography fontWeight={600} component="p" lineHeight="25px" fontSize="18px">
          {location.name}
        </Typography>
        {showPopulation && location.population && (
          <Box sx={{ display: "flex", flexDirection: "row" }} py="1px" px={0} m={0}>
            <Typography variant="caption" fontWeight={600} component="span">
              Population:{" "}
            </Typography>
            <Typography
              variant="caption"
              component="span"
              sx={{ marginRight: "auto", marginLeft: "5px" }}
            >
              {formatNumberWithDelimiter(location.population)}
            </Typography>
            <Link
              href={`/locations/${location.id}/community-characteristics`}
              target="__blank"
              variant="caption"
              component="a"
              color="light.primary"
            >
              More Details
            </Link>
          </Box>
        )}
      </Box>
      {location.mapData && (
        <Box
          sx={{
            borderRadius: "4px",
            overflow: "hidden",
            position: "relative"
          }}
        >
          {allowLocationChange && (
            <Box
              tabIndex={0}
              type="button"
              component="button"
              onClick={handleLocationChangerClick}
              sx={{
                position: "absolute",
                display: "flex",
                alignItems: "stretch",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(33, 33, 33, 0.5)",
                zIndex: 1001,
                transition: "0.3s",
                opacity: 0,
                p: 0,
                m: 0,
                cursor: "pointer",
                "&:hover,&:focus": {
                  opacity: 1,
                  outlineColor: "brand.main",
                  outlineStyle: "solid",
                  outlineWidth: 2,
                  outlineOffset: -2
                }
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "white",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(33, 33, 33, 0.25)",
                    borderColor: "white"
                  }
                }}
              >
                <Typography variant="body1" fontWeight={600}>
                  Click to change location
                </Typography>
              </Box>
            </Box>
          )}
          <GeoMap
            dragging={false}
            htmlId="side-nav-map"
            featureStyle={() => ({
              weight: 1,
              opacity: 0.75,
              color: "#C24644",
              dashArray: "",
              fillColor: "#147BB1",
              fillOpacity: 0.5
            })}
            geoJSON={location.mapData}
            height={height ?? "125px"}
            loadingIndicator={{ height: 75, sx: { pt: 0 } }}
            maxZoom={maxZoomBasedOnGeo}
            minHeight={height ?? "125px"}
            selectedFeatureId={location.id}
            style={{
              pointerEvents: "none"
            }}
            width={width}
            zoomControl={false}
            zoomToFeature
            onMapCreated={() => {
              if (typeof document === "undefined") return;
              // update tabindex to allow location switcher button to take precedence
              document.getElementById("side-nav-map")?.setAttribute("tabindex", "-1");
            }}
          />
        </Box>
      )}
      {locations?.length > 0 && allowLocationChange && (
        <LocationSwitcher
          location={location}
          topic={topic}
          locations={locations as MhcLocationFragment[]}
          open={locationSelectorOpen}
          _onClose={() => setLocationChangerState(false)}
        />
      )}
    </>
  );
};

export default MiniMapWithLoadedLocation;
