"use client";

import { Box, CircularProgress, Skeleton, Stack, Typography } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION } from "../configuration";
import { theme } from "theme/theme";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import SideNavMapFallback from "layout/SideNav/SideNavMapFallback";
import Row from "common/components/Row";

export const SideNavFallback: React.FC<{ displayInMobile?: boolean }> = ({
  displayInMobile = true
}) => {
  const isMobile = useIsMobile();
  const displaySx = displayInMobile
    ? {}
    : {
        [`@media screen and (max-width: ${theme.breakpoints.values.md}px)`]: {
          display: "none"
        }
      };
  return (
    <Stack gap={2} className="web-only-no-pdf" sx={{ overflow: "hidden", ...displaySx }}>
      <Row alignItems="center" p={2} justifyContent="space-between">
        <Typography width="20%">
          <Skeleton width="100%" />
        </Typography>
        <Row gap={1} alignItems="center">
          <Typography width={40} variant="body2">
            <Skeleton width="100%" />
          </Typography>
          <Skeleton width={20} height={20} variant="rectangular" />
        </Row>
      </Row>
      {!isMobile && <SideNavMapFallback />}
      {isMobile && (
        <>
          <Box
            sx={{
              backgroundColor: "component.background",
              px: 4,
              py: 2,
              position: "relative",
              zIndex: 1
            }}
          >
            <Skeleton
              animation={DEFAULT_SKELETON_ANIMATION}
              sx={{
                height: 5,
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column"
              }}
            />
          </Box>
        </>
      )}
      <Stack sx={{ flex: 1, alignItems: "center", justifyContent: "center", pt: 4 }}>
        <CircularProgress />
      </Stack>
    </Stack>
  );
};
