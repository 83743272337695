/* eslint-disable max-lines */

import { MhcTopicFragment } from "graphqlApi/types";

export const topicWithDetailedParents = {
  __typename: "MhcTopic",
  id: "43002",
  category: "all_topics",
  children: [
    {
      __typename: "MhcTopic",
      id: "43003",
      pageId: null,
      name: "Vaccines",
      slug: "vaccine-tracker",
      topicType: "section",
      href: "/locations/state/covid-19/vaccine-tracker"
    },
    {
      __typename: "MhcTopic",
      id: "43004",
      pageId: null,
      name: "Cases",
      slug: "cases",
      topicType: "section",
      href: "/locations/state/covid-19/cases"
    },
    {
      __typename: "MhcTopic",
      id: "43005",
      pageId: null,
      name: "Deaths",
      slug: "deaths",
      topicType: "section",
      href: "/locations/state/covid-19/deaths"
    }
  ],
  href: "/locations/state/infectious-disease/covid-19",
  name: "COVID-19",
  pageId: null,
  slug: "covid-19",
  topicType: "topic",
  parent: {
    __typename: "MhcTopic",
    id: "43001",
    href: "/locations/state/infectious-disease",
    name: "Infectious Disease",
    pageId: null,
    slug: "infectious-disease",
    topicType: "topic"
  }
};

export const allTopics: MhcTopicFragment[] = [
  {
    __typename: "MhcTopic",
    parent: null,
    children: [],
    id: "42940",
    category: "all_topics",
    href: "/locations/state/community-characteristics",
    name: "Community Characteristics",
    pageId: null,
    slug: "community-characteristics",
    topicType: "topic",
    description: "Demographic information covering population totals, age, income, race, and more",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42948",
        children: [],
        href: "/locations/state/environment/air-quality",
        name: "Air Quality",
        pageId: null,
        slug: "air-quality",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42949",
        children: [],
        href: "/locations/state/environment/drinking-water-public",
        name: "Public Drinking Water",
        pageId: null,
        slug: "drinking-water-public",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42950",
        children: [],
        href: "/locations/state/environment/drinking-water-private",
        name: "Private Drinking Water (Wells)",
        pageId: null,
        slug: "drinking-water-private",
        topicType: "topic"
      }
    ],
    id: "42947",
    category: "all_topics",
    href: "/locations/state/environment",
    name: "Environment",
    pageId: null,
    slug: "environment",
    topicType: "topic",
    description: "Pollutant and contaminant reports describing air and drinking water quality",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [],
    id: "42951",
    category: "all_topics",
    href: "/locations/state/topics/svi",
    name: "Social Vulnerability Index",
    pageId: null,
    slug: "topics/svi",
    topicType: "topic",
    description:
      "Rating system assessing a community's ability to respond to and prevent disaster events based on socioeconomic and other factors from the U.S. Census",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [],
    id: "42953",
    category: "all_topics",
    href: "/topics/climate-and-health/locations/state",
    name: "Climate and Health",
    pageId: null,
    slug: "climate-and-health",
    topicType: "topic",
    description:
      "Collection of environmental and health indicators associated with the impacts of climate change",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42961",
        children: [],
        href: "/locations/state/chronic-disease/asthma/er-visits",
        name: "Asthma Emergency Department Visits",
        pageId: null,
        slug: "asthma/er-visits",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42962",
        children: [],
        href: "/locations/state/chronic-disease/asthma/hospitalizations",
        name: "Asthma Hospitalizations",
        pageId: null,
        slug: "asthma/hospitalizations",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42963",
        children: [],
        href: "/locations/state/chronic-disease/asthma/hospital-discharge",
        name: "Asthma Hospital Discharge",
        pageId: null,
        slug: "asthma/hospital-discharge",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42964",
        children: [],
        href: "/locations/state/chronic-disease/asthma/deaths",
        name: "Asthma Deaths",
        pageId: null,
        slug: "asthma/deaths",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42965",
        children: [],
        href: "/locations/state/chronic-disease/diabetes",
        name: "Diabetes Prevalence",
        pageId: null,
        slug: "diabetes",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42966",
        children: [],
        href: "/locations/state/chronic-disease/cancer",
        name: "Cancer",
        pageId: null,
        slug: "cancer",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42967",
        children: [],
        href: "/topics/hypertension/locations/state",
        name: "Hypertension",
        pageId: null,
        slug: "hypertension",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42968",
        children: [],
        href: "/topics/myocardial-infarction-hospitalizations/locations/state",
        name: "Myocardial Infarction",
        pageId: null,
        slug: "myocardial-infarction-hospitalizations",
        topicType: "topic"
      }
    ],
    id: "42960",
    category: "all_topics",
    href: "/locations/state/chronic-disease",
    name: "Chronic Disease",
    pageId: null,
    slug: "chronic-disease",
    topicType: "topic",
    description:
      "Data on persistent health conditions like heart disease, asthma, diabetes, and other leading causes of death and hospitalization",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42970",
        children: [],
        href: "/locations/state/mental-health-substance-use/mental-health",
        name: "Mental Health",
        pageId: null,
        slug: "mental-health",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42971",
        children: [],
        href: "/locations/state/mental-health-substance-use/suicide",
        name: "Suicide",
        pageId: null,
        slug: "suicide",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42972",
        children: [],
        href: "/locations/state/mental-health-substance-use/excessive-drinking",
        name: "Adult Excessive Drinking",
        pageId: null,
        slug: "excessive-drinking",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42973",
        children: [],
        href: "/locations/state/mental-health-substance-use/tobacco",
        name: "Tobacco Use",
        pageId: null,
        slug: "tobacco",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42974",
        children: [],
        href: "/topics/drug-overdose-deaths/locations/state",
        name: "Drug Overdose Deaths",
        pageId: null,
        slug: "drug-overdose-deaths",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42975",
        children: [],
        href: "/locations/state/mental-health-substance-use/pmp",
        name: "Prescription Monitoring Program",
        pageId: null,
        slug: "pmp",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42976",
        children: [],
        href: "/locations/state/mental-health-substance-use/drug-treatments",
        name: "Suspected Non-fatal Drug Overdoses",
        pageId: null,
        slug: "drug-treatments",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42977",
        children: [],
        href: "/topics/youth-substance-use/locations/state",
        name: "Youth Substance Use",
        pageId: null,
        slug: "youth-substance-use",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42978",
        children: [],
        href: "/topics/drug-youth-rx/locations/state",
        name: "Youth Use of Prescription Pain Meds without Prescription",
        pageId: null,
        slug: "drug-youth-rx",
        topicType: "topic"
      }
    ],
    id: "42969",
    category: "all_topics",
    href: "/topics/mental-health-substance-use/locations/state",
    name: "Mental Health & Substance Use",
    pageId: null,
    slug: "mental-health-substance-use",
    topicType: "topic",
    description:
      "Survey results and statistics about depression, suicide, and alcohol, tobacco, and drug use",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "49141",
        name: "Health Status",
        pageId: null,
        slug: "health-status",
        topicType: "topic",
        statIdentifiers: [
          {
            id: "GOOD_PHYSICAL_HEALTH_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "GOOD_MENTAL_HEALTH_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "POOR_GEN_HEALTH_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "GOOD_GEN_HEALTH_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          }
        ],
        href: "/locations/state/healthy-lifestyles/health-status"
      },
      {
        __typename: "MhcTopic",
        id: "49142",
        name: "Physical Activity",
        pageId: null,
        slug: "physical-activity",
        topicType: "topic",
        statIdentifiers: [
          {
            id: "PHYSICAL_ACTIVITY_YRBS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "MET_FED_PA_GUIDELINES_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          }
        ],
        href: "/locations/state/healthy-lifestyles/physical-activity"
      },
      {
        __typename: "MhcTopic",
        id: "49143",
        name: "Sleep",
        pageId: null,
        slug: "sleep",
        topicType: "topic",
        statIdentifiers: [
          {
            id: "INADEQUATE_SLEEP_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          }
        ],
        href: "/locations/state/healthy-lifestyles/sleep"
      },
      {
        __typename: "MhcTopic",
        id: "49144",
        name: "Weight",
        pageId: null,
        slug: "weight",
        topicType: "topic",
        statIdentifiers: [
          {
            id: "OBESE_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "OVERWEIGHT_YRBS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "HEALTHY_WEIGHT_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "OVERWEIGHT_BRFS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          },
          {
            id: "OBESITY_YRBS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          }
        ],
        href: "/locations/state/healthy-lifestyles/weight"
      },
      {
        __typename: "MhcTopic",
        id: "49145",
        name: "Youth Nutrition",
        pageId: null,
        slug: "youth-nutrition",
        topicType: "topic",
        statIdentifiers: [
          {
            id: "VEGETABLES_YRBS_CRUDE_RATE",
            availableGeographies: [],
            attributions: []
          }
        ],
        href: "/locations/state/healthy-lifestyles/youth-nutrition"
      }
    ],
    id: "42984",
    category: "all_topics",
    href: "/locations/state/healthy-lifestyles",
    name: "Healthy Lifestyles",
    pageId: null,
    slug: "healthy-lifestyles",
    topicType: "topic",
    description: "Demographic information covering population totals, age, income, race, and more",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42991",
        children: [],
        href: "/locations/state/community-safety/violent-and-accidental-deaths",
        name: "Violent and Accidental Deaths",
        pageId: null,
        slug: "violent-and-accidental-deaths",
        topicType: "topic"
      }
    ],
    id: "42990",
    category: "all_topics",
    href: "/locations/state/community-safety",
    name: "Community Safety",
    pageId: null,
    slug: "community-safety",
    topicType: "topic",
    description: "Breakdowns of violent and accidental death rates",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42993",
        children: [],
        href: "/locations/state/maternal-and-child-health/infant-and-fetal-deaths",
        name: "Infant and Fetal Deaths",
        pageId: null,
        slug: "infant-and-fetal-deaths",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42994",
        children: [],
        href: "/locations/state/maternal-and-child-health/teen-pregnancy",
        name: "Teen Pregnancy",
        pageId: null,
        slug: "teen-pregnancy",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42995",
        children: [],
        href: "/locations/state/maternal-and-child-health/preterm-births",
        name: "Preterm Births",
        pageId: null,
        slug: "preterm-births",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42996",
        children: [
          {
            id: "leadOverview",
            name: "Overview",
            // category: "all_topics",
            href: "#leadOverview",
            slug: "leadOverview",
            pageId: "leadOverview",
            topicType: "section"
          },
          {
            id: "test-results",
            name: "Children Lead Testing Rates and Results",
            // category: "all_topics",
            href: "#test-results",
            slug: "test-results",
            pageId: "test-results",
            topicType: "section"
          },
          {
            id: "risk-factors",
            name: "Lead Poisoning Risk Factors",
            // category: "all_topics",
            href: "#risk-factors",
            slug: "risk-factors",
            pageId: "risk-factors",
            topicType: "section"
          },
          {
            id: "housing",
            name: "Assessing Lead Testing Coverage and Age of Housing Stock",
            // category: "all_topics",
            href: "#housing",
            slug: "housing",
            pageId: "housing",
            topicType: "section"
          },
          {
            id: "testing-coverage",
            name: "Assessing Lead Testing Coverage in Vulnerable Communities",
            // category: "all_topics",
            href: "#testing-coverage",
            slug: "testing-coverage",
            pageId: "testing-coverage",
            topicType: "section"
          },
          {
            id: "additional-information",
            name: "Additional Information",
            // category: "all_topics",
            href: "#additional-information",
            slug: "additional-information",
            pageId: "additional-information",
            topicType: "section"
          }
        ],
        href: "/topics/lead/locations/state",
        name: "Lead",
        pageId: null,
        slug: "lead",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "42997",
        children: [],
        href: "/locations/state/maternal-and-child-health/nas",
        name: "Neonatal Abstinence Syndrome (NAS)",
        pageId: null,
        slug: "nas",
        topicType: "topic"
      }
    ],
    id: "42992",
    category: "all_topics",
    href: "/locations/state/maternal-and-child-health",
    name: "Maternal and Child Health",
    pageId: null,
    slug: "maternal-and-child-health",
    topicType: "topic",
    description:
      "Mortality and pre-term rates, as well as factors influencing mother and infant welfare, such as lead exposure and teen pregnancy ",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "42999",
        children: [],
        href: "/locations/state/health-services-utilization/mortality-amenable-to-healthcare",
        name: "Mortality Amenable to Healthcare",
        pageId: null,
        slug: "mortality-amenable-to-healthcare",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "43000",
        children: [],
        href: "/locations/state/health-services-utilization/colorectal-cancer-screening",
        name: "Colorectal Cancer Screening",
        pageId: null,
        slug: "colorectal-cancer-screening",
        topicType: "topic"
      }
    ],
    id: "42998",
    category: "all_topics",
    href: "/locations/state/health-services-utilization",
    name: "Health Services Utilization",
    pageId: null,
    slug: "health-services-utilization",
    topicType: "topic",
    description: "Cancer screening rates and metrics on deaths preventable by proper care",
    resources: []
  },
  {
    __typename: "MhcTopic",
    parent: null,
    children: [
      {
        __typename: "MhcTopic",
        id: "43002",
        children: [
          {
            __typename: "MhcTopic",
            id: "43003",
            pageId: null,
            name: "Vaccines",
            slug: "vaccine-tracker",
            topicType: "section",
            href: "/locations/state/covid-19/vaccine-tracker"
          },
          {
            __typename: "MhcTopic",
            id: "43004",
            pageId: null,
            name: "Cases",
            slug: "cases",
            topicType: "section",
            href: "/locations/state/covid-19/cases"
          },
          {
            __typename: "MhcTopic",
            id: "43005",
            pageId: null,
            name: "Deaths",
            slug: "deaths",
            topicType: "section",
            href: "/locations/state/covid-19/deaths"
          }
        ],
        href: "/locations/state/infectious-disease/covid-19",
        name: "COVID-19",
        pageId: null,
        slug: "covid-19",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "43006",
        children: [],
        href: "/locations/state/infectious-disease/notifiable",
        name: "Nationally Notifiable Conditions",
        pageId: null,
        slug: "notifiable",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "43007",
        children: [],
        href: "/locations/state/infectious-disease/hepatitis-c",
        name: "Hepatitis C",
        pageId: null,
        slug: "hepatitis-c",
        topicType: "topic"
      },
      {
        __typename: "MhcTopic",
        id: "43008",
        children: [
          {
            __typename: "MhcTopic",
            id: "43009",
            pageId: "fluOverview",
            name: "Overview",
            slug: "overview",
            topicType: "section",
            href: "/portals/flu/locations/state/overview"
          },
          {
            __typename: "MhcTopic",
            id: "43010",
            pageId: "fluCases",
            name: "Flu Cases",
            slug: "positive-cases",
            topicType: "section",
            href: "/portals/flu/locations/state/positive-cases"
          },
          {
            __typename: "MhcTopic",
            id: "43011",
            pageId: "fluHospitalizations",
            name: "Flu Hospitalizations",
            slug: "hospitalizations",
            topicType: "section",
            href: "/portals/flu/locations/state/hospitalizations"
          },
          {
            __typename: "MhcTopic",
            id: "43012",
            pageId: "fluVaccinations",
            name: "Flu Vaccinations",
            slug: "vaccinations",
            topicType: "section",
            href: "/portals/flu/locations/state/vaccinations"
          }
        ],
        href: "/portals/flu/locations/state",
        name: "Influenza",
        pageId: null,
        slug: "flu",
        topicType: "topic"
      }
    ],
    id: "43001",
    category: "all_topics",
    href: "/locations/state/infectious-disease",
    name: "Infectious Disease",
    pageId: null,
    slug: "infectious-disease",
    topicType: "topic",
    description: "Mortality due to Hepatitis C and other non-COVID-19 infectious conditions",
    resources: []
  }
];
