// TODO: Refactor to not use `styled`
"use client";

import { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const OutlineButtonBase = styled(Button)(({ theme }) => ({
  textTransform: "none",
  size: "small",
  px: 2,
  py: 1,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  backgroundColor: "rgba(17,99,147,.050980392156862744)",
  "&:hover": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}));

interface OutlineButtonProps extends Omit<ButtonProps, "variant" | "disableRipple"> {
  variant?: "secondary";
}

const FCOutlineButton = forwardRef<HTMLButtonElement | HTMLLinkElement, OutlineButtonProps>(
  ({ sx, variant, ...props }, ref) => {
    let customSx = { ...sx };
    const {
      palette: { primary, common, grey }
    } = useTheme();
    if (variant && variant === "secondary") {
      const borderColor = grey[500];
      const borderWidth = 2;
      customSx = {
        ...sx,
        borderWidth,
        borderColor,
        backgroundColor: common.white,
        "&:hover": {
          color: primary.main,
          borderColor,
          borderWidth,
          backgroundColor: grey[100]
        }
      };
    }

    return (
      <OutlineButtonBase
        variant="outlined"
        sx={customSx}
        disableRipple={true}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        {...props}
      >
        {props.children}
      </OutlineButtonBase>
    );
  }
);

FCOutlineButton.displayName = "OutlineButton";

export const OutlineButton = FCOutlineButton;
