"use client";

import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { Link as MuiLink, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { CloseButton } from "component/button";
import { MhcAlert } from "../Alerts/MhcAlert";

interface Props {
  title?: string;
  fromStorybook?: boolean;
}

export const DemoNoticeBanner: React.FC<Props> = ({ title, fromStorybook = false }) => {
  const [display, setDisplay] = useState<boolean>(fromStorybook);
  const pathname = usePathname() ?? "";
  useEffect(() => {
    setDisplay(!isNil(pathname));
  }, [pathname]);

  const link = `https://myhealthycommunity.dhss.delaware.gov${pathname}`;
  const defaultTitle = (
    <Typography component="span">
      This site is for demonstration and testing purposes only. For the most up-to-date version of
      the site, please visit the production url:
    </Typography>
  );

  return (
    <>
      {display === true && (
        <MhcAlert
          severity="info"
          sx={({ palette }) => ({
            maxWidth: "100%",
            borderWidth: "2px",
            borderColor: palette.info.main,
            borderStyle: "solid",
            "& .MuiAlert-message": {
              xs: { maxWidth: "75%" },
              md: { maxWidth: "100%" }
            }
          })}
          action={<CloseButton sx={{ mb: "auto", ml: "auto" }} onClick={() => setDisplay(false)} />}
        >
          <Typography
            variant="body3"
            sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}
            component="p"
          >
            {title || defaultTitle}
            <br />
            <MuiLink
              href={link}
              variant="body1"
              component="a"
              sx={{
                whiteSpace: "nowrap",
                "&:visited": {
                  color: "inherit"
                },
                "&:hover": {
                  color: "inherit"
                },
                "&:active": {
                  color: "inherit"
                }
              }}
            >
              {link}
            </MuiLink>
          </Typography>
        </MhcAlert>
      )}
    </>
  );
};
