import { Link, Stack, Typography } from "@mui/material";

import { MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { sendLocationSwitcherEvent } from "common/components/LocationSwitcher/util/events";

import Row from "common/components/Row";

interface Props {
  parentName: string;
  location?: MhcLocationFragment;
  topic?: MhcTopicFragment | null;
}

export const LocationSwitcherBannerTopic = ({ parentName, location, topic }: Props) => {
  return (
    <Stack direction="column">
      <Row gap="4px">
        <Typography variant="h5" fontWeight={600}>
          Topic:
        </Typography>
        <Row alignItems="baseline" flexWrap="wrap" gap="4px">
          <Typography component="h5" fontWeight={400}>
            {topic?.name}
          </Typography>
          {parentName && (
            <Typography variant="body3" fontWeight={400} color="light.secondary">
              {parentName}
            </Typography>
          )}
        </Row>
      </Row>
      <Link
        href={`/locations/${location?.id ?? "state"}/topics`}
        onClick={() => {
          sendLocationSwitcherEvent({
            parameters: {
              action: "Change topic click",
              label: topic?.name
            }
          });
        }}
        color="#6A7079"
        sx={{ lineHeight: "17px" }}
      >
        <Typography variant="body3" fontWeight={400} lineHeight="17px">
          Choose new topic
        </Typography>
      </Link>
    </Stack>
  );
};
