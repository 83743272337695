import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton } from "@mui/material";

interface Props {
  title: React.ReactNode;
  open: boolean;
}

export const SideNavArrowButton = ({ title, open }: Props) => {
  return (
    <Box
      sx={{
        transition: "transform 0.1s",
        transform: `rotate(${open ? "90" : "0"}deg)`
      }}
    >
      <IconButton
        size="small"
        aria-label={`${!open ? "Open" : "Close"} ${title?.toString() ?? ""} dropdown`}
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
