"use client";

import { Fragment, ReactNode } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, CardProps, Link as MuiLink, Typography } from "@mui/material";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import {
  MhcAlteredLocationFragment,
  MhcFeatureCollection,
  MhcLocationDeprecationInfo,
  MhcLocationFragment
} from "graphqlApi/types";

import { DEFAULT_LAYOUT_GAP, defaultCardPadding } from "layout/configuration";
import { locationFromGeoJson, locationPath } from "./util";

import { ContentCard } from "layout/card/ContentCard";
import { OutlineButton } from "component/button/OutlineButton";
import { LocationDeprecatedAlert } from "../Alerts/LocationDeprecatedAlert";
import LocationComparisonMaps from "./LocationComparisonMaps";

interface Props {
  currentLocation: MhcAlteredLocationFragment;
  yearOfChange?: number;
  sx?: CardProps["sx"];
  showAlert?: boolean;
}

const StrongString: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Typography fontWeight={700} component="strong">
    {children}
  </Typography>
);

const LocationComparisonCard: React.FC<Props> = ({
  currentLocation,
  yearOfChange = 2020,
  sx,
  showAlert = true
}) => {
  const { name } = currentLocation || {};
  const currentLocationName: string = name;
  let title = `Pages for ${currentLocationName} are no longer being updated`;
  const govAgencyName = "The US Census Bureau";

  const { deprecationInfo } = currentLocation;
  if (!deprecationInfo) return null;

  const { oldBoundaries, newBoundaries } = deprecationInfo as MhcLocationDeprecationInfo;

  // Create lists of locations based on new/old boundaries
  const [previousLocations = [], alteredLocations = []] = [
    oldBoundaries.boundaries ?? null,
    newBoundaries.boundaries ?? null
  ].map(
    (boundaries: MhcFeatureCollection[] | null = []): MhcLocationFragment[] =>
      boundaries?.map((geoJson) => locationFromGeoJson(geoJson, currentLocation)) ?? []
  );

  const currentLocationStatus = currentLocation.deprecationInfo?.status;
  const wasMerged = previousLocations.length > 1 && alteredLocations.length === 1;
  const isNew = currentLocationStatus === "new";
  const wasSplit = previousLocations.length === 1 && alteredLocations.length > 1;
  const wasReplaced = currentLocationStatus === "deprecated";

  const alteredLocationsWithoutCurrent = alteredLocations?.filter(
    ({ id }) => id !== currentLocation.id
  );
  const previousLocationsWithoutCurrent = previousLocations?.filter(
    ({ id }) => id !== currentLocation.id
  );

  let description: ReactNode[] = [];
  if (wasReplaced) {
    description.push(
      <Fragment key="description-part-1">
        {govAgencyName} removed <StrongString>{currentLocationName}</StrongString> after the{" "}
        {yearOfChange} census.{" "}
      </Fragment>
    );
  }

  let locationCount = 0;

  if (wasReplaced && wasSplit) {
    locationCount = alteredLocations.length;
    description.push(
      <Fragment key="description-part-2">
        It was split into{" "}
        {alteredLocations.map(({ name }, i) => (
          <Fragment key={i}>
            {name}
            {i < locationCount - 1 && locationCount > 2 && ", "}
            {i === locationCount - 2 && " and "}
          </Fragment>
        ))}
        .
      </Fragment>
    );
  }

  if (wasReplaced && wasMerged) {
    locationCount = previousLocationsWithoutCurrent.length;
    const mergedLocation = alteredLocationsWithoutCurrent && alteredLocationsWithoutCurrent[0];
    description.push(
      <Fragment key="description-part-3">
        {currentLocation.name} {}
        {previousLocationsWithoutCurrent?.map(({ name }, i) => (
          <Fragment key={i}>
            {locationCount === 1 && " and "}
            {name}
            {i < locationCount - 1 && locationCount > 2 && ", "}
            {i === locationCount - 2 && " and "}
          </Fragment>
        ))}{" "}
        were merged to create {mergedLocation?.name ?? "a new census tract"}.
      </Fragment>
    );
  }

  if (isNew) {
    title = `${currentLocationName} is new`;
    description = [
      <Fragment key="description-part-1">
        {govAgencyName} first created <StrongString>{currentLocation.name}</StrongString> after the{" "}
        {yearOfChange} census.{" "}
      </Fragment>
    ];
  }

  if (isNew && wasMerged) {
    locationCount = previousLocations.length;
    description.push(
      <Fragment key="description-part-5">
        It was created by merging{" "}
        {previousLocations?.map(({ id, name }, i) => (
          <Fragment key={`location-${i}`}>
            <MuiLink href={locationPath({ locationId: id })} key={id}>
              {name}
            </MuiLink>
            {i < locationCount - 1 && locationCount > 2 && ", "}
            {i === locationCount - 2 && " and "}
          </Fragment>
        ))}
        .
      </Fragment>
    );
  }

  const getNameFrom = (location?: MhcLocationFragment) => {
    if (isNil(location)) return null;
    if (!isEmpty(location.name)) return location.name;
    const feature = location?.mapData?.features[0] ?? null;
    if (isNil(feature)) return null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const name: string = feature.properties.name || (feature.properties as any).NAME;
    if (!isEmpty(name)) return name;
    return null;
  };

  if (isNew && wasSplit) {
    locationCount = alteredLocationsWithoutCurrent.length;
    const names = compact(
      alteredLocationsWithoutCurrent.map((feature) =>
        !isNil(getNameFrom(feature)) ? [getNameFrom(feature), feature.id] : null
      )
    );
    description.push(
      <Fragment key={previousLocations[0]?.name ?? "new-or-split-location"}>
        {!isNil(getNameFrom(previousLocations[0]))
          ? getNameFrom(previousLocations[0])
          : "The old census tract"}{" "}
        split into this census tract and{" "}
        {names.length > 0 &&
          names.map(([name, id], i) => (
            <Fragment key={id}>
              <MuiLink
                href={locationPath({
                  locationId: id
                })}
                key={id}
              >
                {name}
              </MuiLink>
              {i < locationCount - 1 && locationCount > 2 && ", "}
              {i === locationCount - 2 && " and "}
            </Fragment>
          ))}
        .
      </Fragment>
    );
  }

  let locationButtons = null;
  if (!isNew) {
    const locationsForButtons = alteredLocationsWithoutCurrent;
    locationButtons = locationsForButtons.map(({ name, id }) => (
      <OutlineButton
        variant="secondary"
        key={id}
        href={locationPath({
          locationId: id
        })}
        endIcon={<LaunchIcon fontSize="inherit" />}
      >
        {name}
      </OutlineButton>
    ));
  }

  return (
    <Box>
      {showAlert && (
        <LocationDeprecatedAlert
          locationName={currentLocationName}
          show={currentLocationStatus === "deprecated"}
        />
      )}
      <ContentCard sx={{ ...sx, p: defaultCardPadding, pt: 6 }} noHeader>
        <Typography
          component="h2"
          variant="h2"
          mb={4}
          sx={{ display: { xs: "block", lg: "none" } }}
        >
          {title}
        </Typography>
        <Box
          mt={-2}
          display="flex"
          gap={{ xs: 2, md: 4 }}
          sx={{
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {
              xs: "column-reverse",
              md: "row"
            }
          }}
        >
          <Box flex="1 0 60%" display="flex" flexDirection="column" gap={2}>
            <Typography
              component="h2"
              variant="h2"
              mb={0.5}
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              {title}
            </Typography>
            <Typography maxWidth="80ch" mb={0}>
              {description}
            </Typography>
            {locationButtons && (
              <Box gap={1} display="flex" flexWrap="wrap">
                {locationButtons}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "0 0 auto",
              alignSelf: {
                xs: "center",
                md: "flex-start"
              }
            }}
          >
            <LocationComparisonMaps
              titles={[
                {
                  text: `Before ${yearOfChange}`,
                  active: true
                },
                {
                  text: `After ${yearOfChange}`,
                  active: false
                }
              ]}
              locations={{ previous: previousLocations, altered: alteredLocations }}
            />
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};

export default LocationComparisonCard;
