import { SyntheticEvent } from "react";
import { Fade, Menu, MenuItem, MenuProps, PaperProps } from "@mui/material";
import { bindMenu, PopupState } from "material-ui-popup-state/hooks";

import { NavDropdownProps, NavItem } from "./types";

import { AppNavLink } from "common/components/AppNavLink";

interface Props extends Omit<NavDropdownProps, "id"> {
  elevation?: MenuProps["elevation"];
  popupState: PopupState;
  handleItemClick?: (item: NavItem) => void;
  sx?: PaperProps["sx"];
}

export const DropdownMenu: React.FC<Props> = ({
  items,
  selectedId,
  popupState,
  elevation,
  handleItemClick,
  sx
}) => {
  return (
    <Menu
      elevation={elevation}
      sx={{
        "& .MuiMenu-paper": {
          width: popupState.anchorEl?.getBoundingClientRect()?.width
        },
        ...sx
      }}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      {...bindMenu(popupState)}
    >
      {items.map((item) => {
        return (
          <MenuItem
            key={item.id}
            selected={item.id === selectedId}
            component={item.href ? AppNavLink : "p"}
            href={item.href ?? ""}
            onClick={(event: SyntheticEvent) => {
              if (handleItemClick) {
                event.preventDefault();
                handleItemClick(item);
              }
              popupState.close();
            }}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
