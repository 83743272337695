"use client";
import React, { useCallback, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { StyleFunction } from "leaflet";

import { MappedDropdownOption } from "../charts/Investigate/types";
import {
  MhcFeatureCollectionFragment,
  MhcGeographyEnum,
  MhcGeoJsonFeatureProperty
} from "graphqlApi/types";

import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";
import { InvestigateDropdown } from "../charts/Investigate/InvestigateDropdown";
import { GeoMap, GeoMapProps } from "../GeoMap";
import {
  basicPolygonFeatureStyle,
  locationPickerNoValueStyle,
  selectedPolygonFeatureStyle
} from "../GeoMap/mapStyles";

interface LocationSwitcherMapProps {
  availableGeographies: NoCountry[];
  closeModal?: () => void;
  currentTopicName?: string;
  handleGeographyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingMap: boolean;
  selectedGeography?: MhcGeographyEnum;
  selectedGeoJson: MhcFeatureCollectionFragment;
  selectedId?: string;
  showOnlyLocationsWithData: boolean;
}

export const LocationSwitcherMap: React.FC<LocationSwitcherMapProps> = ({
  availableGeographies,
  closeModal,
  currentTopicName,
  handleGeographyChange,
  loadingMap,
  selectedGeography,
  selectedGeoJson,
  selectedId,
  showOnlyLocationsWithData
}) => {
  const isMobile = useIsMobile();
  const { updateState: updateLocationSwitcherState } = useLocationSwitcherStore((store) => store);
  const mappedOptions: MappedDropdownOption[] = useMemo(() => {
    const geos = (
      showOnlyLocationsWithData === true ? availableGeographies : Object.values(MhcGeographyEnum)
    ).filter((geo) => geo !== "country");
    return sortGeographiesBySize(geos)
      .map((geography) => {
        return {
          title: getReadableGeographyName(geography),
          value: geography
        };
      })
      .reverse();
  }, [availableGeographies, showOnlyLocationsWithData]);

  const handleOnFeatureClick = (id?: string) => {
    updateLocationSwitcherState({
      selectedId: id,
      locationSwitcherSelectedGeography: selectedGeography as NoCountry
    });
  };

  const featureStyle: GeoMapProps["featureStyle"] = useCallback(
    (feature: Parameters<StyleFunction<MhcGeoJsonFeatureProperty>>[0]) => {
      if (feature?.properties.id === selectedId) {
        return {
          ...basicPolygonFeatureStyle,
          ...selectedPolygonFeatureStyle
        };
      }
      return locationPickerNoValueStyle;
    },
    [selectedId]
  );

  return (
    <Stack
      gap={4}
      sx={{ width: "100%", overflow: "hidden", height: { xs: "100%", md: "auto" }, p: 0 }}
      flex={1}
      flexGrow={1}
      height="100%"
    >
      <InvestigateDropdown
        value={selectedGeography ? getReadableGeographyName(selectedGeography) : "state"}
        title={"A. Select Map Type"}
        onChange={(event) => {
          sendGaUserInteractionEvent({
            category: "Maps",
            action: "Map type click",
            label: currentTopicName,
            ui_location: "Location Switcher"
          });
          handleGeographyChange(event);
        }}
        defaultValue={MhcGeographyEnum.State}
        options={Object.values(mappedOptions).map((option) => ({
          title: option.title,
          value: option.value
        }))}
        type="Map"
        valueItemIsSelected={({ value, selectedValue }) => {
          if (!value || !selectedValue) return false;
          return selectedValue === getReadableGeographyName(value as MhcGeographyEnum);
        }}
        fullWidth
        sx={{ mb: "-10px", flex: 1, flexGrow: 1, width: "100%" }}
      />
      <Box sx={{ minHeight: { xs: "40vh", md: "50vh" }, p: 0 }}>
        <GeoMap
          forceLoading={loadingMap}
          geoJSON={selectedGeoJson}
          showInfoBox
          height={isMobile ? "100%" : "500px"}
          selectedFeatureId={selectedId}
          onFeatureClick={handleOnFeatureClick}
          selectedDataIdentifier="POPULATION_TOTAL_COUNT"
          featureStyle={featureStyle}
          center={[39.143699735810586, -74.988912]}
          zoomToFeature
          animateZoom
          maxZoom={13}
          onNavigationComplete={closeModal}
          zoomFitBoundsOptions={{
            maxZoom: 13,
            paddingBottomRight: [230, 160],
            paddingTopLeft: [50, 0]
          }}
        />
      </Box>
    </Stack>
  );
};
