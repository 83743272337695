import { BoxProps, Link as MuiLink, LinkProps } from "@mui/material";

interface Props extends LinkProps {
  href: string;
  component?: BoxProps["component"];
}

export const AppNavLink: React.FC<Props> = ({ href, ...props }) => (
  <MuiLink href={href} {...props} underline="hover" />
);
