"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { AppNavLink } from "common/components/AppNavLink";

interface Props {
  name: string;
  href: string;
  selected: boolean;
  target?: string;
}

export const FooterButton: React.FC<Props> = ({ href, name, selected, target }) => {
  const pathname = usePathname() || "";

  const _href = href === pathname && target !== undefined ? "#" : href;
  return (
    <AppNavLink
      href={_href}
      component={Link}
      onClick={(event) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (target !== undefined && _href.includes("#")) {
          event?.preventDefault();
          const element = document.getElementById(target);
          element?.scrollIntoView({ behavior: "smooth" });
        }
      }}
      sx={({ palette }) => ({
        textTransform: "none",
        color: selected ? "white" : palette.brand.dark,
        borderColor: selected ? undefined : palette.brand.dark,
        backgroundColor: selected ? palette.primary.main : "transparent",
        textDecoration: "none",
        fontWeight: 600,
        px: 2,
        py: 1,
        borderRadius: "0.3rem",
        border: `1px solid ${selected ? palette.primary.main : palette.grey[400]}`,
        cursor: "pointer",
        fontSize: 13,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: selected ? undefined : palette.grey[200]
        }
      })}
    >
      {name}
    </AppNavLink>
  );
};
