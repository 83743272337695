import React from "react";
import { InputLabel, Link, Stack } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcLocation, MhcLocationFragment } from "graphqlApi/types";

import { allLocationsBasicData } from "./util/allLocationsBasicData";
import { sendLocationSwitcherEvent } from "./util/events";
import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { GaEventActions, GaEventTypes } from "common/util/googleAnalytics";

import { LocationPicker } from "../LocationPicker/LocationPicker";
import { LocationSwitcherProps } from "./LocationSwitcher";
import LocationSwitcherAlert from "./LocationSwitcherAlert";
import LocationSwitcherTopLocations from "./LocationSwitcherTopLocations";

interface LocationSwitcherSearchLocationProps {
  selectedId?: string;
  onlyLocationsWithData: boolean;
  locationsWithData: MhcLocation[];
  noDataAvailability: boolean;
  dataAvailableForGranularity: boolean;
  selectedGeography?: NoCountry;
  topLocations?: MhcLocationFragment[];
  topic?: LocationSwitcherProps["topic"];
  navigateToLocation: (nv?: MhcLocationFragment) => void;
  forHomePage: boolean;
}

export const LocationSwitcherSearchLocation: React.FC<LocationSwitcherSearchLocationProps> = ({
  selectedId,
  onlyLocationsWithData,
  locationsWithData,
  noDataAvailability,
  dataAvailableForGranularity,
  selectedGeography,
  topLocations,
  topic,
  forHomePage,
  navigateToLocation
}) => {
  return (
    <Stack gap={4} width="100%" flex={1} flexGrow={1} sx={{ p: 0 }}>
      <LocationPicker
        hiddenLabel
        selectedLocationId={selectedId}
        label="B. Search Location"
        inputPlaceholder="Search or select a location"
        locations={
          (onlyLocationsWithData === true
            ? locationsWithData
            : allLocationsBasicData) as MhcLocationFragment[]
        }
        onChange={(location) => {
          sendLocationSwitcherEvent({
            eventType: GaEventTypes.UserInteraction,
            parameters: {
              action: GaEventActions.LocationPickerClick,
              label: topic?.name
            }
          });
          navigateToLocation(location);
        }}
        sx={{ px: 0, pt: 0, mt: 0, mb: { xs: 4, md: 0 }, width: "100%" }}
        groupLocations
        sortFromLargerToSmaller
      />
      <LocationSwitcherAlert
        topicName={topic?.name}
        selectedGeography={selectedGeography}
        onlyLocationsWithData={onlyLocationsWithData}
        noDataAvailability={noDataAvailability}
        dataAvailableForGranularity={dataAvailableForGranularity}
      />
      {!isNil(topic) && (
        <Stack>
          <InputLabel>C. Select Delaware</InputLabel>
          <Link
            href={topic.href ? topic.href : `/topics/${topic.slug}`}
            sx={{ textDecoration: "underline" }}
          >
            View {topic?.name} Report for Delaware
          </Link>
        </Stack>
      )}
      {topLocations && (
        <LocationSwitcherTopLocations
          locations={topLocations}
          topic={topic}
          selectedGeography={selectedGeography}
          navigateToLocation={navigateToLocation}
          forHomePage={forHomePage}
        />
      )}
    </Stack>
  );
};
