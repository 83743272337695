"use client";

import React from "react";
import { Box, Skeleton } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION, defaultContainerMargin } from "layout/configuration";

interface FallbackPageHeaderProps {
  showSubtitle?: boolean;
  numberOfTabs?: number;
}

export const FallbackPageHeader: React.FC<FallbackPageHeaderProps> = ({
  showSubtitle,
  numberOfTabs = 4
}) => {
  return (
    <Box
      sx={{
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        px: defaultContainerMargin,
        backgroundColor: "brand.light",
        maxWidth: "100vw"
      }}
    >
      <Box sx={{ pt: 4, pb: 4 }}>
        <Skeleton
          width="40%"
          variant="rectangular"
          height={45}
          animation={DEFAULT_SKELETON_ANIMATION}
        />
      </Box>
      {showSubtitle && (
        <Box sx={{ mb: { sm: 1, md: 2 } }}>
          <Skeleton variant="text" sx={{ maxWidth: 200 }} />
        </Box>
      )}
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "stretch" }}>
        {[...Array(numberOfTabs)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            sx={{
              pointerEvents: "none",
              textDecoration: "none",
              transition: "background-color 300ms linear",
              px: 2,
              height: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px 10px 0px 0px",
              borderBottom: 0,
              lineHeight: "20px",
              width: "150px"
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
