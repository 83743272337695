import { ReactNode } from "react";
import { Box, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { visuallyHidden } from "@mui/utils";

import { AppNavLink } from "common/components/AppNavLink";

export interface NavItem {
  id: string;
  name: string;
  icon?: typeof SvgIcon;
  href?: string;
}

interface LinkTabProps {
  item: NavItem;
  selected?: boolean;
  selectedColor?: string;
  children?: ReactNode;
}

export const LinkTab: React.FC<LinkTabProps> = ({ item, selected = false, selectedColor }) => {
  const { palette, typography } = useTheme();
  const Icon = item.icon;

  return (
    <AppNavLink
      href={item.href ?? ""}
      sx={{
        pointerEvents: selected ? "none" : undefined,
        textDecoration: "none",
        color: selected ? palette.primary.contrastText : palette.text.primary,
        border: `${selected ? "2px" : "1px"} solid ${palette.divider}`,
        background: selected ? selectedColor || palette.primary.main : palette.common.white,
        transition: "background-color 300ms linear",
        px: 2,
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px 10px 0px 0px",
        borderBottom: 0,
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.body2.fontSize,
        "&:hover": {
          backgroundColor: palette.grey[200]
        },
        lineHeight: "20px"
      }}
    >
      {Icon ? (
        <>
          <Icon aria-label={item.name} />
          <Box sx={visuallyHidden}>{item.name}</Box>
        </>
      ) : (
        item.name
      )}
    </AppNavLink>
  );
};

export interface Props {
  items: NavItem[];
  selectedId?: string | null | undefined;
  selectedColor?: string;
}
export const NavTabs: React.FC<Props> = ({ items, selectedId, selectedColor }) => {
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "stretch" }}>
      {items.map((item) => {
        return (
          <LinkTab
            item={item}
            key={item.id}
            selected={item.id === selectedId}
            selectedColor={selectedColor}
          >
            {item.name}
          </LinkTab>
        );
      })}
    </Box>
  );
};
