import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { TabMenuWithContent } from "common/components/TabbedMenu/TabbedMenu";

export const TabbedMenuButton = (tab: TabMenuWithContent) => {
  const { selected = false, title } = tab;
  const { palette, typography } = useTheme();
  const selectedColor = palette.brand.main;
  return (
    <Box
      className="no-underline"
      sx={{
        maxWidth: "max-content",
        pointerEvents: selected ? "none" : undefined,
        cursor: selected ? undefined : "pointer",
        color: selected ? palette.primary.contrastText : palette.text.primary,
        border: `${selected ? "2px" : "1px"} solid ${palette.divider}`,
        background: selected ? selectedColor || palette.primary.main : palette.common.white,
        transition: "background-color 300ms linear",
        px: 2,
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px 10px 0px 0px",
        borderBottom: 0,
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.body2.fontSize,
        "&:hover": {
          backgroundColor: palette.grey[200]
        },
        lineHeight: "20px"
      }}
      onClick={() => tab?.selectTab?.(tab)}
    >
      {title}
    </Box>
  );
};
