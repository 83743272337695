import { AlertTitle } from "@mui/material";

import { MhcAlert } from "./MhcAlert";

interface Props {
  locationName: string;
  show?: boolean;
}

export const LocationDeprecatedAlert: React.FC<Props> = ({ locationName, show = true }) => {
  if (!show) return null;
  return (
    <MhcAlert severity="error" sx={{ mb: 4 }}>
      <AlertTitle>{locationName} is not available as a community profile report.</AlertTitle>
      Please select a different location using the dropdown or map below.
    </MhcAlert>
  );
};
