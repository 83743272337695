"use client";

import { create } from "zustand";

type State = {
  active: string | null;
};

type Actions = {
  setScrollSpyActive: (active: string | null) => void;
};

export const useScrollSpyStore = create<State & Actions>((set) => ({
  active: null,
  setScrollSpyActive: (active: string | null) => set((state) => ({ ...state, active }))
}));
