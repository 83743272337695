"use client";

import { Box, Paper, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcLocation, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { theme } from "theme/theme";

import { TopicBreadcrumbs } from "layout/Breadcrumb/TopicBreadCrumbs";
import ContextHeader from "layout/ContextHeader/ContextHeader";
import { ContextHeaderContentProps } from "layout/ContextHeader/ContextHeaderContent";
import { LocationChanger } from "layout/LocationMenu/LocationChanger";
import { pageHeaderWrapperStyle } from "layout/PageHeader/PageHeader";
import { useLayoutStore } from "common/state/useLayoutStore";

interface SectionType {
  id: string;
  name: string;
}

type TopicHeadersProps = {
  topic?: MhcTopicFragment | null;
  title?: string;
  pageSections?: ContextHeaderContentProps["pageSections"];
  location?: MhcLocationFragment | null;
  subLocation?: MhcLocationFragment;
  section?: SectionType;
  locations?: MhcLocation[];
  allowLocationChange?: boolean;
  isDashboard?: boolean;
  pageId?: string;
};

export default function TopicHeadersContent({
  title,
  topic,
  location,
  section,
  subLocation,
  pageSections,
  locations,
  allowLocationChange = false,
  isDashboard = false
}: TopicHeadersProps) {
  const { desktopNavIsOpen, toggleDesktopNav } = useLayoutStore((state) => state);
  return (
    <>
      <ContextHeader isOpen={desktopNavIsOpen} handleOpenMenu={toggleDesktopNav}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
          {!isNil(topic) && (
            <TopicBreadcrumbs
              topic={topic ?? undefined}
              locationId={location?.id}
              subLocationId={subLocation?.id}
              subLocationName={subLocation?.name}
              sectionId={section?.id}
              sectionName={section?.name}
              pageSections={pageSections}
            />
          )}
          {allowLocationChange && location && locations && (
            <LocationChanger
              location={location}
              locations={locations}
              topic={topic as MhcTopicFragment}
              uiLocation="Context Header"
            />
          )}
        </Box>
      </ContextHeader>
      {!isDashboard && (
        <Paper
          elevation={0}
          square
          sx={{ background: theme.palette.common.white, flex: 1, width: "100%" }}
        >
          <Box component="header" id="page-header">
            <Box sx={pageHeaderWrapperStyle("#FFFFFF")}>
              <Box
                sx={{
                  pt: 4,
                  pb: 4,
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  rowGap: 2
                }}
              >
                <Typography variant="h1" component="h1">
                  {topic?.name ?? title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
}
