import isNil from "lodash/isNil";

import { linkColor } from "theme/colors";

import { NavItem } from "layout/SideNav/SideNavMenu/SideNavMenu";

interface Props {
  isSelected: boolean;
  hasItems: boolean;
  level: number;
  anyChildSelected: boolean;
  items?: NavItem[] | undefined | null;
  parentSelected: boolean;
}
export const useGetBorderColor = ({
  isSelected,
  hasItems,
  level,
  anyChildSelected,
  items,
  parentSelected
}: Props) => {
  const blue = linkColor;
  const grey = "#1F334F80"; // Only used here
  if (isSelected) {
    return blue;
  }
  if (level >= 3 && !isSelected && !hasItems && !parentSelected) {
    return grey;
  }
  if (level >= 3 && (isNil(items) || items.length === 0 || anyChildSelected || parentSelected)) {
    return blue;
  }
  // selected || is active || closeActive && level > 3
  return grey;
};
