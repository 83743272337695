"use client";

import React from "react";

import { DashboardFooterTitle, DashboardPagesMap } from "./util/dashboardConfigurations";
import { getPageHref, GetPageHrefProps } from "./util/pageHref";

import { NavItem } from "common/components/NavTabs";
import { PortalCardFooter } from "common/components/PortalCardFooter/PortalCardFooter";

interface DashboardFooterProps {
  locationId: string;
  selectedId: string;
  configProps: GetPageHrefProps;
}

export const DashboardFooter: React.FC<DashboardFooterProps> = ({
  locationId,
  configProps,
  selectedId
}) => {
  const pages = Object.values(DashboardPagesMap[configProps.dashboard] ?? []);
  const title = DashboardFooterTitle[configProps.dashboard];
  const items: NavItem[] = pages.map((page) => ({
    ...page,
    locationId,
    href: getPageHref({ ...configProps, pageId: page.id })
  }));

  return (
    <PortalCardFooter
      items={items}
      footerTitle={title ?? "Sections in this dashboard"}
      selectedItemId={selectedId}
      target={"covid-home-page"}
    />
  );
};
