"use client";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";

import { NavDropdownProps } from "./types";

import { DropdownMenu } from "./DropdownMenu";

export const baseDropdownStyle: ButtonProps["sx"] = {
  textAlign: "left",
  textTransform: "none",
  justifyContent: "space-between",
  borderColor: "grey.400",
  backgroundColor: "background.paper",
  minHeight: "55px",
  ".MuiButton-endIcon": {
    color: "action.active",
    svg: { fontSize: "1.5rem" }
  }
};

export const baseDropdownTextStyle = {
  fontWeight: 700,
  fontSize: 16
};

export const Dropdown: React.FC<NavDropdownProps> = ({
  id,
  items,
  selectedId,
  sx = {},
  handleItemClick
}) => {
  const popupState = usePopupState({ variant: "popover", popupId: id });
  const selected = items.find((e) => e.id === selectedId);
  return (
    <Box sx={sx}>
      <Button
        variant="outlined"
        fullWidth
        endIcon={<ExpandMoreIcon fontSize="large" />}
        sx={{ ...baseDropdownStyle, mt: 1 }}
        {...bindTrigger(popupState)}
      >
        <Typography
          sx={{ ...baseDropdownTextStyle, color: selected?.name ? "primary.main" : "common.black" }}
        >
          {selected?.name || "Select Section"}
        </Typography>
      </Button>
      <DropdownMenu
        items={items}
        popupState={popupState}
        selectedId={selectedId || selected?.id}
        handleItemClick={handleItemClick}
      />
    </Box>
  );
};
