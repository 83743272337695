import { MhcLocationFragment } from "graphqlApi/types";

export const allLocationsBasicData: MhcLocationFragment[] = [
  {
    __typename: "MhcLocation",
    id: "state",
    name: "Delaware",
    geography: "state",
    population: 967679
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-505-01",
    name: "Census Tract 505.01",
    geography: "census_tract",
    population: 4556
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-03",
    name: "Census Tract 504.03",
    geography: "census_tract",
    population: 3412
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-169-04",
    name: "Census Tract 169.04",
    geography: "census_tract",
    population: 3996
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-405-02",
    name: "Census Tract 405.02",
    geography: "census_tract",
    population: 2648
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-9800-001",
    name: "Census Tract 9800 (Kent County)",
    geography: "census_tract",
    population: 263
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-107-03",
    name: "Census Tract 107.03",
    geography: "census_tract",
    population: 1877
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-12",
    name: "Census Tract 136.12",
    geography: "census_tract",
    population: 5804
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-434",
    name: "Census Tract 434",
    geography: "census_tract",
    population: 5552
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-07",
    name: "Census Tract 136.07",
    geography: "census_tract",
    population: 5930
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-114",
    name: "Census Tract 114",
    geography: "census_tract",
    population: 3206
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-125",
    name: "Census Tract 125",
    geography: "census_tract",
    population: 5015
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-123",
    name: "Census Tract 123",
    geography: "census_tract",
    population: 2716
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-115",
    name: "Census Tract 115",
    geography: "census_tract",
    population: 2957
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-116",
    name: "Census Tract 116",
    geography: "census_tract",
    population: 3520
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-05",
    name: "Census Tract 504.05",
    geography: "census_tract",
    population: 4615
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-501-05",
    name: "Census Tract 501.05",
    geography: "census_tract",
    population: 5205
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-06",
    name: "Census Tract 504.06",
    geography: "census_tract",
    population: 4709
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-108",
    name: "Census Tract 108",
    geography: "census_tract",
    population: 4653
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-08",
    name: "Census Tract 504.08",
    geography: "census_tract",
    population: 4726
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-14",
    name: "Census Tract 14",
    geography: "census_tract",
    population: 1990
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-4",
    name: "Census Tract 4",
    geography: "census_tract",
    population: 3241
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-26",
    name: "Census Tract 26",
    geography: "census_tract",
    population: 3785
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-122",
    name: "Census Tract 122",
    geography: "census_tract",
    population: 4621
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-511-02",
    name: "Census Tract 511.02",
    geography: "census_tract",
    population: 1450
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-22",
    name: "Census Tract 22",
    geography: "census_tract",
    population: 2815
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-120",
    name: "Census Tract 120",
    geography: "census_tract",
    population: 4368
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-02",
    name: "Census Tract 112.02",
    geography: "census_tract",
    population: 3783
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-169-01",
    name: "Census Tract 169.01",
    geography: "census_tract",
    population: 2190
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-06",
    name: "Census Tract 112.06",
    geography: "census_tract",
    population: 3881
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-113",
    name: "Census Tract 113",
    geography: "census_tract",
    population: 2340
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-118",
    name: "Census Tract 118",
    geography: "census_tract",
    population: 4211
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-135-01",
    name: "Census Tract 135.01",
    geography: "census_tract",
    population: 6988
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-04",
    name: "Census Tract 136.04",
    geography: "census_tract",
    population: 4321
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-9",
    name: "Census Tract 9",
    geography: "census_tract",
    population: 2446
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-2",
    name: "Census Tract 2",
    geography: "census_tract",
    population: 5596
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-11",
    name: "Census Tract 11",
    geography: "census_tract",
    population: 3183
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-13",
    name: "Census Tract 13",
    geography: "census_tract",
    population: 3242
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-16",
    name: "Census Tract 16",
    geography: "census_tract",
    population: 2268
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-138",
    name: "Census Tract 138",
    geography: "census_tract",
    population: 5625
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-150",
    name: "Census Tract 150",
    geography: "census_tract",
    population: 5670
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-511-01",
    name: "Census Tract 511.01",
    geography: "census_tract",
    population: 909
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-10",
    name: "Census Tract 136.10",
    geography: "census_tract",
    population: 5510
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-119",
    name: "Census Tract 119",
    geography: "census_tract",
    population: 2999
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-13",
    name: "Census Tract 136.13",
    geography: "census_tract",
    population: 5702
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-137",
    name: "Census Tract 137",
    geography: "census_tract",
    population: 4184
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-124",
    name: "Census Tract 124",
    geography: "census_tract",
    population: 4213
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-431",
    name: "Census Tract 431",
    geography: "census_tract",
    population: 2468
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-127",
    name: "Census Tract 127",
    geography: "census_tract",
    population: 4025
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-01",
    name: "Census Tract 112.01",
    geography: "census_tract",
    population: 3001
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-05",
    name: "Census Tract 112.05",
    geography: "census_tract",
    population: 1999
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-144-02",
    name: "Census Tract 144.02",
    geography: "census_tract",
    population: 2989
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-117",
    name: "Census Tract 117",
    geography: "census_tract",
    population: 4440
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-03",
    name: "Census Tract 148.03",
    geography: "census_tract",
    population: 4206
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-103",
    name: "Census Tract 103",
    geography: "census_tract",
    population: 3661
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-11",
    name: "Census Tract 136.11",
    geography: "census_tract",
    population: 6106
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-29",
    name: "Census Tract 29",
    geography: "census_tract",
    population: 3558
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-518-02",
    name: "Census Tract 518.02",
    geography: "census_tract",
    population: 4140
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-401",
    name: "Census Tract 401",
    geography: "census_tract",
    population: 7531
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-502",
    name: "Census Tract 502",
    geography: "census_tract",
    population: 4320
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-402-01",
    name: "Census Tract 402.01",
    geography: "census_tract",
    population: 4766
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-415",
    name: "Census Tract 415",
    geography: "census_tract",
    population: 4531
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-420",
    name: "Census Tract 420",
    geography: "census_tract",
    population: 3460
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-410",
    name: "Census Tract 410",
    geography: "census_tract",
    population: 6719
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-413",
    name: "Census Tract 413",
    geography: "census_tract",
    population: 2056
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-414",
    name: "Census Tract 414",
    geography: "census_tract",
    population: 3390
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-105-02",
    name: "Census Tract 105.02",
    geography: "census_tract",
    population: 5855
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-135-05",
    name: "Census Tract 135.05",
    geography: "census_tract",
    population: 3212
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-164-04",
    name: "Census Tract 164.04",
    geography: "census_tract",
    population: 2878
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-03",
    name: "Census Tract 112.03",
    geography: "census_tract",
    population: 4716
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-08",
    name: "Census Tract 166.08",
    geography: "census_tract",
    population: 6510
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-09",
    name: "Census Tract 148.09",
    geography: "census_tract",
    population: 8336
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-425",
    name: "Census Tract 425",
    geography: "census_tract",
    population: 4470
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-402-03",
    name: "Census Tract 402.03",
    geography: "census_tract",
    population: 5310
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-429",
    name: "Census Tract 429",
    geography: "census_tract",
    population: 5215
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-421",
    name: "Census Tract 421",
    geography: "census_tract",
    population: 4321
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-01",
    name: "Census Tract 508.01",
    geography: "census_tract",
    population: 3906
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-15",
    name: "Census Tract 15",
    geography: "census_tract",
    population: 2196
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-01",
    name: "Census Tract 504.01",
    geography: "census_tract",
    population: 3507
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-501-01",
    name: "Census Tract 501.01",
    geography: "census_tract",
    population: 4140
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-503-02",
    name: "Census Tract 503.02",
    geography: "census_tract",
    population: 5516
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-21",
    name: "Census Tract 21",
    geography: "census_tract",
    population: 1748
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-501-03",
    name: "Census Tract 501.03",
    geography: "census_tract",
    population: 5246
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-158-02",
    name: "Census Tract 158.02",
    geography: "census_tract",
    population: 2356
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-07",
    name: "Census Tract 149.07",
    geography: "census_tract",
    population: 4932
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-512-04",
    name: "Census Tract 512.04",
    geography: "census_tract",
    population: 682
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-512-02",
    name: "Census Tract 512.02",
    geography: "census_tract",
    population: 1121
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-512-03",
    name: "Census Tract 512.03",
    geography: "census_tract",
    population: 897
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-504-07",
    name: "Census Tract 504.07",
    geography: "census_tract",
    population: 6067
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-501-04",
    name: "Census Tract 501.04",
    geography: "census_tract",
    population: 4714
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-06",
    name: "Census Tract 507.06",
    geography: "census_tract",
    population: 2379
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-06",
    name: "Census Tract 149.06",
    geography: "census_tract",
    population: 5352
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-10",
    name: "Census Tract 148.10",
    geography: "census_tract",
    population: 7111
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-139-03",
    name: "Census Tract 139.03",
    geography: "census_tract",
    population: 5073
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-140",
    name: "Census Tract 140",
    geography: "census_tract",
    population: 5320
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-05",
    name: "Census Tract 422.05",
    geography: "census_tract",
    population: 2992
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-428-02",
    name: "Census Tract 428.02",
    geography: "census_tract",
    population: 5464
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-151",
    name: "Census Tract 151",
    geography: "census_tract",
    population: 3567
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-156",
    name: "Census Tract 156",
    geography: "census_tract",
    population: 2773
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-159",
    name: "Census Tract 159",
    geography: "census_tract",
    population: 3344
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-160",
    name: "Census Tract 160",
    geography: "census_tract",
    population: 2744
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-161",
    name: "Census Tract 161",
    geography: "census_tract",
    population: 2172
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-162",
    name: "Census Tract 162",
    geography: "census_tract",
    population: 2492
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-163-01",
    name: "Census Tract 163.01",
    geography: "census_tract",
    population: 5986
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-141",
    name: "Census Tract 141",
    geography: "census_tract",
    population: 4284
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-142",
    name: "Census Tract 142",
    geography: "census_tract",
    population: 1761
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-145-01",
    name: "Census Tract 145.01",
    geography: "census_tract",
    population: 2126
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-147-02",
    name: "Census Tract 147.02",
    geography: "census_tract",
    population: 1759
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-147-03",
    name: "Census Tract 147.03",
    geography: "census_tract",
    population: 5485
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-08",
    name: "Census Tract 148.08",
    geography: "census_tract",
    population: 6381
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-407",
    name: "Census Tract 407",
    geography: "census_tract",
    population: 5631
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-416",
    name: "Census Tract 416",
    geography: "census_tract",
    population: 2168
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-418-02",
    name: "Census Tract 418.02",
    geography: "census_tract",
    population: 6080
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-505-05",
    name: "Census Tract 505.05",
    geography: "census_tract",
    population: 2927
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-419",
    name: "Census Tract 419",
    geography: "census_tract",
    population: 5194
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-139-01",
    name: "Census Tract 139.01",
    geography: "census_tract",
    population: 3018
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-101-06",
    name: "Census Tract 101.06",
    geography: "census_tract",
    population: 1650
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-10",
    name: "Census Tract 513.10",
    geography: "census_tract",
    population: 1554
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-405-01",
    name: "Census Tract 405.01",
    geography: "census_tract",
    population: 4731
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-11",
    name: "Census Tract 166.11",
    geography: "census_tract",
    population: 6782
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-09",
    name: "Census Tract 510.09",
    geography: "census_tract",
    population: 2212
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-512-01",
    name: "Census Tract 512.01",
    geography: "census_tract",
    population: 1428
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-15",
    name: "Census Tract 510.15",
    geography: "census_tract",
    population: 1761
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-402-06",
    name: "Census Tract 402.06",
    geography: "census_tract",
    population: 6495
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-506-04",
    name: "Census Tract 506.04",
    geography: "census_tract",
    population: 3729
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-08",
    name: "Census Tract 507.08",
    geography: "census_tract",
    population: 2577
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-14",
    name: "Census Tract 148.14",
    geography: "census_tract",
    population: 4121
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-07",
    name: "Census Tract 508.07",
    geography: "census_tract",
    population: 1984
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-05",
    name: "Census Tract 508.05",
    geography: "census_tract",
    population: 3126
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-12",
    name: "Census Tract 166.12",
    geography: "census_tract",
    population: 5670
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-163-06",
    name: "Census Tract 163.06",
    geography: "census_tract",
    population: 4433
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-402-05",
    name: "Census Tract 402.05",
    geography: "census_tract",
    population: 3934
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-02",
    name: "Census Tract 513.02",
    geography: "census_tract",
    population: 4195
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-506-01",
    name: "Census Tract 506.01",
    geography: "census_tract",
    population: 5336
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-03",
    name: "Census Tract 149.03",
    geography: "census_tract",
    population: 7662
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-19-02",
    name: "Census Tract 19.02",
    geography: "census_tract",
    population: 2131
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-28",
    name: "Census Tract 28",
    geography: "census_tract",
    population: 1886
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-163-07",
    name: "Census Tract 163.07",
    geography: "census_tract",
    population: 2606
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-12",
    name: "Census Tract 148.12",
    geography: "census_tract",
    population: 4732
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-139-06",
    name: "Census Tract 139.06",
    geography: "census_tract",
    population: 4827
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-139-05",
    name: "Census Tract 139.05",
    geography: "census_tract",
    population: 3630
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-101-05",
    name: "Census Tract 101.05",
    geography: "census_tract",
    population: 2329
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-143-01",
    name: "Census Tract 143.01",
    geography: "census_tract",
    population: 1448
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-09",
    name: "Census Tract 166.09",
    geography: "census_tract",
    population: 5156
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-163-08",
    name: "Census Tract 163.08",
    geography: "census_tract",
    population: 4309
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-13",
    name: "Census Tract 148.13",
    geography: "census_tract",
    population: 5051
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-135-07",
    name: "Census Tract 135.07",
    geography: "census_tract",
    population: 1709
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-143-02",
    name: "Census Tract 143.02",
    geography: "census_tract",
    population: 6014
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-107-04",
    name: "Census Tract 107.04",
    geography: "census_tract",
    population: 3977
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-411-01",
    name: "Census Tract 411.01",
    geography: "census_tract",
    population: 3620
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-503-04",
    name: "Census Tract 503.04",
    geography: "census_tract",
    population: 5343
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-04",
    name: "Census Tract 422.04",
    geography: "census_tract",
    population: 4202
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-07",
    name: "Census Tract 422.07",
    geography: "census_tract",
    population: 2208
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-418-03",
    name: "Census Tract 418.03",
    geography: "census_tract",
    population: 4170
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-06",
    name: "Census Tract 422.06",
    geography: "census_tract",
    population: 2624
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-08",
    name: "Census Tract 422.08",
    geography: "census_tract",
    population: 4136
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-503-03",
    name: "Census Tract 503.03",
    geography: "census_tract",
    population: 2896
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-11",
    name: "Census Tract 507.11",
    geography: "census_tract",
    population: 1065
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-13",
    name: "Census Tract 513.13",
    geography: "census_tract",
    population: 2650
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-14",
    name: "Census Tract 513.14",
    geography: "census_tract",
    population: 2319
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-09",
    name: "Census Tract 513.09",
    geography: "census_tract",
    population: 1171
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-12",
    name: "Census Tract 513.12",
    geography: "census_tract",
    population: 1909
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-11",
    name: "Census Tract 513.11",
    geography: "census_tract",
    population: 1622
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-101-04",
    name: "Census Tract 101.04",
    geography: "census_tract",
    population: 3745
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-515-01",
    name: "Census Tract 515.01",
    geography: "census_tract",
    population: 2420
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-432-02",
    name: "Census Tract 432.02",
    geography: "census_tract",
    population: 4412
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-08",
    name: "Census Tract 513.08",
    geography: "census_tract",
    population: 3649
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-509-05",
    name: "Census Tract 509.05",
    geography: "census_tract",
    population: 3786
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-06",
    name: "Census Tract 508.06",
    geography: "census_tract",
    population: 3540
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-14",
    name: "Census Tract 510.14",
    geography: "census_tract",
    population: 1718
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-17",
    name: "Census Tract 510.17",
    geography: "census_tract",
    population: 2996
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-506-03",
    name: "Census Tract 506.03",
    geography: "census_tract",
    population: 2463
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-12",
    name: "Census Tract 510.12",
    geography: "census_tract",
    population: 2152
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-09",
    name: "Census Tract 507.09",
    geography: "census_tract",
    population: 1589
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-13",
    name: "Census Tract 166.13",
    geography: "census_tract",
    population: 5352
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-168-07",
    name: "Census Tract 168.07",
    geography: "census_tract",
    population: 1621
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-14",
    name: "Census Tract 166.14",
    geography: "census_tract",
    population: 6496
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-11",
    name: "Census Tract 510.11",
    geography: "census_tract",
    population: 3147
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-166-10",
    name: "Census Tract 166.10",
    geography: "census_tract",
    population: 7298
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-515-02",
    name: "Census Tract 515.02",
    geography: "census_tract",
    population: 3076
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-509-03",
    name: "Census Tract 509.03",
    geography: "census_tract",
    population: 1896
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-505-06",
    name: "Census Tract 505.06",
    geography: "census_tract",
    population: 3354
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-13",
    name: "Census Tract 510.13",
    geography: "census_tract",
    population: 3826
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-148-11",
    name: "Census Tract 148.11",
    geography: "census_tract",
    population: 6417
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-04",
    name: "Census Tract 508.04",
    geography: "census_tract",
    population: 4162
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-5",
    name: "Census Tract 5",
    geography: "census_tract",
    population: 3892
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-6-01",
    name: "Census Tract 6.01",
    geography: "census_tract",
    population: 3323
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-6-02",
    name: "Census Tract 6.02",
    geography: "census_tract",
    population: 2724
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-433",
    name: "Census Tract 433",
    geography: "census_tract",
    population: 6626
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-112-04",
    name: "Census Tract 112.04",
    geography: "census_tract",
    population: 3225
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-511-03",
    name: "Census Tract 511.03",
    geography: "census_tract",
    population: 1306
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-110",
    name: "Census Tract 110",
    geography: "census_tract",
    population: 3010
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-147-06",
    name: "Census Tract 147.06",
    geography: "census_tract",
    population: 2743
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-04",
    name: "Census Tract 149.04",
    geography: "census_tract",
    population: 5417
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-418-04",
    name: "Census Tract 418.04",
    geography: "census_tract",
    population: 6020
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-24",
    name: "Census Tract 24",
    geography: "census_tract",
    population: 3940
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-27",
    name: "Census Tract 27",
    geography: "census_tract",
    population: 2654
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-164-01",
    name: "Census Tract 164.01",
    geography: "census_tract",
    population: 6126
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-12",
    name: "Census Tract 12",
    geography: "census_tract",
    population: 1684
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-104",
    name: "Census Tract 104",
    geography: "census_tract",
    population: 4613
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-109",
    name: "Census Tract 109",
    geography: "census_tract",
    population: 2504
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-111",
    name: "Census Tract 111",
    geography: "census_tract",
    population: 2710
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-145-02",
    name: "Census Tract 145.02",
    geography: "census_tract",
    population: 6752
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-152",
    name: "Census Tract 152",
    geography: "census_tract",
    population: 6110
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-25",
    name: "Census Tract 25",
    geography: "census_tract",
    population: 3263
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-30-02",
    name: "Census Tract 30.02",
    geography: "census_tract",
    population: 3593
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-135-06",
    name: "Census Tract 135.06",
    geography: "census_tract",
    population: 4334
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-15",
    name: "Census Tract 136.15",
    geography: "census_tract",
    population: 3486
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-517-02",
    name: "Census Tract 517.02",
    geography: "census_tract",
    population: 5976
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-519",
    name: "Census Tract 519",
    geography: "census_tract",
    population: 4359
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-514",
    name: "Census Tract 514",
    geography: "census_tract",
    population: 3421
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-01",
    name: "Census Tract 507.01",
    geography: "census_tract",
    population: 4646
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-517-01",
    name: "Census Tract 517.01",
    geography: "census_tract",
    population: 4005
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-518-01",
    name: "Census Tract 518.01",
    geography: "census_tract",
    population: 5240
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-102",
    name: "Census Tract 102",
    geography: "census_tract",
    population: 2139
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-129",
    name: "Census Tract 129",
    geography: "census_tract",
    population: 4478
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-512-05",
    name: "Census Tract 512.05",
    geography: "census_tract",
    population: 761
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-08",
    name: "Census Tract 149.08",
    geography: "census_tract",
    population: 2258
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-135-08",
    name: "Census Tract 135.08",
    geography: "census_tract",
    population: 5948
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-402-04",
    name: "Census Tract 402.04",
    geography: "census_tract",
    population: 6108
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-422-03",
    name: "Census Tract 422.03",
    geography: "census_tract",
    population: 5809
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-412",
    name: "Census Tract 412",
    geography: "census_tract",
    population: 4130
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-428-01",
    name: "Census Tract 428.01",
    geography: "census_tract",
    population: 1694
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-10",
    name: "Census Tract 507.10",
    geography: "census_tract",
    population: 2354
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-9800-005",
    name: "Census Tract 9800 (Sussex County)",
    geography: "census_tract",
    population: 0
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-10",
    name: "Census Tract 510.10",
    geography: "census_tract",
    population: 2198
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-16",
    name: "Census Tract 510.16",
    geography: "census_tract",
    population: 2131
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-508-08",
    name: "Census Tract 508.08",
    geography: "census_tract",
    population: 3632
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-513-07",
    name: "Census Tract 513.07",
    geography: "census_tract",
    population: 3471
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-07",
    name: "Census Tract 507.07",
    geography: "census_tract",
    population: 2837
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-409",
    name: "Census Tract 409",
    geography: "census_tract",
    population: 2373
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-509-04",
    name: "Census Tract 509.04",
    geography: "census_tract",
    population: 2117
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-430",
    name: "Census Tract 430",
    geography: "census_tract",
    population: 5018
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-417-02",
    name: "Census Tract 417.02",
    geography: "census_tract",
    population: 4777
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-417-01",
    name: "Census Tract 417.01",
    geography: "census_tract",
    population: 5808
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-163-09",
    name: "Census Tract 163.09",
    geography: "census_tract",
    population: 3674
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-168-06",
    name: "Census Tract 168.06",
    geography: "census_tract",
    population: 5009
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-168-05",
    name: "Census Tract 168.05",
    geography: "census_tract",
    population: 1653
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-510-08",
    name: "Census Tract 510.08",
    geography: "census_tract",
    population: 3535
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-168-08",
    name: "Census Tract 168.08",
    geography: "census_tract",
    population: 5900
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-154",
    name: "Census Tract 154",
    geography: "census_tract",
    population: 2812
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-144-03",
    name: "Census Tract 144.03",
    geography: "census_tract",
    population: 4966
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-144-04",
    name: "Census Tract 144.04",
    geography: "census_tract",
    population: 4754
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-9801",
    name: "Census Tract 9801",
    geography: "census_tract",
    population: 0
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-149-09",
    name: "Census Tract 149.09",
    geography: "census_tract",
    population: 5711
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-155-02",
    name: "Census Tract 155.02",
    geography: "census_tract",
    population: 2892
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-121",
    name: "Census Tract 121",
    geography: "census_tract",
    population: 3190
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-132",
    name: "Census Tract 132",
    geography: "census_tract",
    population: 2746
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-130",
    name: "Census Tract 130",
    geography: "census_tract",
    population: 1975
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-3",
    name: "Census Tract 3",
    geography: "census_tract",
    population: 3007
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-133",
    name: "Census Tract 133",
    geography: "census_tract",
    population: 1819
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-126",
    name: "Census Tract 126",
    geography: "census_tract",
    population: 2996
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-131",
    name: "Census Tract 131",
    geography: "census_tract",
    population: 2292
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-134",
    name: "Census Tract 134",
    geography: "census_tract",
    population: 2146
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-08",
    name: "Census Tract 136.08",
    geography: "census_tract",
    population: 2206
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-147-05",
    name: "Census Tract 147.05",
    geography: "census_tract",
    population: 5082
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-136-14",
    name: "Census Tract 136.14",
    geography: "census_tract",
    population: 2690
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-507-03",
    name: "Census Tract 507.03",
    geography: "census_tract",
    population: 2244
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-23",
    name: "Census Tract 23",
    geography: "census_tract",
    population: 2490
  },
  {
    __typename: "MhcLocation",
    id: "census-tract-505-03",
    name: "Census Tract 505.03",
    geography: "census_tract",
    population: 6221
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19902",
    name: "Zip Code 19902",
    geography: "zip_code",
    population: 263
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19732",
    name: "Zip Code 19732",
    geography: "zip_code",
    population: 81
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19711",
    name: "Zip Code 19711",
    geography: "zip_code",
    population: 53194
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19954",
    name: "Zip Code 19954",
    geography: "zip_code",
    population: 1417
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19951",
    name: "Zip Code 19951",
    geography: "zip_code",
    population: 2046
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19953",
    name: "Zip Code 19953",
    geography: "zip_code",
    population: 4896
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19955",
    name: "Zip Code 19955",
    geography: "zip_code",
    population: 96
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19975",
    name: "Zip Code 19975",
    geography: "zip_code",
    population: 9336
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19804",
    name: "Zip Code 19804",
    geography: "zip_code",
    population: 17529
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19801",
    name: "Zip Code 19801",
    geography: "zip_code",
    population: 17447
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19731",
    name: "Zip Code 19731",
    geography: "zip_code",
    population: 350
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19967",
    name: "Zip Code 19967",
    geography: "zip_code",
    population: 1005
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19805",
    name: "Zip Code 19805",
    geography: "zip_code",
    population: 38061
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19936",
    name: "Zip Code 19936",
    geography: "zip_code",
    population: 315
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19960",
    name: "Zip Code 19960",
    geography: "zip_code",
    population: 6209
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19968",
    name: "Zip Code 19968",
    geography: "zip_code",
    population: 12902
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19966",
    name: "Zip Code 19966",
    geography: "zip_code",
    population: 30601
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19970",
    name: "Zip Code 19970",
    geography: "zip_code",
    population: 8770
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19977",
    name: "Zip Code 19977",
    geography: "zip_code",
    population: 25536
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19930",
    name: "Zip Code 19930",
    geography: "zip_code",
    population: 3077
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19941",
    name: "Zip Code 19941",
    geography: "zip_code",
    population: 3477
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19940",
    name: "Zip Code 19940",
    geography: "zip_code",
    population: 6560
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19950",
    name: "Zip Code 19950",
    geography: "zip_code",
    population: 6762
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19808",
    name: "Zip Code 19808",
    geography: "zip_code",
    population: 38106
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19807",
    name: "Zip Code 19807",
    geography: "zip_code",
    population: 7369
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19713",
    name: "Zip Code 19713",
    geography: "zip_code",
    population: 30403
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19979",
    name: "Zip Code 19979",
    geography: "zip_code",
    population: 738
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19706",
    name: "Zip Code 19706",
    geography: "zip_code",
    population: 1858
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19802",
    name: "Zip Code 19802",
    geography: "zip_code",
    population: 26126
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19736",
    name: "Zip Code 19736",
    geography: "zip_code",
    population: 59
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19803",
    name: "Zip Code 19803",
    geography: "zip_code",
    population: 21484
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19701",
    name: "Zip Code 19701",
    geography: "zip_code",
    population: 42240
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19939",
    name: "Zip Code 19939",
    geography: "zip_code",
    population: 7302
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19952",
    name: "Zip Code 19952",
    geography: "zip_code",
    population: 10610
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19958",
    name: "Zip Code 19958",
    geography: "zip_code",
    population: 27123
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19956",
    name: "Zip Code 19956",
    geography: "zip_code",
    population: 14557
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19730",
    name: "Zip Code 19730",
    geography: "zip_code",
    population: 441
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19702",
    name: "Zip Code 19702",
    geography: "zip_code",
    population: 54980
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19971",
    name: "Zip Code 19971",
    geography: "zip_code",
    population: 13922
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19717",
    name: "Zip Code 19717",
    geography: "zip_code",
    population: 3649
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19716",
    name: "Zip Code 19716",
    geography: "zip_code",
    population: 1426
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19962",
    name: "Zip Code 19962",
    geography: "zip_code",
    population: 11448
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19735",
    name: "Zip Code 19735",
    geography: "zip_code",
    population: 8
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19947",
    name: "Zip Code 19947",
    geography: "zip_code",
    population: 20033
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19710",
    name: "Zip Code 19710",
    geography: "zip_code",
    population: 0
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19963",
    name: "Zip Code 19963",
    geography: "zip_code",
    population: 21489
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19901",
    name: "Zip Code 19901",
    geography: "zip_code",
    population: 38673
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19707",
    name: "Zip Code 19707",
    geography: "zip_code",
    population: 16383
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19943",
    name: "Zip Code 19943",
    geography: "zip_code",
    population: 12504
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19904",
    name: "Zip Code 19904",
    geography: "zip_code",
    population: 38158
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19946",
    name: "Zip Code 19946",
    geography: "zip_code",
    population: 5539
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19931",
    name: "Zip Code 19931",
    geography: "zip_code",
    population: 416
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19734",
    name: "Zip Code 19734",
    geography: "zip_code",
    population: 13072
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19733",
    name: "Zip Code 19733",
    geography: "zip_code",
    population: 68
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19720",
    name: "Zip Code 19720",
    geography: "zip_code",
    population: 59410
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19809",
    name: "Zip Code 19809",
    geography: "zip_code",
    population: 14827
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19938",
    name: "Zip Code 19938",
    geography: "zip_code",
    population: 8587
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19934",
    name: "Zip Code 19934",
    geography: "zip_code",
    population: 13673
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19933",
    name: "Zip Code 19933",
    geography: "zip_code",
    population: 11457
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19806",
    name: "Zip Code 19806",
    geography: "zip_code",
    population: 9313
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19703",
    name: "Zip Code 19703",
    geography: "zip_code",
    population: 15379
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19810",
    name: "Zip Code 19810",
    geography: "zip_code",
    population: 25273
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19973",
    name: "Zip Code 19973",
    geography: "zip_code",
    population: 25991
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19945",
    name: "Zip Code 19945",
    geography: "zip_code",
    population: 6432
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19709",
    name: "Zip Code 19709",
    geography: "zip_code",
    population: 45005
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19964",
    name: "Zip Code 19964",
    geography: "zip_code",
    population: 1472
  },
  {
    __typename: "MhcLocation",
    id: "zip-code-19944",
    name: "Zip Code 19944",
    geography: "zip_code",
    population: 746
  },
  {
    __typename: "MhcLocation",
    id: "school-district-colonial",
    name: "Colonial School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-smyrna",
    name: "Smyrna School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-capital",
    name: "Capital School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-caesar-rodney",
    name: "Caesar Rodney School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-lake-forest",
    name: "Lake Forest School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-milford",
    name: "Milford School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-ncc-votech",
    name: "NCC VoTech",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-polytech",
    name: "POLYTECH",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-campus-community-school",
    name: "Campus Community School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-charter-school-of-new-castle",
    name: "Charter School of New Castle",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-charter-school-of-wilmington",
    name: "Charter School of Wilmington",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-delaware-military-academy",
    name: "Delaware Military Academy",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-early-college-high-school-at-dsu",
    name: "Early College High School At DSU",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-east-side-charter-school",
    name: "East Side Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-edison-thomas-a-charter-school",
    name: "Edison (Thomas A.) Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-first-state-military-academy",
    name: "First State Military Academy",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-first-state-montessori-academy",
    name: "First State Montessori Academy",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-freire-charter-school-wilmington",
    name: "Freire Charter School Wilmington",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-great-oaks-charter-school",
    name: "Great Oaks Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-kuumba-academy-charter-school",
    name: "Kuumba Academy Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-las-americas-aspira-academy",
    name: "Las Americas Aspira Academy",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-mot-charter-school",
    name: "MOT Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-newark-charter-school",
    name: "Newark Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-odyssey-charter-school",
    name: "Odyssey Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-positive-outcomes-charter-school",
    name: "Positive Outcomes Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-providence-creek-academy-charter-school",
    name: "Providence Creek Academy Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-sussex-academy",
    name: "Sussex Academy",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-sussex-montessori-school",
    name: "Sussex Montessori School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-brandywine",
    name: "Brandywine School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-red-clay-consolidated",
    name: "Red Clay Consolidated School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-christina",
    name: "Christina School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-appoquinimink",
    name: "Appoquinimink School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-woodbridge",
    name: "Woodbridge School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-cape-henlopen",
    name: "Cape Henlopen School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-indian-river",
    name: "Indian River School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-seaford",
    name: "Seaford School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-laurel",
    name: "Laurel School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-academia-antonia-alonso",
    name: "Academia Antonia Alonso",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-academy-of-dover-charter-school",
    name: "Academy of Dover Charter School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-gateway-lab-school",
    name: "Gateway Lab School",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-delmar",
    name: "Delmar School District",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "school-district-sussex-tech",
    name: "Sussex Tech",
    geography: "school_district",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "county-new-castle",
    name: "New Castle County",
    geography: "county",
    population: 558306
  },
  {
    __typename: "MhcLocation",
    id: "county-kent",
    name: "Kent County",
    geography: "county",
    population: 179124
  },
  {
    __typename: "MhcLocation",
    id: "county-sussex",
    name: "Sussex County",
    geography: "county",
    population: 230249
  },
  {
    __typename: "MhcLocation",
    id: "odessa",
    name: "Odessa",
    geography: "municipality",
    population: 352
  },
  {
    __typename: "MhcLocation",
    id: "south-bethany",
    name: "South Bethany",
    geography: "municipality",
    population: 558
  },
  {
    __typename: "MhcLocation",
    id: "delaware-city",
    name: "Delaware City",
    geography: "municipality",
    population: 1779
  },
  {
    __typename: "MhcLocation",
    id: "delmar",
    name: "Delmar",
    geography: "municipality",
    population: 2153
  },
  {
    __typename: "MhcLocation",
    id: "bethany-beach",
    name: "Bethany Beach",
    geography: "municipality",
    population: 1150
  },
  {
    __typename: "MhcLocation",
    id: "dewey-beach",
    name: "Dewey Beach",
    geography: "municipality",
    population: 442
  },
  {
    __typename: "MhcLocation",
    id: "dagsboro",
    name: "Dagsboro",
    geography: "municipality",
    population: 1026
  },
  {
    __typename: "MhcLocation",
    id: "newport",
    name: "Newport",
    geography: "municipality",
    population: 906
  },
  {
    __typename: "MhcLocation",
    id: "townsend",
    name: "Townsend",
    geography: "municipality",
    population: 2546
  },
  {
    __typename: "MhcLocation",
    id: "wyoming",
    name: "Wyoming",
    geography: "municipality",
    population: 1596
  },
  {
    __typename: "MhcLocation",
    id: "clayton",
    name: "Clayton",
    geography: "municipality",
    population: 3393
  },
  {
    __typename: "MhcLocation",
    id: "hartly",
    name: "Hartly",
    geography: "municipality",
    population: 167
  },
  {
    __typename: "MhcLocation",
    id: "little-creek",
    name: "Little Creek",
    geography: "municipality",
    population: 288
  },
  {
    __typename: "MhcLocation",
    id: "viola",
    name: "Viola",
    geography: "municipality",
    population: 173
  },
  {
    __typename: "MhcLocation",
    id: "frederica",
    name: "Frederica",
    geography: "municipality",
    population: 948
  },
  {
    __typename: "MhcLocation",
    id: "middletown",
    name: "Middletown",
    geography: "municipality",
    population: 22350
  },
  {
    __typename: "MhcLocation",
    id: "selbyville",
    name: "Selbyville",
    geography: "municipality",
    population: 2520
  },
  {
    __typename: "MhcLocation",
    id: "smyrna",
    name: "Smyrna",
    geography: "municipality",
    population: 11694
  },
  {
    __typename: "MhcLocation",
    id: "bellefonte",
    name: "Bellefonte",
    geography: "municipality",
    population: 1109
  },
  {
    __typename: "MhcLocation",
    id: "wilmington",
    name: "Wilmington",
    geography: "municipality",
    population: 70655
  },
  {
    __typename: "MhcLocation",
    id: "millville",
    name: "Millville",
    geography: "municipality",
    population: 1484
  },
  {
    __typename: "MhcLocation",
    id: "milton",
    name: "Milton",
    geography: "municipality",
    population: 2993
  },
  {
    __typename: "MhcLocation",
    id: "new-castle",
    name: "New Castle",
    geography: "municipality",
    population: 5302
  },
  {
    __typename: "MhcLocation",
    id: "lewes",
    name: "Lewes",
    geography: "municipality",
    population: 3266
  },
  {
    __typename: "MhcLocation",
    id: "georgetown",
    name: "Georgetown",
    geography: "municipality",
    population: 7436
  },
  {
    __typename: "MhcLocation",
    id: "henlopen-acres",
    name: "Henlopen Acres",
    geography: "municipality",
    population: 187
  },
  {
    __typename: "MhcLocation",
    id: "rehoboth-beach",
    name: "Rehoboth Beach",
    geography: "municipality",
    population: 1588
  },
  {
    __typename: "MhcLocation",
    id: "ardentown",
    name: "Ardentown",
    geography: "municipality",
    population: 240
  },
  {
    __typename: "MhcLocation",
    id: "elsmere",
    name: "Elsmere",
    geography: "municipality",
    population: 5814
  },
  {
    __typename: "MhcLocation",
    id: "camden",
    name: "Camden",
    geography: "municipality",
    population: 3529
  },
  {
    __typename: "MhcLocation",
    id: "laurel",
    name: "Laurel",
    geography: "municipality",
    population: 4311
  },
  {
    __typename: "MhcLocation",
    id: "felton",
    name: "Felton",
    geography: "municipality",
    population: 1191
  },
  {
    __typename: "MhcLocation",
    id: "blades",
    name: "Blades",
    geography: "municipality",
    population: 1123
  },
  {
    __typename: "MhcLocation",
    id: "arden",
    name: "Arden",
    geography: "municipality",
    population: 535
  },
  {
    __typename: "MhcLocation",
    id: "ocean-view",
    name: "Ocean View",
    geography: "municipality",
    population: 2637
  },
  {
    __typename: "MhcLocation",
    id: "seaford",
    name: "Seaford",
    geography: "municipality",
    population: 7897
  },
  {
    __typename: "MhcLocation",
    id: "bridgeville",
    name: "Bridgeville",
    geography: "municipality",
    population: 3508
  },
  {
    __typename: "MhcLocation",
    id: "milford",
    name: "Milford",
    geography: "municipality",
    population: 11463
  },
  {
    __typename: "MhcLocation",
    id: "houston",
    name: "Houston",
    geography: "municipality",
    population: 449
  },
  {
    __typename: "MhcLocation",
    id: "woodside",
    name: "Woodside",
    geography: "municipality",
    population: 207
  },
  {
    __typename: "MhcLocation",
    id: "bethel",
    name: "Bethel",
    geography: "municipality",
    population: 404
  },
  {
    __typename: "MhcLocation",
    id: "ardencroft",
    name: "Ardencroft",
    geography: "municipality",
    population: 190
  },
  {
    __typename: "MhcLocation",
    id: "kenton",
    name: "Kenton",
    geography: "municipality",
    population: 464
  },
  {
    __typename: "MhcLocation",
    id: "leipsic",
    name: "Leipsic",
    geography: "municipality",
    population: 141
  },
  {
    __typename: "MhcLocation",
    id: "magnolia",
    name: "Magnolia",
    geography: "municipality",
    population: 379
  },
  {
    __typename: "MhcLocation",
    id: "newark",
    name: "Newark",
    geography: "municipality",
    population: 33822
  },
  {
    __typename: "MhcLocation",
    id: "slaughter-beach",
    name: "Slaughter Beach",
    geography: "municipality",
    population: 302
  },
  {
    __typename: "MhcLocation",
    id: "ellendale",
    name: "Ellendale",
    geography: "municipality",
    population: 751
  },
  {
    __typename: "MhcLocation",
    id: "millsboro",
    name: "Millsboro",
    geography: "municipality",
    population: 4448
  },
  {
    __typename: "MhcLocation",
    id: "cheswold",
    name: "Cheswold",
    geography: "municipality",
    population: 1673
  },
  {
    __typename: "MhcLocation",
    id: "frankford",
    name: "Frankford",
    geography: "municipality",
    population: 712
  },
  {
    __typename: "MhcLocation",
    id: "harrington",
    name: "Harrington",
    geography: "municipality",
    population: 3658
  },
  {
    __typename: "MhcLocation",
    id: "farmington",
    name: "Farmington",
    geography: "municipality",
    population: 184
  },
  {
    __typename: "MhcLocation",
    id: "greenwood",
    name: "Greenwood",
    geography: "municipality",
    population: 1127
  },
  {
    __typename: "MhcLocation",
    id: "bowers-beach",
    name: "Bowers Beach",
    geography: "municipality",
    population: 312
  },
  {
    __typename: "MhcLocation",
    id: "dover",
    name: "Dover",
    geography: "municipality",
    population: 37872
  },
  {
    __typename: "MhcLocation",
    id: "fenwick-island",
    name: "Fenwick Island",
    geography: "municipality",
    population: 445
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-21",
    name: "State Senate District 21",
    geography: "senate_district",
    population: 44205
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-5",
    name: "State Senate District 5",
    geography: "senate_district",
    population: 40613
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-11",
    name: "State Senate District 11",
    geography: "senate_district",
    population: 42726
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-13",
    name: "State Senate District 13",
    geography: "senate_district",
    population: 43838
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-7",
    name: "State Senate District 7",
    geography: "senate_district",
    population: 42058
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-3",
    name: "State Senate District 3",
    geography: "senate_district",
    population: 39367
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-10",
    name: "State Senate District 10",
    geography: "senate_district",
    population: 45317
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-14",
    name: "State Senate District 14",
    geography: "senate_district",
    population: 52874
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-15",
    name: "State Senate District 15",
    geography: "senate_district",
    population: 48839
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-16",
    name: "State Senate District 16",
    geography: "senate_district",
    population: 47845
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-17",
    name: "State Senate District 17",
    geography: "senate_district",
    population: 48028
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-18",
    name: "State Senate District 18",
    geography: "senate_district",
    population: 49179
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-19",
    name: "State Senate District 19",
    geography: "senate_district",
    population: 52367
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-20",
    name: "State Senate District 20",
    geography: "senate_district",
    population: 50078
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-2",
    name: "State Senate District 2",
    geography: "senate_district",
    population: 41343
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-1",
    name: "State Senate District 1",
    geography: "senate_district",
    population: 44477
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-8",
    name: "State Senate District 8",
    geography: "senate_district",
    population: 45333
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-12",
    name: "State Senate District 12",
    geography: "senate_district",
    population: 53006
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-9",
    name: "State Senate District 9",
    geography: "senate_district",
    population: 41935
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-6",
    name: "State Senate District 6",
    geography: "senate_district",
    population: 53772
  },
  {
    __typename: "MhcLocation",
    id: "state-senate-district-4",
    name: "State Senate District 4",
    geography: "senate_district",
    population: 40479
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-4",
    name: "State House District 4",
    geography: "house_of_rep_district",
    population: 20375
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-5",
    name: "State House District 5",
    geography: "house_of_rep_district",
    population: 22135
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-8",
    name: "State House District 8",
    geography: "house_of_rep_district",
    population: 24218
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-26",
    name: "State House District 26",
    geography: "house_of_rep_district",
    population: 23141
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-19",
    name: "State House District 19",
    geography: "house_of_rep_district",
    population: 21750
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-7",
    name: "State House District 7",
    geography: "house_of_rep_district",
    population: 22282
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-16",
    name: "State House District 16",
    geography: "house_of_rep_district",
    population: 21924
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-18",
    name: "State House District 18",
    geography: "house_of_rep_district",
    population: 23173
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-24",
    name: "State House District 24",
    geography: "house_of_rep_district",
    population: 23233
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-25",
    name: "State House District 25",
    geography: "house_of_rep_district",
    population: 22793
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-21",
    name: "State House District 21",
    geography: "house_of_rep_district",
    population: 22454
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-22",
    name: "State House District 22",
    geography: "house_of_rep_district",
    population: 22411
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-31",
    name: "State House District 31",
    geography: "house_of_rep_district",
    population: 23737
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-23",
    name: "State House District 23",
    geography: "house_of_rep_district",
    population: 22531
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-27",
    name: "State House District 27",
    geography: "house_of_rep_district",
    population: 23343
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-39",
    name: "State House District 39",
    geography: "house_of_rep_district",
    population: 22698
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-40",
    name: "State House District 40",
    geography: "house_of_rep_district",
    population: 23917
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-1",
    name: "State House District 1",
    geography: "house_of_rep_district",
    population: 22307
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-2",
    name: "State House District 2",
    geography: "house_of_rep_district",
    population: 21782
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-3",
    name: "State House District 3",
    geography: "house_of_rep_district",
    population: 19542
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-10",
    name: "State House District 10",
    geography: "house_of_rep_district",
    population: 21510
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-13",
    name: "State House District 13",
    geography: "house_of_rep_district",
    population: 23216
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-37",
    name: "State House District 37",
    geography: "house_of_rep_district",
    population: 31004
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-9",
    name: "State House District 9",
    geography: "house_of_rep_district",
    population: 30537
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-12",
    name: "State House District 12",
    geography: "house_of_rep_district",
    population: 24385
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-30",
    name: "State House District 30",
    geography: "house_of_rep_district",
    population: 20520
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-33",
    name: "State House District 33",
    geography: "house_of_rep_district",
    population: 24038
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-6",
    name: "State House District 6",
    geography: "house_of_rep_district",
    population: 21705
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-28",
    name: "State House District 28",
    geography: "house_of_rep_district",
    population: 22581
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-29",
    name: "State House District 29",
    geography: "house_of_rep_district",
    population: 24632
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-11",
    name: "State House District 11",
    geography: "house_of_rep_district",
    population: 22401
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-14",
    name: "State House District 14",
    geography: "house_of_rep_district",
    population: 26190
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-15",
    name: "State House District 15",
    geography: "house_of_rep_district",
    population: 24978
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-17",
    name: "State House District 17",
    geography: "house_of_rep_district",
    population: 23703
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-20",
    name: "State House District 20",
    geography: "house_of_rep_district",
    population: 28172
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-32",
    name: "State House District 32",
    geography: "house_of_rep_district",
    population: 25603
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-35",
    name: "State House District 35",
    geography: "house_of_rep_district",
    population: 24646
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-38",
    name: "State House District 38",
    geography: "house_of_rep_district",
    population: 27703
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-41",
    name: "State House District 41",
    geography: "house_of_rep_district",
    population: 22522
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-34",
    name: "State House District 34",
    geography: "house_of_rep_district",
    population: 24490
  },
  {
    __typename: "MhcLocation",
    id: "state-house-district-36",
    name: "State House District 36",
    geography: "house_of_rep_district",
    population: 23397
  },
  {
    __typename: "MhcLocation",
    id: "riverside",
    name: "Riverside",
    geography: "neighborhood",
    population: 1176
  },
  {
    __typename: "MhcLocation",
    id: "price-s-run-ne",
    name: "Price's Run / NE",
    geography: "neighborhood",
    population: 6054
  },
  {
    __typename: "MhcLocation",
    id: "downtown",
    name: "Downtown",
    geography: "neighborhood",
    population: 1294
  },
  {
    __typename: "MhcLocation",
    id: "9th-ward",
    name: "9th Ward",
    geography: "neighborhood",
    population: 8360
  },
  {
    __typename: "MhcLocation",
    id: "trolley-square",
    name: "Trolley Square",
    geography: "neighborhood",
    population: 3585
  },
  {
    __typename: "MhcLocation",
    id: "southbridge",
    name: "Southbridge",
    geography: "neighborhood",
    population: 1529
  },
  {
    __typename: "MhcLocation",
    id: "forty-acres",
    name: "Forty Acres",
    geography: "neighborhood",
    population: 1079
  },
  {
    __typename: "MhcLocation",
    id: "east-side",
    name: "East Side",
    geography: "neighborhood",
    population: 6181
  },
  {
    __typename: "MhcLocation",
    id: "bayard-square",
    name: "Bayard Square",
    geography: "neighborhood",
    population: 2392
  },
  {
    __typename: "MhcLocation",
    id: "hedgeville",
    name: "Hedgeville",
    geography: "neighborhood",
    population: 3740
  },
  {
    __typename: "MhcLocation",
    id: "westmoreland",
    name: "Westmoreland",
    geography: "neighborhood",
    population: 51
  },
  {
    __typename: "MhcLocation",
    id: "union-park-gardens",
    name: "Union Park Gardens",
    geography: "neighborhood",
    population: 950
  },
  {
    __typename: "MhcLocation",
    id: "canby-park",
    name: "Canby Park",
    geography: "neighborhood",
    population: 832
  },
  {
    __typename: "MhcLocation",
    id: "wawaset",
    name: "Wawaset",
    geography: "neighborhood",
    population: 553
  },
  {
    __typename: "MhcLocation",
    id: "mack-park-greenhill-ave-area",
    name: "Mack Park / Greenhill Ave. Area",
    geography: "neighborhood",
    population: 1590
  },
  {
    __typename: "MhcLocation",
    id: "flats",
    name: "Flats",
    geography: "neighborhood",
    population: 1059
  },
  {
    __typename: "MhcLocation",
    id: "cool-spring-titlton-park",
    name: "Cool Spring / Titlton Park",
    geography: "neighborhood",
    population: 2979
  },
  {
    __typename: "MhcLocation",
    id: "little-italy",
    name: "Little Italy",
    geography: "neighborhood",
    population: 1356
  },
  {
    __typename: "MhcLocation",
    id: "bancroft-parkway",
    name: "Bancroft Parkway",
    geography: "neighborhood",
    population: 281
  },
  {
    __typename: "MhcLocation",
    id: "washington-heights",
    name: "Washington Heights",
    geography: "neighborhood",
    population: 1034
  },
  {
    __typename: "MhcLocation",
    id: "brandywine-village",
    name: "Brandywine Village",
    geography: "neighborhood",
    population: 1287
  },
  {
    __typename: "MhcLocation",
    id: "harlan",
    name: "Harlan",
    geography: "neighborhood",
    population: 2450
  },
  {
    __typename: "MhcLocation",
    id: "highlands",
    name: "Highlands",
    geography: "neighborhood",
    population: 2578
  },
  {
    __typename: "MhcLocation",
    id: "brandywine-hills",
    name: "Brandywine Hills",
    geography: "neighborhood",
    population: 865
  },
  {
    __typename: "MhcLocation",
    id: "eastlake",
    name: "Eastlake",
    geography: "neighborhood",
    population: 1266
  },
  {
    __typename: "MhcLocation",
    id: "midtown-brandywine",
    name: "Midtown Brandywine",
    geography: "neighborhood",
    population: 424
  },
  {
    __typename: "MhcLocation",
    id: "hilltop",
    name: "Hilltop",
    geography: "neighborhood",
    population: 5903
  },
  {
    __typename: "MhcLocation",
    id: "west-center-city",
    name: "West Center City",
    geography: "neighborhood",
    population: 4013
  },
  {
    __typename: "MhcLocation",
    id: "trinity-vicinity",
    name: "Trinity Vicinity",
    geography: "neighborhood",
    population: 652
  },
  {
    __typename: "MhcLocation",
    id: "happy-valley",
    name: "Happy Valley",
    geography: "neighborhood",
    population: 155
  },
  {
    __typename: "MhcLocation",
    id: "eastlawn",
    name: "Eastlawn",
    geography: "neighborhood",
    population: 555
  },
  {
    __typename: "MhcLocation",
    id: "browntown",
    name: "Browntown",
    geography: "neighborhood",
    population: 2612
  },
  {
    __typename: "MhcLocation",
    id: "triangle",
    name: "Triangle",
    geography: "neighborhood",
    population: 1777
  },
  {
    __typename: "MhcLocation",
    id: "riverfront",
    name: "Riverfront",
    geography: "neighborhood",
    population: 579
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-110",
    name: "Block Group 1 in Tract 110.0",
    geography: "census_block_group",
    population: 874
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-135-05",
    name: "Block Group 2 in Tract 135.05",
    geography: "census_block_group",
    population: 2675
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-414",
    name: "Block Group 2 in Tract 414.0",
    geography: "census_block_group",
    population: 1216
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-402-02",
    name: "Block Group 1 in Tract 402.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-512-03",
    name: "Block Group 1 in Tract 512.03",
    geography: "census_block_group",
    population: 499
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-512-03",
    name: "Block Group 2 in Tract 512.03",
    geography: "census_block_group",
    population: 84
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-503-01",
    name: "Block Group 2 in Tract 503.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-517-01",
    name: "Block Group 2 in Tract 517.01",
    geography: "census_block_group",
    population: 2647
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-26",
    name: "Block Group 3 in Tract 26.0",
    geography: "census_block_group",
    population: 938
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-513-06",
    name: "Block Group 2 in Tract 513.06",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-30-02",
    name: "Block Group 1 in Tract 30.02",
    geography: "census_block_group",
    population: 2361
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-510-04",
    name: "Block Group 2 in Tract 510.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-9",
    name: "Block Group 2 in Tract 9.0",
    geography: "census_block_group",
    population: 774
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-23",
    name: "Block Group 1 in Tract 23.0",
    geography: "census_block_group",
    population: 620
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-169-04",
    name: "Block Group 2 in Tract 169.04",
    geography: "census_block_group",
    population: 1478
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-405-02",
    name: "Block Group 2 in Tract 405.02",
    geography: "census_block_group",
    population: 1694
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-417-01",
    name: "Block Group 1 in Tract 417.01",
    geography: "census_block_group",
    population: 1816
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-433",
    name: "Block Group 2 in Tract 433.0",
    geography: "census_block_group",
    population: 1966
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-405-01",
    name: "Block Group 2 in Tract 405.01",
    geography: "census_block_group",
    population: 2473
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-405-01",
    name: "Block Group 1 in Tract 405.01",
    geography: "census_block_group",
    population: 2257
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-402-01",
    name: "Block Group 1 in Tract 402.01",
    geography: "census_block_group",
    population: 2520
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-425",
    name: "Block Group 1 in Tract 425.0",
    geography: "census_block_group",
    population: 1897
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-430",
    name: "Block Group 3 in Tract 430.0",
    geography: "census_block_group",
    population: 1473
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-430",
    name: "Block Group 1 in Tract 430.0",
    geography: "census_block_group",
    population: 1667
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-402-02",
    name: "Block Group 2 in Tract 402.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-126",
    name: "Block Group 2 in Tract 126.0",
    geography: "census_block_group",
    population: 795
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-421",
    name: "Block Group 2 in Tract 421.0",
    geography: "census_block_group",
    population: 2509
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-108",
    name: "Block Group 1 in Tract 108.0",
    geography: "census_block_group",
    population: 1533
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-422-01",
    name: "Block Group 2 in Tract 422.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-417-02",
    name: "Block Group 1 in Tract 417.02",
    geography: "census_block_group",
    population: 2694
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-418-01",
    name: "Block Group 1 in Tract 418.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-05",
    name: "Block Group 2 in Tract 148.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-110",
    name: "Block Group 2 in Tract 110.0",
    geography: "census_block_group",
    population: 1239
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-101-01",
    name: "Block Group 3 in Tract 101.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-119",
    name: "Block Group 1 in Tract 119.0",
    geography: "census_block_group",
    population: 1315
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-23",
    name: "Block Group 2 in Tract 23.0",
    geography: "census_block_group",
    population: 907
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-22",
    name: "Block Group 1 in Tract 22.0",
    geography: "census_block_group",
    population: 1526
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-101-04",
    name: "Block Group 1 in Tract 101.04",
    geography: "census_block_group",
    population: 1987
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-101-04",
    name: "Block Group 2 in Tract 101.04",
    geography: "census_block_group",
    population: 1758
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-158-02",
    name: "Block Group 2 in Tract 158.02",
    geography: "census_block_group",
    population: 1614
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-163-05",
    name: "Block Group 3 in Tract 163.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-511-02",
    name: "Block Group 1 in Tract 511.02",
    geography: "census_block_group",
    population: 372
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-507-04",
    name: "Block Group 2 in Tract 507.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-513-06",
    name: "Block Group 3 in Tract 513.06",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-511-01",
    name: "Block Group 2 in Tract 511.01",
    geography: "census_block_group",
    population: 253
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-510-05",
    name: "Block Group 1 in Tract 510.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-510-07",
    name: "Block Group 3 in Tract 510.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-152",
    name: "Block Group 1 in Tract 152.0",
    geography: "census_block_group",
    population: 1245
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-504-07",
    name: "Block Group 2 in Tract 504.07",
    geography: "census_block_group",
    population: 1450
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-505-03",
    name: "Block Group 2 in Tract 505.03",
    geography: "census_block_group",
    population: 1851
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-149-03",
    name: "Block Group 3 in Tract 149.03",
    geography: "census_block_group",
    population: 2067
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-135-06",
    name: "Block Group 3 in Tract 135.06",
    geography: "census_block_group",
    population: 1247
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-152",
    name: "Block Group 3 in Tract 152.0",
    geography: "census_block_group",
    population: 1025
  },
  {
    __typename: "MhcLocation",
    id: "bg-5-152",
    name: "Block Group 5 in Tract 152.0",
    geography: "census_block_group",
    population: 1643
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-515",
    name: "Block Group 1 in Tract 515.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-513-02",
    name: "Block Group 2 in Tract 513.02",
    geography: "census_block_group",
    population: 2002
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-513-01",
    name: "Block Group 2 in Tract 513.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-149-03",
    name: "Block Group 2 in Tract 149.03",
    geography: "census_block_group",
    population: 0
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-508-03",
    name: "Block Group 3 in Tract 508.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-502",
    name: "Block Group 1 in Tract 502.0",
    geography: "census_block_group",
    population: 1245
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-510-03",
    name: "Block Group 2 in Tract 510.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-506-02",
    name: "Block Group 2 in Tract 506.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-122",
    name: "Block Group 1 in Tract 122.0",
    geography: "census_block_group",
    population: 1037
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-519",
    name: "Block Group 2 in Tract 519.0",
    geography: "census_block_group",
    population: 2661
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-518-02",
    name: "Block Group 2 in Tract 518.02",
    geography: "census_block_group",
    population: 1791
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-123",
    name: "Block Group 2 in Tract 123.0",
    geography: "census_block_group",
    population: 1087
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-163-02",
    name: "Block Group 3 in Tract 163.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-149-03",
    name: "Block Group 4 in Tract 149.03",
    geography: "census_block_group",
    population: 1893
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-04",
    name: "Block Group 1 in Tract 149.04",
    geography: "census_block_group",
    population: 2760
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-127",
    name: "Block Group 1 in Tract 127.0",
    geography: "census_block_group",
    population: 1413
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-16",
    name: "Block Group 3 in Tract 16.0",
    geography: "census_block_group",
    population: 719
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-15",
    name: "Block Group 2 in Tract 15.0",
    geography: "census_block_group",
    population: 1746
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-125",
    name: "Block Group 4 in Tract 125.0",
    geography: "census_block_group",
    population: 364
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-125",
    name: "Block Group 2 in Tract 125.0",
    geography: "census_block_group",
    population: 1214
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-6-02",
    name: "Block Group 1 in Tract 6.02",
    geography: "census_block_group",
    population: 1306
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-6-02",
    name: "Block Group 2 in Tract 6.02",
    geography: "census_block_group",
    population: 940
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-6-02",
    name: "Block Group 3 in Tract 6.02",
    geography: "census_block_group",
    population: 478
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-144-03",
    name: "Block Group 3 in Tract 144.03",
    geography: "census_block_group",
    population: 2276
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-147-03",
    name: "Block Group 3 in Tract 147.03",
    geography: "census_block_group",
    population: 2369
  },
  {
    __typename: "MhcLocation",
    id: "bg-5-2",
    name: "Block Group 5 in Tract 2.0",
    geography: "census_block_group",
    population: 1037
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-07",
    name: "Block Group 1 in Tract 136.07",
    geography: "census_block_group",
    population: 2084
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-117",
    name: "Block Group 3 in Tract 117.0",
    geography: "census_block_group",
    population: 1173
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-139-01",
    name: "Block Group 1 in Tract 139.01",
    geography: "census_block_group",
    population: 1309
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-112-03",
    name: "Block Group 3 in Tract 112.03",
    geography: "census_block_group",
    population: 2056
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-03",
    name: "Block Group 1 in Tract 112.03",
    geography: "census_block_group",
    population: 2059
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-02",
    name: "Block Group 2 in Tract 112.02",
    geography: "census_block_group",
    population: 967
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-160",
    name: "Block Group 1 in Tract 160.0",
    geography: "census_block_group",
    population: 1490
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-142",
    name: "Block Group 1 in Tract 142.0",
    geography: "census_block_group",
    population: 1761
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-432-02",
    name: "Block Group 3 in Tract 432.02",
    geography: "census_block_group",
    population: 1136
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-432-02",
    name: "Block Group 2 in Tract 432.02",
    geography: "census_block_group",
    population: 767
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-432-02",
    name: "Block Group 4 in Tract 432.02",
    geography: "census_block_group",
    population: 1164
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-405-02",
    name: "Block Group 1 in Tract 405.02",
    geography: "census_block_group",
    population: 953
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-433",
    name: "Block Group 1 in Tract 433.0",
    geography: "census_block_group",
    population: 2789
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-434",
    name: "Block Group 2 in Tract 434.0",
    geography: "census_block_group",
    population: 3712
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-431",
    name: "Block Group 2 in Tract 431.0",
    geography: "census_block_group",
    population: 1545
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-434",
    name: "Block Group 1 in Tract 434.0",
    geography: "census_block_group",
    population: 1839
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-417-01",
    name: "Block Group 2 in Tract 417.01",
    geography: "census_block_group",
    population: 3991
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-402-01",
    name: "Block Group 2 in Tract 402.01",
    geography: "census_block_group",
    population: 2245
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-418-02",
    name: "Block Group 1 in Tract 418.02",
    geography: "census_block_group",
    population: 4064
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-418-02",
    name: "Block Group 2 in Tract 418.02",
    geography: "census_block_group",
    population: 2015
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-431",
    name: "Block Group 1 in Tract 431.0",
    geography: "census_block_group",
    population: 922
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-414",
    name: "Block Group 1 in Tract 414.0",
    geography: "census_block_group",
    population: 2173
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-425",
    name: "Block Group 2 in Tract 425.0",
    geography: "census_block_group",
    population: 2572
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-401",
    name: "Block Group 1 in Tract 401.0",
    geography: "census_block_group",
    population: 3036
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-401",
    name: "Block Group 2 in Tract 401.0",
    geography: "census_block_group",
    population: 2503
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-401",
    name: "Block Group 3 in Tract 401.0",
    geography: "census_block_group",
    population: 1991
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-430",
    name: "Block Group 2 in Tract 430.0",
    geography: "census_block_group",
    population: 1876
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-429",
    name: "Block Group 2 in Tract 429.0",
    geography: "census_block_group",
    population: 2784
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-429",
    name: "Block Group 1 in Tract 429.0",
    geography: "census_block_group",
    population: 2430
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-428",
    name: "Block Group 1 in Tract 428.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-402-03",
    name: "Block Group 1 in Tract 402.03",
    geography: "census_block_group",
    population: 3757
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-402-03",
    name: "Block Group 2 in Tract 402.03",
    geography: "census_block_group",
    population: 1552
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-418-01",
    name: "Block Group 2 in Tract 418.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-421",
    name: "Block Group 1 in Tract 421.0",
    geography: "census_block_group",
    population: 1811
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-413",
    name: "Block Group 1 in Tract 413.0",
    geography: "census_block_group",
    population: 2056
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-412",
    name: "Block Group 2 in Tract 412.0",
    geography: "census_block_group",
    population: 2353
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-412",
    name: "Block Group 1 in Tract 412.0",
    geography: "census_block_group",
    population: 1776
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-411",
    name: "Block Group 2 in Tract 411.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-428",
    name: "Block Group 2 in Tract 428.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-428",
    name: "Block Group 3 in Tract 428.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-422-01",
    name: "Block Group 1 in Tract 422.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-422-01",
    name: "Block Group 3 in Tract 422.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-409",
    name: "Block Group 1 in Tract 409.0",
    geography: "census_block_group",
    population: 2373
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-419",
    name: "Block Group 1 in Tract 419.0",
    geography: "census_block_group",
    population: 2846
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-419",
    name: "Block Group 2 in Tract 419.0",
    geography: "census_block_group",
    population: 2347
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-417-02",
    name: "Block Group 2 in Tract 417.02",
    geography: "census_block_group",
    population: 2082
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-416",
    name: "Block Group 1 in Tract 416.0",
    geography: "census_block_group",
    population: 2167
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-415",
    name: "Block Group 2 in Tract 415.0",
    geography: "census_block_group",
    population: 1460
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-415",
    name: "Block Group 1 in Tract 415.0",
    geography: "census_block_group",
    population: 3070
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-411",
    name: "Block Group 1 in Tract 411.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-410",
    name: "Block Group 2 in Tract 410.0",
    geography: "census_block_group",
    population: 3922
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-410",
    name: "Block Group 1 in Tract 410.0",
    geography: "census_block_group",
    population: 2796
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-119",
    name: "Block Group 3 in Tract 119.0",
    geography: "census_block_group",
    population: 810
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-119",
    name: "Block Group 2 in Tract 119.0",
    geography: "census_block_group",
    population: 874
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-126",
    name: "Block Group 1 in Tract 126.0",
    geography: "census_block_group",
    population: 947
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-422-02",
    name: "Block Group 3 in Tract 422.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-422-02",
    name: "Block Group 2 in Tract 422.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-422-02",
    name: "Block Group 1 in Tract 422.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-418-01",
    name: "Block Group 3 in Tract 418.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-05",
    name: "Block Group 1 in Tract 148.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-148-05",
    name: "Block Group 4 in Tract 148.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-148-05",
    name: "Block Group 3 in Tract 148.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-148-08",
    name: "Block Group 4 in Tract 148.08",
    geography: "census_block_group",
    population: 2053
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-08",
    name: "Block Group 1 in Tract 148.08",
    geography: "census_block_group",
    population: 1327
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-08",
    name: "Block Group 2 in Tract 148.08",
    geography: "census_block_group",
    population: 990
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-148-08",
    name: "Block Group 3 in Tract 148.08",
    geography: "census_block_group",
    population: 2011
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-420",
    name: "Block Group 1 in Tract 420.0",
    geography: "census_block_group",
    population: 1459
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-420",
    name: "Block Group 2 in Tract 420.0",
    geography: "census_block_group",
    population: 2000
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-110",
    name: "Block Group 3 in Tract 110.0",
    geography: "census_block_group",
    population: 897
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-109",
    name: "Block Group 3 in Tract 109.0",
    geography: "census_block_group",
    population: 640
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-109",
    name: "Block Group 2 in Tract 109.0",
    geography: "census_block_group",
    population: 1252
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-109",
    name: "Block Group 1 in Tract 109.0",
    geography: "census_block_group",
    population: 612
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-108",
    name: "Block Group 3 in Tract 108.0",
    geography: "census_block_group",
    population: 696
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-108",
    name: "Block Group 2 in Tract 108.0",
    geography: "census_block_group",
    population: 2424
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-166-08",
    name: "Block Group 1 in Tract 166.08",
    geography: "census_block_group",
    population: 1221
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-149-09",
    name: "Block Group 4 in Tract 149.09",
    geography: "census_block_group",
    population: 915
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-149-09",
    name: "Block Group 3 in Tract 149.09",
    geography: "census_block_group",
    population: 808
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-126",
    name: "Block Group 3 in Tract 126.0",
    geography: "census_block_group",
    population: 1254
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-138",
    name: "Block Group 1 in Tract 138.0",
    geography: "census_block_group",
    population: 2192
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-138",
    name: "Block Group 2 in Tract 138.0",
    geography: "census_block_group",
    population: 1210
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-138",
    name: "Block Group 3 in Tract 138.0",
    geography: "census_block_group",
    population: 2223
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-26",
    name: "Block Group 2 in Tract 26.0",
    geography: "census_block_group",
    population: 1036
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-26",
    name: "Block Group 4 in Tract 26.0",
    geography: "census_block_group",
    population: 1157
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-104",
    name: "Block Group 2 in Tract 104.0",
    geography: "census_block_group",
    population: 967
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-104",
    name: "Block Group 3 in Tract 104.0",
    geography: "census_block_group",
    population: 2036
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-104",
    name: "Block Group 4 in Tract 104.0",
    geography: "census_block_group",
    population: 985
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-103",
    name: "Block Group 3 in Tract 103.0",
    geography: "census_block_group",
    population: 912
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-103",
    name: "Block Group 2 in Tract 103.0",
    geography: "census_block_group",
    population: 1447
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-103",
    name: "Block Group 1 in Tract 103.0",
    geography: "census_block_group",
    population: 1302
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-154",
    name: "Block Group 1 in Tract 154.0",
    geography: "census_block_group",
    population: 1258
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-154",
    name: "Block Group 2 in Tract 154.0",
    geography: "census_block_group",
    population: 1554
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-102",
    name: "Block Group 2 in Tract 102.0",
    geography: "census_block_group",
    population: 936
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-102",
    name: "Block Group 1 in Tract 102.0",
    geography: "census_block_group",
    population: 1203
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-101-01",
    name: "Block Group 1 in Tract 101.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-101-01",
    name: "Block Group 2 in Tract 101.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-129",
    name: "Block Group 3 in Tract 129.0",
    geography: "census_block_group",
    population: 1631
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-129",
    name: "Block Group 2 in Tract 129.0",
    geography: "census_block_group",
    population: 1150
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-129",
    name: "Block Group 1 in Tract 129.0",
    geography: "census_block_group",
    population: 1697
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-12",
    name: "Block Group 3 in Tract 136.12",
    geography: "census_block_group",
    population: 2163
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-12",
    name: "Block Group 2 in Tract 136.12",
    geography: "census_block_group",
    population: 1286
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-12",
    name: "Block Group 1 in Tract 136.12",
    geography: "census_block_group",
    population: 2355
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-13",
    name: "Block Group 3 in Tract 136.13",
    geography: "census_block_group",
    population: 2517
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-13",
    name: "Block Group 2 in Tract 136.13",
    geography: "census_block_group",
    population: 1569
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-13",
    name: "Block Group 1 in Tract 136.13",
    geography: "census_block_group",
    population: 1616
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-168-01",
    name: "Block Group 1 in Tract 168.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-168-01",
    name: "Block Group 2 in Tract 168.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-22",
    name: "Block Group 3 in Tract 22.0",
    geography: "census_block_group",
    population: 596
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-22",
    name: "Block Group 2 in Tract 22.0",
    geography: "census_block_group",
    population: 693
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-21",
    name: "Block Group 2 in Tract 21.0",
    geography: "census_block_group",
    population: 905
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-21",
    name: "Block Group 1 in Tract 21.0",
    geography: "census_block_group",
    population: 843
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-9801",
    name: "Block Group 1 in Tract 9801.0",
    geography: "census_block_group",
    population: 0
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-29",
    name: "Block Group 3 in Tract 29.0",
    geography: "census_block_group",
    population: 1407
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-29",
    name: "Block Group 4 in Tract 29.0",
    geography: "census_block_group",
    population: 566
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-29",
    name: "Block Group 1 in Tract 29.0",
    geography: "census_block_group",
    population: 816
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-29",
    name: "Block Group 2 in Tract 29.0",
    geography: "census_block_group",
    population: 769
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-507-06",
    name: "Block Group 2 in Tract 507.06",
    geography: "census_block_group",
    population: 1379
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-507-06",
    name: "Block Group 3 in Tract 507.06",
    geography: "census_block_group",
    population: 85
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-512-01",
    name: "Block Group 2 in Tract 512.01",
    geography: "census_block_group",
    population: 735
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-28",
    name: "Block Group 1 in Tract 28.0",
    geography: "census_block_group",
    population: 1175
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-28",
    name: "Block Group 2 in Tract 28.0",
    geography: "census_block_group",
    population: 711
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-148-09",
    name: "Block Group 3 in Tract 148.09",
    geography: "census_block_group",
    population: 1409
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-09",
    name: "Block Group 1 in Tract 148.09",
    geography: "census_block_group",
    population: 2520
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-09",
    name: "Block Group 2 in Tract 148.09",
    geography: "census_block_group",
    population: 2506
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-08",
    name: "Block Group 1 in Tract 149.08",
    geography: "census_block_group",
    population: 1385
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-169-04",
    name: "Block Group 1 in Tract 169.04",
    geography: "census_block_group",
    population: 3996
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-168-04",
    name: "Block Group 2 in Tract 168.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-168-04",
    name: "Block Group 1 in Tract 168.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-166-08",
    name: "Block Group 2 in Tract 166.08",
    geography: "census_block_group",
    population: 5289
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-507-06",
    name: "Block Group 1 in Tract 507.06",
    geography: "census_block_group",
    population: 914
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-158-02",
    name: "Block Group 1 in Tract 158.02",
    geography: "census_block_group",
    population: 742
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-512-03",
    name: "Block Group 4 in Tract 512.03",
    geography: "census_block_group",
    population: 224
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-512-03",
    name: "Block Group 3 in Tract 512.03",
    geography: "census_block_group",
    population: 88
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-512-02",
    name: "Block Group 1 in Tract 512.02",
    geography: "census_block_group",
    population: 946
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-512-02",
    name: "Block Group 2 in Tract 512.02",
    geography: "census_block_group",
    population: 174
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-512-04",
    name: "Block Group 1 in Tract 512.04",
    geography: "census_block_group",
    population: 252
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-512-04",
    name: "Block Group 3 in Tract 512.04",
    geography: "census_block_group",
    population: 102
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-512-04",
    name: "Block Group 4 in Tract 512.04",
    geography: "census_block_group",
    population: 263
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-163-05",
    name: "Block Group 2 in Tract 163.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-163-05",
    name: "Block Group 1 in Tract 163.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-164-04",
    name: "Block Group 1 in Tract 164.04",
    geography: "census_block_group",
    population: 1116
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-26",
    name: "Block Group 1 in Tract 26.0",
    geography: "census_block_group",
    population: 654
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-23",
    name: "Block Group 3 in Tract 23.0",
    geography: "census_block_group",
    population: 963
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-164-04",
    name: "Block Group 2 in Tract 164.04",
    geography: "census_block_group",
    population: 922
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-164-04",
    name: "Block Group 3 in Tract 164.04",
    geography: "census_block_group",
    population: 840
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-10",
    name: "Block Group 2 in Tract 148.1",
    geography: "census_block_group",
    population: 2166
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-10",
    name: "Block Group 1 in Tract 148.1",
    geography: "census_block_group",
    population: 2703
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-148-10",
    name: "Block Group 3 in Tract 148.1",
    geography: "census_block_group",
    population: 2242
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-511-02",
    name: "Block Group 2 in Tract 511.02",
    geography: "census_block_group",
    population: 675
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-511-02",
    name: "Block Group 3 in Tract 511.02",
    geography: "census_block_group",
    population: 402
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-510-06",
    name: "Block Group 2 in Tract 510.06",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-510-06",
    name: "Block Group 1 in Tract 510.06",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-512-01",
    name: "Block Group 1 in Tract 512.01",
    geography: "census_block_group",
    population: 692
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-155-02",
    name: "Block Group 1 in Tract 155.02",
    geography: "census_block_group",
    population: 2109
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-155-02",
    name: "Block Group 2 in Tract 155.02",
    geography: "census_block_group",
    population: 783
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-512-04",
    name: "Block Group 2 in Tract 512.04",
    geography: "census_block_group",
    population: 63
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-507-04",
    name: "Block Group 1 in Tract 507.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-507-03",
    name: "Block Group 2 in Tract 507.03",
    geography: "census_block_group",
    population: 1155
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-507-03",
    name: "Block Group 1 in Tract 507.03",
    geography: "census_block_group",
    population: 1088
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-08",
    name: "Block Group 1 in Tract 504.08",
    geography: "census_block_group",
    population: 2937
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-504-08",
    name: "Block Group 2 in Tract 504.08",
    geography: "census_block_group",
    population: 1788
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-504-05",
    name: "Block Group 2 in Tract 504.05",
    geography: "census_block_group",
    population: 2331
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-05",
    name: "Block Group 1 in Tract 504.05",
    geography: "census_block_group",
    population: 2283
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-513-06",
    name: "Block Group 1 in Tract 513.06",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-513-05",
    name: "Block Group 2 in Tract 513.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-513-05",
    name: "Block Group 1 in Tract 513.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-513-05",
    name: "Block Group 3 in Tract 513.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-512-05",
    name: "Block Group 2 in Tract 512.05",
    geography: "census_block_group",
    population: 372
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-512-05",
    name: "Block Group 1 in Tract 512.05",
    geography: "census_block_group",
    population: 388
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-139-03",
    name: "Block Group 1 in Tract 139.03",
    geography: "census_block_group",
    population: 2303
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-139-03",
    name: "Block Group 2 in Tract 139.03",
    geography: "census_block_group",
    population: 1053
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-15",
    name: "Block Group 3 in Tract 136.15",
    geography: "census_block_group",
    population: 1418
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-15",
    name: "Block Group 1 in Tract 136.15",
    geography: "census_block_group",
    population: 1714
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-15",
    name: "Block Group 2 in Tract 136.15",
    geography: "census_block_group",
    population: 354
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-501-04",
    name: "Block Group 1 in Tract 501.04",
    geography: "census_block_group",
    population: 1299
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-501-04",
    name: "Block Group 3 in Tract 501.04",
    geography: "census_block_group",
    population: 1108
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-07",
    name: "Block Group 1 in Tract 504.07",
    geography: "census_block_group",
    population: 1795
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-14",
    name: "Block Group 2 in Tract 136.14",
    geography: "census_block_group",
    population: 2034
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-14",
    name: "Block Group 1 in Tract 136.14",
    geography: "census_block_group",
    population: 656
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-19-02",
    name: "Block Group 2 in Tract 19.02",
    geography: "census_block_group",
    population: 1514
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-19-02",
    name: "Block Group 1 in Tract 19.02",
    geography: "census_block_group",
    population: 617
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-30-02",
    name: "Block Group 2 in Tract 30.02",
    geography: "census_block_group",
    population: 1232
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-30-02",
    name: "Block Group 3 in Tract 30.02",
    geography: "census_block_group",
    population: 2416
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-105-02",
    name: "Block Group 3 in Tract 105.02",
    geography: "census_block_group",
    population: 803
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-105-02",
    name: "Block Group 1 in Tract 105.02",
    geography: "census_block_group",
    population: 846
  },
  {
    __typename: "MhcLocation",
    id: "bg-5-105-02",
    name: "Block Group 5 in Tract 105.02",
    geography: "census_block_group",
    population: 1236
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-105-02",
    name: "Block Group 2 in Tract 105.02",
    geography: "census_block_group",
    population: 1109
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-105-02",
    name: "Block Group 4 in Tract 105.02",
    geography: "census_block_group",
    population: 1861
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-511-01",
    name: "Block Group 3 in Tract 511.01",
    geography: "census_block_group",
    population: 450
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-511-01",
    name: "Block Group 1 in Tract 511.01",
    geography: "census_block_group",
    population: 204
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-507-05",
    name: "Block Group 3 in Tract 507.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-507-05",
    name: "Block Group 1 in Tract 507.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-507-05",
    name: "Block Group 4 in Tract 507.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-507-05",
    name: "Block Group 2 in Tract 507.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-510-05",
    name: "Block Group 3 in Tract 510.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-511-03",
    name: "Block Group 2 in Tract 511.03",
    geography: "census_block_group",
    population: 444
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-511-03",
    name: "Block Group 3 in Tract 511.03",
    geography: "census_block_group",
    population: 79
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-511-03",
    name: "Block Group 1 in Tract 511.03",
    geography: "census_block_group",
    population: 782
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-510-07",
    name: "Block Group 2 in Tract 510.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-510-07",
    name: "Block Group 1 in Tract 510.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-139-04",
    name: "Block Group 3 in Tract 139.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-139-04",
    name: "Block Group 4 in Tract 139.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-139-04",
    name: "Block Group 2 in Tract 139.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-06",
    name: "Block Group 1 in Tract 149.06",
    geography: "census_block_group",
    population: 1703
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-149-06",
    name: "Block Group 2 in Tract 149.06",
    geography: "census_block_group",
    population: 1145
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-504-07",
    name: "Block Group 3 in Tract 504.07",
    geography: "census_block_group",
    population: 2820
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-501-05",
    name: "Block Group 1 in Tract 501.05",
    geography: "census_block_group",
    population: 3125
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-510-05",
    name: "Block Group 2 in Tract 510.05",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-505-04",
    name: "Block Group 1 in Tract 505.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-505-04",
    name: "Block Group 3 in Tract 505.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-505-04",
    name: "Block Group 2 in Tract 505.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-504-06",
    name: "Block Group 2 in Tract 504.06",
    geography: "census_block_group",
    population: 1474
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-504-06",
    name: "Block Group 3 in Tract 504.06",
    geography: "census_block_group",
    population: 2078
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-505-03",
    name: "Block Group 1 in Tract 505.03",
    geography: "census_block_group",
    population: 4369
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-509-01",
    name: "Block Group 1 in Tract 509.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-510-04",
    name: "Block Group 1 in Tract 510.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-509-02",
    name: "Block Group 3 in Tract 509.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-509-02",
    name: "Block Group 1 in Tract 509.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-509-02",
    name: "Block Group 2 in Tract 509.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-501-05",
    name: "Block Group 2 in Tract 501.05",
    geography: "census_block_group",
    population: 2079
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-107-02",
    name: "Block Group 2 in Tract 107.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-107-02",
    name: "Block Group 3 in Tract 107.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-508-03",
    name: "Block Group 4 in Tract 508.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-504-01",
    name: "Block Group 2 in Tract 504.01",
    geography: "census_block_group",
    population: 1446
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-135-06",
    name: "Block Group 2 in Tract 135.06",
    geography: "census_block_group",
    population: 2326
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-135-06",
    name: "Block Group 1 in Tract 135.06",
    geography: "census_block_group",
    population: 761
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-135-05",
    name: "Block Group 1 in Tract 135.05",
    geography: "census_block_group",
    population: 537
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-149-07",
    name: "Block Group 3 in Tract 149.07",
    geography: "census_block_group",
    population: 1217
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-149-07",
    name: "Block Group 2 in Tract 149.07",
    geography: "census_block_group",
    population: 1702
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-07",
    name: "Block Group 1 in Tract 149.07",
    geography: "census_block_group",
    population: 2013
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-139-04",
    name: "Block Group 1 in Tract 139.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-503-01",
    name: "Block Group 3 in Tract 503.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-501-01",
    name: "Block Group 2 in Tract 501.01",
    geography: "census_block_group",
    population: 1842
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-501-01",
    name: "Block Group 1 in Tract 501.01",
    geography: "census_block_group",
    population: 2297
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-515",
    name: "Block Group 4 in Tract 515.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-152",
    name: "Block Group 4 in Tract 152.0",
    geography: "census_block_group",
    population: 1107
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-152",
    name: "Block Group 2 in Tract 152.0",
    geography: "census_block_group",
    population: 1090
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-503-01",
    name: "Block Group 1 in Tract 503.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-517-01",
    name: "Block Group 1 in Tract 517.01",
    geography: "census_block_group",
    population: 1357
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-01",
    name: "Block Group 1 in Tract 504.01",
    geography: "census_block_group",
    population: 2060
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-507-01",
    name: "Block Group 1 in Tract 507.01",
    geography: "census_block_group",
    population: 3015
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-515",
    name: "Block Group 3 in Tract 515.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-515",
    name: "Block Group 2 in Tract 515.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-514",
    name: "Block Group 2 in Tract 514.0",
    geography: "census_block_group",
    population: 1326
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-514",
    name: "Block Group 1 in Tract 514.0",
    geography: "census_block_group",
    population: 2094
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-513-02",
    name: "Block Group 1 in Tract 513.02",
    geography: "census_block_group",
    population: 2192
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-513-03",
    name: "Block Group 1 in Tract 513.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-513-03",
    name: "Block Group 3 in Tract 513.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-513-03",
    name: "Block Group 2 in Tract 513.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-513-01",
    name: "Block Group 1 in Tract 513.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-513-01",
    name: "Block Group 3 in Tract 513.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-508-02",
    name: "Block Group 2 in Tract 508.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-508-02",
    name: "Block Group 1 in Tract 508.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-508-03",
    name: "Block Group 1 in Tract 508.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-508-03",
    name: "Block Group 2 in Tract 508.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-507-01",
    name: "Block Group 2 in Tract 507.01",
    geography: "census_block_group",
    population: 1630
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-502",
    name: "Block Group 2 in Tract 502.0",
    geography: "census_block_group",
    population: 3074
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-501-03",
    name: "Block Group 2 in Tract 501.03",
    geography: "census_block_group",
    population: 2225
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-506-01",
    name: "Block Group 2 in Tract 506.01",
    geography: "census_block_group",
    population: 2466
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-518-01",
    name: "Block Group 2 in Tract 518.01",
    geography: "census_block_group",
    population: 2846
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-03",
    name: "Block Group 1 in Tract 504.03",
    geography: "census_block_group",
    population: 3412
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-166-04",
    name: "Block Group 2 in Tract 166.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-166-04",
    name: "Block Group 1 in Tract 166.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-166-04",
    name: "Block Group 3 in Tract 166.04",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-501-03",
    name: "Block Group 1 in Tract 501.03",
    geography: "census_block_group",
    population: 3020
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-506-02",
    name: "Block Group 3 in Tract 506.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-506-02",
    name: "Block Group 1 in Tract 506.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-506-01",
    name: "Block Group 1 in Tract 506.01",
    geography: "census_block_group",
    population: 2869
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-518-01",
    name: "Block Group 1 in Tract 518.01",
    geography: "census_block_group",
    population: 2393
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-510-03",
    name: "Block Group 1 in Tract 510.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-510-03",
    name: "Block Group 3 in Tract 510.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-519",
    name: "Block Group 1 in Tract 519.0",
    geography: "census_block_group",
    population: 1697
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-518-02",
    name: "Block Group 1 in Tract 518.02",
    geography: "census_block_group",
    population: 2348
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-517-02",
    name: "Block Group 2 in Tract 517.02",
    geography: "census_block_group",
    population: 1982
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-517-02",
    name: "Block Group 3 in Tract 517.02",
    geography: "census_block_group",
    population: 2356
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-517-02",
    name: "Block Group 1 in Tract 517.02",
    geography: "census_block_group",
    population: 1637
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-124",
    name: "Block Group 4 in Tract 124.0",
    geography: "census_block_group",
    population: 1115
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-163-02",
    name: "Block Group 2 in Tract 163.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-163-02",
    name: "Block Group 1 in Tract 163.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-163-01",
    name: "Block Group 2 in Tract 163.01",
    geography: "census_block_group",
    population: 2097
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-163-01",
    name: "Block Group 1 in Tract 163.01",
    geography: "census_block_group",
    population: 1668
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-166-02",
    name: "Block Group 2 in Tract 166.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-166-02",
    name: "Block Group 1 in Tract 166.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-127",
    name: "Block Group 3 in Tract 127.0",
    geography: "census_block_group",
    population: 819
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-127",
    name: "Block Group 2 in Tract 127.0",
    geography: "census_block_group",
    population: 504
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-508-01",
    name: "Block Group 1 in Tract 508.01",
    geography: "census_block_group",
    population: 1698
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-508-01",
    name: "Block Group 2 in Tract 508.01",
    geography: "census_block_group",
    population: 2207
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-503-02",
    name: "Block Group 1 in Tract 503.02",
    geography: "census_block_group",
    population: 3253
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-503-02",
    name: "Block Group 2 in Tract 503.02",
    geography: "census_block_group",
    population: 2262
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-505-01",
    name: "Block Group 2 in Tract 505.01",
    geography: "census_block_group",
    population: 2953
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-505-01",
    name: "Block Group 1 in Tract 505.01",
    geography: "census_block_group",
    population: 1602
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-03",
    name: "Block Group 1 in Tract 149.03",
    geography: "census_block_group",
    population: 566
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-149-04",
    name: "Block Group 2 in Tract 149.04",
    geography: "census_block_group",
    population: 2657
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-159",
    name: "Block Group 1 in Tract 159.0",
    geography: "census_block_group",
    population: 818
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-159",
    name: "Block Group 3 in Tract 159.0",
    geography: "census_block_group",
    population: 1834
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-159",
    name: "Block Group 2 in Tract 159.0",
    geography: "census_block_group",
    population: 692
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-169-01",
    name: "Block Group 1 in Tract 169.01",
    geography: "census_block_group",
    population: 722
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-169-01",
    name: "Block Group 2 in Tract 169.01",
    geography: "census_block_group",
    population: 1468
  },
  {
    __typename: "MhcLocation",
    id: "bg-5-127",
    name: "Block Group 5 in Tract 127.0",
    geography: "census_block_group",
    population: 496
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-127",
    name: "Block Group 4 in Tract 127.0",
    geography: "census_block_group",
    population: 793
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-123",
    name: "Block Group 1 in Tract 123.0",
    geography: "census_block_group",
    population: 1629
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-16",
    name: "Block Group 1 in Tract 16.0",
    geography: "census_block_group",
    population: 863
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-16",
    name: "Block Group 2 in Tract 16.0",
    geography: "census_block_group",
    population: 686
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-15",
    name: "Block Group 1 in Tract 15.0",
    geography: "census_block_group",
    population: 450
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-147-02",
    name: "Block Group 1 in Tract 147.02",
    geography: "census_block_group",
    population: 1759
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-145-02",
    name: "Block Group 2 in Tract 145.02",
    geography: "census_block_group",
    population: 4655
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-145-02",
    name: "Block Group 1 in Tract 145.02",
    geography: "census_block_group",
    population: 2097
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-144-04",
    name: "Block Group 1 in Tract 144.04",
    geography: "census_block_group",
    population: 3122
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-144-04",
    name: "Block Group 2 in Tract 144.04",
    geography: "census_block_group",
    population: 1632
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-14",
    name: "Block Group 2 in Tract 14.0",
    geography: "census_block_group",
    population: 1251
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-14",
    name: "Block Group 1 in Tract 14.0",
    geography: "census_block_group",
    population: 739
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-13",
    name: "Block Group 1 in Tract 13.0",
    geography: "census_block_group",
    population: 919
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-13",
    name: "Block Group 2 in Tract 13.0",
    geography: "census_block_group",
    population: 640
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-13",
    name: "Block Group 4 in Tract 13.0",
    geography: "census_block_group",
    population: 951
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-13",
    name: "Block Group 3 in Tract 13.0",
    geography: "census_block_group",
    population: 732
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-12",
    name: "Block Group 2 in Tract 12.0",
    geography: "census_block_group",
    population: 738
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-12",
    name: "Block Group 1 in Tract 12.0",
    geography: "census_block_group",
    population: 946
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-11",
    name: "Block Group 1 in Tract 11.0",
    geography: "census_block_group",
    population: 1391
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-11",
    name: "Block Group 3 in Tract 11.0",
    geography: "census_block_group",
    population: 902
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-11",
    name: "Block Group 2 in Tract 11.0",
    geography: "census_block_group",
    population: 890
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-148-07",
    name: "Block Group 3 in Tract 148.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-07",
    name: "Block Group 2 in Tract 148.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-07",
    name: "Block Group 1 in Tract 148.07",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-124",
    name: "Block Group 1 in Tract 124.0",
    geography: "census_block_group",
    population: 1401
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-124",
    name: "Block Group 2 in Tract 124.0",
    geography: "census_block_group",
    population: 687
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-124",
    name: "Block Group 3 in Tract 124.0",
    geography: "census_block_group",
    population: 1010
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-5",
    name: "Block Group 2 in Tract 5.0",
    geography: "census_block_group",
    population: 1027
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-5",
    name: "Block Group 3 in Tract 5.0",
    geography: "census_block_group",
    population: 739
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-4",
    name: "Block Group 2 in Tract 4.0",
    geography: "census_block_group",
    population: 1035
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-4",
    name: "Block Group 3 in Tract 4.0",
    geography: "census_block_group",
    population: 964
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-4",
    name: "Block Group 1 in Tract 4.0",
    geography: "census_block_group",
    population: 1242
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-3",
    name: "Block Group 2 in Tract 3.0",
    geography: "census_block_group",
    population: 1320
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-3",
    name: "Block Group 4 in Tract 3.0",
    geography: "census_block_group",
    population: 1093
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-3",
    name: "Block Group 3 in Tract 3.0",
    geography: "census_block_group",
    population: 964
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-3",
    name: "Block Group 1 in Tract 3.0",
    geography: "census_block_group",
    population: 723
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-121",
    name: "Block Group 2 in Tract 121.0",
    geography: "census_block_group",
    population: 1461
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-121",
    name: "Block Group 3 in Tract 121.0",
    geography: "census_block_group",
    population: 461
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-121",
    name: "Block Group 1 in Tract 121.0",
    geography: "census_block_group",
    population: 1268
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-120",
    name: "Block Group 2 in Tract 120.0",
    geography: "census_block_group",
    population: 2194
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-120",
    name: "Block Group 3 in Tract 120.0",
    geography: "census_block_group",
    population: 1251
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-120",
    name: "Block Group 1 in Tract 120.0",
    geography: "census_block_group",
    population: 923
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-145-01",
    name: "Block Group 1 in Tract 145.01",
    geography: "census_block_group",
    population: 2126
  },
  {
    __typename: "MhcLocation",
    id: "bg-6-125",
    name: "Block Group 6 in Tract 125.0",
    geography: "census_block_group",
    population: 1192
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-125",
    name: "Block Group 3 in Tract 125.0",
    geography: "census_block_group",
    population: 704
  },
  {
    __typename: "MhcLocation",
    id: "bg-5-125",
    name: "Block Group 5 in Tract 125.0",
    geography: "census_block_group",
    population: 591
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-125",
    name: "Block Group 1 in Tract 125.0",
    geography: "census_block_group",
    population: 950
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-122",
    name: "Block Group 4 in Tract 122.0",
    geography: "census_block_group",
    population: 1604
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-122",
    name: "Block Group 2 in Tract 122.0",
    geography: "census_block_group",
    population: 903
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-122",
    name: "Block Group 3 in Tract 122.0",
    geography: "census_block_group",
    population: 1077
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-9",
    name: "Block Group 3 in Tract 9.0",
    geography: "census_block_group",
    population: 832
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-9",
    name: "Block Group 1 in Tract 9.0",
    geography: "census_block_group",
    population: 840
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-6-01",
    name: "Block Group 1 in Tract 6.01",
    geography: "census_block_group",
    population: 1204
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-6-01",
    name: "Block Group 2 in Tract 6.01",
    geography: "census_block_group",
    population: 1232
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-6-01",
    name: "Block Group 3 in Tract 6.01",
    geography: "census_block_group",
    population: 887
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-5",
    name: "Block Group 4 in Tract 5.0",
    geography: "census_block_group",
    population: 929
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-5",
    name: "Block Group 1 in Tract 5.0",
    geography: "census_block_group",
    population: 1197
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-10",
    name: "Block Group 2 in Tract 136.1",
    geography: "census_block_group",
    population: 803
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-115",
    name: "Block Group 2 in Tract 115.0",
    geography: "census_block_group",
    population: 827
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-115",
    name: "Block Group 1 in Tract 115.0",
    geography: "census_block_group",
    population: 1221
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-115",
    name: "Block Group 3 in Tract 115.0",
    geography: "census_block_group",
    population: 909
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-117",
    name: "Block Group 4 in Tract 117.0",
    geography: "census_block_group",
    population: 1279
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-147-06",
    name: "Block Group 2 in Tract 147.06",
    geography: "census_block_group",
    population: 1824
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-147-06",
    name: "Block Group 1 in Tract 147.06",
    geography: "census_block_group",
    population: 919
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-144-03",
    name: "Block Group 2 in Tract 144.03",
    geography: "census_block_group",
    population: 1094
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-144-03",
    name: "Block Group 1 in Tract 144.03",
    geography: "census_block_group",
    population: 1596
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-144-02",
    name: "Block Group 2 in Tract 144.02",
    geography: "census_block_group",
    population: 2228
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-144-02",
    name: "Block Group 1 in Tract 144.02",
    geography: "census_block_group",
    population: 761
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-147-03",
    name: "Block Group 4 in Tract 147.03",
    geography: "census_block_group",
    population: 756
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-147-03",
    name: "Block Group 1 in Tract 147.03",
    geography: "census_block_group",
    population: 1736
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-147-03",
    name: "Block Group 2 in Tract 147.03",
    geography: "census_block_group",
    population: 624
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-141",
    name: "Block Group 2 in Tract 141.0",
    geography: "census_block_group",
    population: 1254
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-141",
    name: "Block Group 1 in Tract 141.0",
    geography: "census_block_group",
    population: 1096
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-141",
    name: "Block Group 3 in Tract 141.0",
    geography: "census_block_group",
    population: 1934
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-148-03",
    name: "Block Group 1 in Tract 148.03",
    geography: "census_block_group",
    population: 1573
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-148-03",
    name: "Block Group 2 in Tract 148.03",
    geography: "census_block_group",
    population: 1725
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-147-05",
    name: "Block Group 1 in Tract 147.05",
    geography: "census_block_group",
    population: 1526
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-147-05",
    name: "Block Group 2 in Tract 147.05",
    geography: "census_block_group",
    population: 1472
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-147-05",
    name: "Block Group 3 in Tract 147.05",
    geography: "census_block_group",
    population: 2084
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-2",
    name: "Block Group 3 in Tract 2.0",
    geography: "census_block_group",
    population: 524
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-2",
    name: "Block Group 1 in Tract 2.0",
    geography: "census_block_group",
    population: 1445
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-2",
    name: "Block Group 2 in Tract 2.0",
    geography: "census_block_group",
    population: 307
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-2",
    name: "Block Group 4 in Tract 2.0",
    geography: "census_block_group",
    population: 392
  },
  {
    __typename: "MhcLocation",
    id: "bg-6-2",
    name: "Block Group 6 in Tract 2.0",
    geography: "census_block_group",
    population: 1891
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-27",
    name: "Block Group 1 in Tract 27.0",
    geography: "census_block_group",
    population: 1428
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-27",
    name: "Block Group 2 in Tract 27.0",
    geography: "census_block_group",
    population: 1226
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-25",
    name: "Block Group 3 in Tract 25.0",
    geography: "census_block_group",
    population: 1033
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-25",
    name: "Block Group 1 in Tract 25.0",
    geography: "census_block_group",
    population: 792
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-25",
    name: "Block Group 2 in Tract 25.0",
    geography: "census_block_group",
    population: 1438
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-24",
    name: "Block Group 4 in Tract 24.0",
    geography: "census_block_group",
    population: 1139
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-24",
    name: "Block Group 1 in Tract 24.0",
    geography: "census_block_group",
    population: 540
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-24",
    name: "Block Group 3 in Tract 24.0",
    geography: "census_block_group",
    population: 811
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-24",
    name: "Block Group 2 in Tract 24.0",
    geography: "census_block_group",
    population: 1450
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-140",
    name: "Block Group 4 in Tract 140.0",
    geography: "census_block_group",
    population: 2387
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-140",
    name: "Block Group 2 in Tract 140.0",
    geography: "census_block_group",
    population: 601
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-140",
    name: "Block Group 3 in Tract 140.0",
    geography: "census_block_group",
    population: 1906
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-140",
    name: "Block Group 1 in Tract 140.0",
    geography: "census_block_group",
    population: 426
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-164-01",
    name: "Block Group 1 in Tract 164.01",
    geography: "census_block_group",
    population: 1874
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-143",
    name: "Block Group 3 in Tract 143.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-143",
    name: "Block Group 2 in Tract 143.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-10",
    name: "Block Group 3 in Tract 136.1",
    geography: "census_block_group",
    population: 2844
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-116",
    name: "Block Group 1 in Tract 116.0",
    geography: "census_block_group",
    population: 1051
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-114",
    name: "Block Group 1 in Tract 114.0",
    geography: "census_block_group",
    population: 875
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-114",
    name: "Block Group 2 in Tract 114.0",
    geography: "census_block_group",
    population: 1401
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-114",
    name: "Block Group 3 in Tract 114.0",
    geography: "census_block_group",
    population: 930
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-113",
    name: "Block Group 1 in Tract 113.0",
    geography: "census_block_group",
    population: 1653
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-113",
    name: "Block Group 2 in Tract 113.0",
    geography: "census_block_group",
    population: 687
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-143",
    name: "Block Group 1 in Tract 143.0",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-137",
    name: "Block Group 1 in Tract 137.0",
    geography: "census_block_group",
    population: 1257
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-137",
    name: "Block Group 3 in Tract 137.0",
    geography: "census_block_group",
    population: 673
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-137",
    name: "Block Group 2 in Tract 137.0",
    geography: "census_block_group",
    population: 2254
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-10",
    name: "Block Group 1 in Tract 136.1",
    geography: "census_block_group",
    population: 1472
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-136-10",
    name: "Block Group 4 in Tract 136.1",
    geography: "census_block_group",
    population: 391
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-130",
    name: "Block Group 1 in Tract 130.0",
    geography: "census_block_group",
    population: 1259
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-130",
    name: "Block Group 2 in Tract 130.0",
    geography: "census_block_group",
    population: 716
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-07",
    name: "Block Group 3 in Tract 136.07",
    geography: "census_block_group",
    population: 1762
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-07",
    name: "Block Group 2 in Tract 136.07",
    geography: "census_block_group",
    population: 1398
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-136-07",
    name: "Block Group 4 in Tract 136.07",
    geography: "census_block_group",
    population: 686
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-08",
    name: "Block Group 1 in Tract 136.08",
    geography: "census_block_group",
    population: 1125
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-08",
    name: "Block Group 2 in Tract 136.08",
    geography: "census_block_group",
    population: 1081
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-04",
    name: "Block Group 2 in Tract 136.04",
    geography: "census_block_group",
    population: 1751
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-04",
    name: "Block Group 3 in Tract 136.04",
    geography: "census_block_group",
    population: 1413
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-04",
    name: "Block Group 1 in Tract 136.04",
    geography: "census_block_group",
    population: 1157
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-116",
    name: "Block Group 2 in Tract 116.0",
    geography: "census_block_group",
    population: 2469
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-117",
    name: "Block Group 2 in Tract 117.0",
    geography: "census_block_group",
    population: 460
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-117",
    name: "Block Group 1 in Tract 117.0",
    geography: "census_block_group",
    population: 1528
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-139-01",
    name: "Block Group 3 in Tract 139.01",
    geography: "census_block_group",
    population: 707
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-139-01",
    name: "Block Group 2 in Tract 139.01",
    geography: "census_block_group",
    population: 1002
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-136-11",
    name: "Block Group 1 in Tract 136.11",
    geography: "census_block_group",
    population: 1850
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-136-11",
    name: "Block Group 2 in Tract 136.11",
    geography: "census_block_group",
    population: 1448
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-136-11",
    name: "Block Group 3 in Tract 136.11",
    geography: "census_block_group",
    population: 1765
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-164-01",
    name: "Block Group 2 in Tract 164.01",
    geography: "census_block_group",
    population: 2293
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-118",
    name: "Block Group 2 in Tract 118.0",
    geography: "census_block_group",
    population: 1279
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-04",
    name: "Block Group 2 in Tract 112.04",
    geography: "census_block_group",
    population: 2050
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-04",
    name: "Block Group 1 in Tract 112.04",
    geography: "census_block_group",
    population: 1175
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-03",
    name: "Block Group 2 in Tract 112.03",
    geography: "census_block_group",
    population: 601
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-02",
    name: "Block Group 1 in Tract 112.02",
    geography: "census_block_group",
    population: 2816
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-01",
    name: "Block Group 1 in Tract 112.01",
    geography: "census_block_group",
    population: 1703
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-01",
    name: "Block Group 2 in Tract 112.01",
    geography: "census_block_group",
    population: 1298
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-111",
    name: "Block Group 2 in Tract 111.0",
    geography: "census_block_group",
    population: 2080
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-111",
    name: "Block Group 1 in Tract 111.0",
    geography: "census_block_group",
    population: 2710
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-151",
    name: "Block Group 2 in Tract 151.0",
    geography: "census_block_group",
    population: 1147
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-162",
    name: "Block Group 3 in Tract 162.0",
    geography: "census_block_group",
    population: 871
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-162",
    name: "Block Group 2 in Tract 162.0",
    geography: "census_block_group",
    population: 1213
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-161",
    name: "Block Group 2 in Tract 161.0",
    geography: "census_block_group",
    population: 933
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-161",
    name: "Block Group 1 in Tract 161.0",
    geography: "census_block_group",
    population: 1239
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-166-01",
    name: "Block Group 2 in Tract 166.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-166-01",
    name: "Block Group 3 in Tract 166.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-166-01",
    name: "Block Group 1 in Tract 166.01",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-156",
    name: "Block Group 1 in Tract 156.0",
    geography: "census_block_group",
    population: 1042
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-156",
    name: "Block Group 2 in Tract 156.0",
    geography: "census_block_group",
    population: 1731
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-150",
    name: "Block Group 2 in Tract 150.0",
    geography: "census_block_group",
    population: 471
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-150",
    name: "Block Group 1 in Tract 150.0",
    geography: "census_block_group",
    population: 450
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-150",
    name: "Block Group 3 in Tract 150.0",
    geography: "census_block_group",
    population: 1297
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-150",
    name: "Block Group 4 in Tract 150.0",
    geography: "census_block_group",
    population: 1592
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-06",
    name: "Block Group 2 in Tract 112.06",
    geography: "census_block_group",
    population: 2538
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-06",
    name: "Block Group 1 in Tract 112.06",
    geography: "census_block_group",
    population: 1343
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-112-05",
    name: "Block Group 2 in Tract 112.05",
    geography: "census_block_group",
    population: 812
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-112-05",
    name: "Block Group 1 in Tract 112.05",
    geography: "census_block_group",
    population: 1187
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-134",
    name: "Block Group 1 in Tract 134.0",
    geography: "census_block_group",
    population: 587
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-134",
    name: "Block Group 2 in Tract 134.0",
    geography: "census_block_group",
    population: 943
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-134",
    name: "Block Group 3 in Tract 134.0",
    geography: "census_block_group",
    population: 616
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-133",
    name: "Block Group 2 in Tract 133.0",
    geography: "census_block_group",
    population: 762
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-133",
    name: "Block Group 1 in Tract 133.0",
    geography: "census_block_group",
    population: 1057
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-132",
    name: "Block Group 1 in Tract 132.0",
    geography: "census_block_group",
    population: 899
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-132",
    name: "Block Group 3 in Tract 132.0",
    geography: "census_block_group",
    population: 1290
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-132",
    name: "Block Group 2 in Tract 132.0",
    geography: "census_block_group",
    population: 557
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-131",
    name: "Block Group 1 in Tract 131.0",
    geography: "census_block_group",
    population: 743
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-131",
    name: "Block Group 3 in Tract 131.0",
    geography: "census_block_group",
    population: 666
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-131",
    name: "Block Group 2 in Tract 131.0",
    geography: "census_block_group",
    population: 883
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-135-03",
    name: "Block Group 4 in Tract 135.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-135-03",
    name: "Block Group 2 in Tract 135.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-135-03",
    name: "Block Group 3 in Tract 135.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-135-03",
    name: "Block Group 1 in Tract 135.03",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-135-01",
    name: "Block Group 4 in Tract 135.01",
    geography: "census_block_group",
    population: 1229
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-135-01",
    name: "Block Group 1 in Tract 135.01",
    geography: "census_block_group",
    population: 1273
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-135-01",
    name: "Block Group 3 in Tract 135.01",
    geography: "census_block_group",
    population: 1412
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-135-01",
    name: "Block Group 2 in Tract 135.01",
    geography: "census_block_group",
    population: 1709
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-118",
    name: "Block Group 1 in Tract 118.0",
    geography: "census_block_group",
    population: 1207
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-407",
    name: "Block Group 3 in Tract 407.0",
    geography: "census_block_group",
    population: 1924
  },
  {
    __typename: "MhcLocation",
    id: "bg-3-433",
    name: "Block Group 3 in Tract 433.0",
    geography: "census_block_group",
    population: 1869
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-407",
    name: "Block Group 2 in Tract 407.0",
    geography: "census_block_group",
    population: 1691
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-407",
    name: "Block Group 1 in Tract 407.0",
    geography: "census_block_group",
    population: 2014
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-151",
    name: "Block Group 1 in Tract 151.0",
    geography: "census_block_group",
    population: 2420
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-162",
    name: "Block Group 1 in Tract 162.0",
    geography: "census_block_group",
    population: 408
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-432-02",
    name: "Block Group 1 in Tract 432.02",
    geography: "census_block_group",
    population: 1344
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-504-06",
    name: "Block Group 1 in Tract 504.06",
    geography: "census_block_group",
    population: 1156
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-501-04",
    name: "Block Group 2 in Tract 501.04",
    geography: "census_block_group",
    population: 2306
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-107-02",
    name: "Block Group 1 in Tract 107.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-4-107-02",
    name: "Block Group 4 in Tract 107.02",
    geography: "census_block_group",
    population: null
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-149-09",
    name: "Block Group 1 in Tract 149.09",
    geography: "census_block_group",
    population: 2169
  },
  {
    __typename: "MhcLocation",
    id: "bg-1-104",
    name: "Block Group 1 in Tract 104.0",
    geography: "census_block_group",
    population: 625
  },
  {
    __typename: "MhcLocation",
    id: "bg-2-149-09",
    name: "Block Group 2 in Tract 149.09",
    geography: "census_block_group",
    population: 1819
  }
];
