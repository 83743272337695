"use client";

import { Stack } from "@mui/material";

import { FcWithChildren } from "common/components/types";

import { useLayoutStore } from "common/state/useLayoutStore";

const TopicMainContent: FcWithChildren = ({ children }) => {
  const { desktopNavIsOpen, desktopContainerWidth, desktopTransform } = useLayoutStore(
    (state) => state
  );
  const baseSx = {
    flex: 1,
    transition: "min-width .25s ease-in-out, transform .25s ease-in-out",
    maxWidth: "100%",
    overflow: "clip",
    position: "relative"
  };
  const sx = desktopNavIsOpen
    ? baseSx
    : {
        ...baseSx,
        minWidth: { xs: "100vw", md: desktopContainerWidth },
        transform: { xs: "none", md: desktopTransform }
      };
  return <Stack sx={sx}>{children}</Stack>;
};

export default TopicMainContent;
