"use client";

import { usePathname } from "next/navigation";
import { Box } from "@mui/material";

import { SideNavMapProps } from "layout/SideNav/SideNavMap";
import MiniMapWithLoadedLocation from "common/components/GeoMap/MiniMapWithLoadedLocation";
import {
  getAvailablePageTypeFromPath,
  SideNavCprButton
} from "./SideNavCprButton/SideNavCprButton";
import { SideNavHeading } from "./SideNavHeading";

export const SideNavMapWithLoadedLocation: React.FC<SideNavMapProps> = ({
  location,
  topic,
  locations,
  allowLocationChange = true
}) => {
  const pathname = usePathname() as string;

  return (
    <Box sx={{ boxShadow: 1, pb: "10px" }}>
      <SideNavHeading title="Currently Selected Location" />
      <Box mx="20px">
        <MiniMapWithLoadedLocation
          location={location}
          topic={topic}
          locations={locations}
          allowLocationChange={allowLocationChange}
        />
        <SideNavCprButton
          locationId={location.id}
          currentGeography={location.geography}
          page={getAvailablePageTypeFromPath(pathname)}
        />
      </Box>
    </Box>
  );
};
