"use client";

import React, { ReactNode } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Skeleton } from "@mui/material";

import { MhcAttributionFragment } from "graphqlApi/types";

import { linkColor } from "theme/colors";

import { AttributionPopoverBase } from "common/components/AttributionPopoverBase";

export const DownloadDataButtonFallback = () => (
  <Skeleton
    width={160}
    height={36}
    variant="rectangular"
    sx={{ display: "block", alignSelf: "self-end" }}
  />
);

type Props = {
  attributions: MhcAttributionFragment[];
  color?: string;
  label?: ReactNode;
};

const DownloadDataButton: React.FC<Props> = ({
  attributions,
  color = linkColor,
  label = "Download Data"
}) => (
  <AttributionPopoverBase
    id={`attributions-menu`}
    attributions={attributions}
    uiLocation="Page Header"
  >
    <Button
      variant="outlined"
      endIcon={<DownloadIcon />}
      sx={{
        textTransform: "none",
        color: color,
        borderColor: color
      }}
    >
      {label}
    </Button>
  </AttributionPopoverBase>
);

export default DownloadDataButton;
