import { ReactNode } from "react";
import Link from "next/link";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardProps,
  Link as MuiLink,
  Stack,
  Typography
} from "@mui/material";

type Props = CardProps & {
  href?: string;
  cardImage?: ReactNode;
  newTopic?: boolean;
};

export const TopicCard: React.FC<Props> = ({
  variant = "outlined",
  children,
  title,
  href,
  cardImage,
  newTopic = false,
  ...props
}) => {
  const content = (
    <Stack direction="column" gap={0} flex={1} sx={{ height: "100%" }}>
      {cardImage && (
        <CardMedia>
          <Stack sx={{ position: "relative" }}>
            {cardImage}{" "}
            {newTopic === true && (
              <Stack
                sx={{
                  position: "absolute",
                  top: "auto",
                  bottom: "16px",
                  left: "16px",
                  right: "auto",
                  backgroundColor: "#FFE815",
                  borderRadius: "8px",
                  color: "component.textColor",
                  px: 1,
                  py: 0.5
                }}
              >
                <Typography variant="caption">NEW UPDATE</Typography>
              </Stack>
            )}
          </Stack>
        </CardMedia>
      )}
      <Stack direction="column" gap={2} flex={1} p={2}>
        {title && (
          <Typography gutterBottom variant="h4" component="div" color="black">
            {title}
          </Typography>
        )}
        {children && (
          <Typography variant="body2" component="div" sx={{ textDecoration: "none" }} color="black">
            {children}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  if (href) {
    return (
      <Card variant={variant} sx={{ mb: 2 }} {...props}>
        <MuiLink component={Link} href={href} sx={{ display: "flex", flex: 1 }}>
          <CardActionArea component="span">{content}</CardActionArea>
        </MuiLink>
      </Card>
    );
  }

  return (
    <Card variant={variant} sx={{ mb: 2 }} {...props}>
      {content}
    </Card>
  );
};
