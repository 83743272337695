"use client";

import { Box, Skeleton } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION } from "layout/configuration";

import { pageHeaderWrapperStyle } from "./PageHeader";

export const PageHeaderFallback = () => {
  return (
    <Box sx={pageHeaderWrapperStyle()}>
      <Box sx={{ pt: 4, pb: 4 }}>
        <Skeleton
          width="40%"
          variant="rectangular"
          height={45}
          animation={DEFAULT_SKELETON_ANIMATION}
        />
      </Box>
    </Box>
  );
};
