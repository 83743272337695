import {
  MhcAlteredLocationFragment,
  MhcFeatureCollection,
  MhcLocationFragment
} from "graphqlApi/types";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";

export const locationNamesPhrase = (locations: MhcLocationFragment[]) => {
  return locations
    .map((l) => l.name)
    .join(", ")
    .replace(/,(?=[^,]+$)/, `${locations.length > 2 ? ", " : " "}and`);
};

// The backend sends boundaries as GeoJSON but we need location objects for child components
// This function takes the feature collection and constructs locations based on the feature properties
export const locationFromGeoJson = (
  geoJson: MhcFeatureCollection,
  currentLocation: MhcAlteredLocationFragment
): MhcLocationFragment => {
  const {
    id = "",
    name = "",
    NAME = ""
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = (geoJson.features[0]?.properties ?? {}) as any; // We need to update the BE to use the new properties
  return {
    __typename: "MhcLocation",
    mapData: geoJson,
    id,
    geography: currentLocation.geography,
    name: NAME || name || ""
  };
};

interface LocationPathArgs {
  locationId?: string | null;
}
export const locationPath = ({ locationId }: LocationPathArgs): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathWithParamKeys, params } = useRouterPath();
  const { locationId: _locationId, ..._params } = params;
  const pathWithLocationId = pathWithParamKeys.replace("[locationId]", locationId ?? "state");
  return Object.entries(_params).reduce(
    (path, [key, value]) => value && path?.replace(`[${key}]`, value as string),
    pathWithLocationId
  );
};
