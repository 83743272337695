"use client";

import { MhcTopicFragment } from "graphqlApi/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import DashboardContentNav from "layout/DashboardContent/DashboardContentNav";
import { SideNavProps } from "layout/SideNav";
import { SideNavAppRouter } from "layout/SideNav/SideNavAppRouter";
import { LocationWithGeoJson } from "layout/SideNav/SideNavMap";
import TopicNavigationWrapper from "layout/Topics/TopicNavigationWrapper";
import { useLayoutStore } from "common/state/useLayoutStore";
import { useLocationStore } from "common/state/useLocationStore";

export type TopicNavigationProps = Omit<SideNavProps, "location" | "topic"> & {
  location?: LocationWithGeoJson | null;
  topic?: MhcTopicFragment | null;
};

export default function TopicNavigationContent({
  items,
  selectedItemId,
  allowLocationChange,
  location,
  locations,
  topic
}: TopicNavigationProps) {
  const isMobile = useIsMobile();
  const { desktopNavIsOpen, mobileNavIsOpen, toggleDesktopNav, toggleMobileNav, desktopTransform } =
    useLayoutStore((state) => state);
  const setLocation = useLocationStore((state) => state.setLocation);
  if (location) setLocation(location);
  return (
    <TopicNavigationWrapper
      desktopTransform={desktopTransform}
      mobileNavIsOpen={mobileNavIsOpen}
      isMobile={isMobile}
    >
      <DashboardContentNav
        desktopNavIsOpen={desktopNavIsOpen}
        mobileNavIsOpen={mobileNavIsOpen}
        handleCloseMobileMenu={toggleMobileNav}
        handleCloseDesktopMenu={toggleDesktopNav}
      >
        <SideNavAppRouter
          allowLocationChange={allowLocationChange}
          items={items}
          location={location}
          locations={locations}
          selectedItemId={selectedItemId}
          topic={topic}
        />
      </DashboardContentNav>
    </TopicNavigationWrapper>
  );
}
