import { cloneElement, SyntheticEvent, useCallback, useMemo } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Link, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import clsx from "clsx";

import { SITE_HEADER_HEIGHT } from "layout/configuration";
import { linkColor } from "theme/colors";
import { sendGaNavigationEvent } from "common/util/googleAnalytics";
import { useGetTextColor } from "layout/SideNav/SideNavMenu/util/useGetTextColor";

import { NavItem } from "layout/SideNav/SideNavMenu/SideNavMenu";

interface Props {
  item: NavItem;
  selected: boolean;
  active: boolean;
  anyChildSelected: boolean;
}

export const useHasItems = (item: NavItem) => {
  return !isNil(item.items) && !isEmpty(item.items);
};

export const SideNavText: React.FC<Props> = ({ item, selected, active, anyChildSelected }) => {
  const { level, title, items } = item;
  const hasItems = useHasItems(item);
  const textColor = useGetTextColor({
    level,
    items,
    active,
    selected,
    anyChildSelected
  });

  const fontWeight = useMemo(() => {
    if (level != 1) {
      if (active === true || selected === true) return 600;
      return 400;
    }
    return 600;
  }, [level, active, selected]);
  // Handle anchor clicks by scrolling smoothly to content container
  // if it exists
  const handleItemClick = useCallback((event: SyntheticEvent, itemTitle: string) => {
    const anchor = event.currentTarget as HTMLAnchorElement;
    const url = new URL(anchor.href ?? "");
    if (anchor.href) {
      sendGaNavigationEvent({
        category: "Side Nav",
        action: "Item Click",
        label: itemTitle
      });
    }
    if (!url?.hash) return;
    event.preventDefault();
    const anchoredContent = document.getElementById(url.hash.slice(1));
    if (!anchoredContent) return;
    const y =
      anchoredContent.getBoundingClientRect().top + window.pageYOffset - SITE_HEADER_HEIGHT * 2;
    window.scrollTo({ top: y, behavior: "smooth" });
  }, []);

  const icon = useMemo(() => {
    if (!isNil(item.icon) && !isEmpty(item.icon)) return item.icon;
    if (level > 3) {
      if (selected === true) return <RadioButtonCheckedIcon />;
      return <RadioButtonUncheckedIcon />;
    }
    return null;
  }, [item.icon, level, selected]);

  return (
    <>
      {!isNil(icon) && (
        <Box
          sx={{
            fontSize: 14,
            display: "flex",
            color: active || selected ? linkColor : "#9E9E9E",
            my: 0
          }}
          aria-hidden="true"
        >
          {icon && cloneElement(icon, { fontSize: "inherit" })}
        </Box>
      )}
      <Box
        className={clsx({ selected, active })}
        sx={{
          flexGrow: 1,
          color: textColor,
          fontSize: 14,
          "&.selected":
            item.level > 1 || item.category !== "all_topics"
              ? {
                  color: linkColor
                }
              : undefined
        }}
      >
        <Link
          href={item.href || "#"}
          onClick={(event) => {
            handleItemClick(event, typeof item.title === "string" ? item.title : "");
          }}
          title={typeof item.title === "string" ? item.title : item.title?.toString() ?? ""}
          sx={{
            textDecoration: "none",
            color: "inherit",
            textOverflow: "wrap",
            wordWrap: "break-word",
            overflow: "hidden",
            whiteSpace: "initial",
            display: "block",
            fontSize: 14,
            lineHeight: "18px",
            pr: !hasItems ? "16px" : undefined,
            "&:focus": {
              outlineWidth: 2,
              outlineColor: "brand.main",
              outlineStyle: "solid",
              borderRadius: "2px"
            }
          }}
          aria-label={`Go to ${item.title?.toString() ?? ""} page`}
        >
          <Typography
            component="span"
            variant="inherit"
            sx={{
              fontWeight,
              opacity: active || selected ? 1 : 0.85
            }}
          >
            {title}
          </Typography>
        </Link>
      </Box>
    </>
  );
};
