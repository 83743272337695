"use client";

import { Stack } from "@mui/material";

import { FcWithChildren } from "common/components/types";

import { SITE_HEADER_HEIGHT } from "layout/configuration";
import { cssMediaQuery } from "common/util/style";

type TopicNavigationWrapperProps = {
  isMobile?: boolean;
  mobileNavIsOpen?: boolean;
  desktopTransform?: string;
  height?: string;
};

const TopicNavigationWrapper: FcWithChildren<TopicNavigationWrapperProps> = ({
  children,
  mobileNavIsOpen = false,
  desktopTransform = ""
}) => {
  return (
    <Stack
      component="aside"
      id="side-nav-container"
      sx={{
        transition: "transform .25s ease-in-out",
        [cssMediaQuery()]: {
          display: mobileNavIsOpen ? "none" : "block",
          transform: mobileNavIsOpen ? "" : "transform(-100%)"
        },
        justifyContent: "center",
        position: "sticky",
        top: `${SITE_HEADER_HEIGHT}px`,
        transform: desktopTransform
      }}
    >
      {children}
    </Stack>
  );
};

export default TopicNavigationWrapper;
