"use client";

import uniq from "lodash/uniq";
import { create } from "zustand";

import { MhcAttribution, MhcAttributionFragment } from "graphqlApi/types";

type AttributionsInState = (MhcAttributionFragment | MhcAttribution)[];

type State = {
  attributions: Record<string, AttributionsInState>;
  registeredKeys: string[];
};

type Actions = {
  setAttributions: (key: string, attributions: AttributionsInState) => void;
  addAttributions: (key: string, attributions: AttributionsInState) => void;
  getAttributionsByKey: (key: string) => AttributionsInState;
};

export const useAttributionsStore = create<State & Actions>((set, get) => ({
  attributions: {},
  registeredKeys: [],
  getAttributionsByKey: (key) => get().attributions[key] ?? [],
  setAttributions: (key, attributions: AttributionsInState) =>
    set((state) => {
      state.registeredKeys = uniq([...Object.keys(state.attributions), key]);
      if (attributions) {
        state.attributions[key] = attributions as AttributionsInState;
      }
      return { ...state };
    }),
  addAttributions: (key, attributions: AttributionsInState) =>
    set((state) => {
      state.registeredKeys = uniq([...Object.keys(state.attributions), key]);
      if (attributions) {
        const existing = state.attributions[key] ?? [];
        state.attributions[key] = [...attributions, ...existing] as AttributionsInState;
      }
      return { ...state };
    })
}));
