import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { Stack, Typography } from "@mui/material";

const LocationSwitcherFallback = () => (
  <Stack
    sx={{
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      background: "rgba(255,255,255,.95)",
      zIndex: 10000,
      border: "1px solid #f9f9f9",
      inset: 0,
      borderRadius: 4
    }}
  >
    <Stack width="65%" maxWidth={500} gap={2} position="relative" id="new-location-loading">
      <Stack gap={1}>
        <Typography variant="h3" textAlign="center">
          Loading new location...
        </Typography>
        <Typography variant="body1" textAlign="center" fontWeight={400}>
          This may take a minute.
        </Typography>
      </Stack>
      <ProgressBar
        height="5px"
        color="#245194"
        options={{ showSpinner: false, parent: "#location-loading" }}
        shallowRouting
      />
    </Stack>
  </Stack>
);

export default LocationSwitcherFallback;
