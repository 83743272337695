import { Box } from "@mui/material";
import compact from "lodash/compact";

import { MhcFeatureCollection, MhcLocationFragment } from "graphqlApi/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import ThumbnailGeoMap, { ThumbnailMapTitle } from "../GeoMap/ThumbnailGeoMap";

interface Props {
  titles: ThumbnailMapTitle[];
  locations: {
    previous: MhcLocationFragment[];
    altered: MhcLocationFragment[];
  };
}

export const geoJsonFromLocations = (locations: MhcLocationFragment[]): MhcFeatureCollection => {
  const features: MhcFeatureCollection["features"] = (() => {
    const feature = compact(locations.map((l) => l.mapData?.features[0]).flat()).filter(
      (f) => f !== undefined
    );
    return feature.filter((f) => f !== null && f !== undefined) as MhcFeatureCollection["features"];
  })();

  return {
    features: features,
    type: "FeatureCollection"
  };
};

const LocationComparisonMaps: React.FC<Props> = ({ locations, titles }) => {
  const { previous, altered } = locations;
  const isMobile = useIsMobile();
  return (
    <Box display="flex" gap={{ xs: 1.5, md: 3 }}>
      {[previous, altered].map((locations, i) => (
        <ThumbnailGeoMap
          size={isMobile ? 110 : undefined}
          key={`location-compairson-map-${i}`}
          geoJSON={geoJsonFromLocations(locations)}
          title={titles[i] ?? undefined}
        />
      ))}
    </Box>
  );
};

export default LocationComparisonMaps;
