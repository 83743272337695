import React from "react";
import Link from "next/link";
import { Link as MuiLink, Typography } from "@mui/material";

import { MhcLocationFragment } from "graphqlApi/types";

import { sendLocationSwitcherEvent } from "./util/events";
import { updateLocationOnCurrentPath } from "./util/updateLocationOnCurrentPath";
import { GaEventActions } from "common/util/googleAnalytics";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import { LocationSwitcherProps } from "./LocationSwitcher";

interface LocationSwitcherBreadcrumbProps {
  location: LocationSwitcherProps["location"];
  currentTopicName?: string;
  navigateToLocation: (nv?: MhcLocationFragment) => void;
}

export const LocationSwitcherBreadcrumb: React.FC<LocationSwitcherBreadcrumbProps> = ({
  location,
  currentTopicName,
  navigateToLocation
}) => {
  const { pathWithParamKeys, params } = useRouterPath();

  return (
    <MuiLink
      sx={{
        display: "flex",
        flexDirection: "row",
        lineHeight: "24px",
        maxHeight: "48px",
        overflow: "hidden",
        lineClamp: "2",
        minWidth: "min-content"
      }}
      component={Link}
      href={
        updateLocationOnCurrentPath({
          locationId: location.id ?? "",
          pathname: pathWithParamKeys,
          params
        }) as string
      }
      onClick={(event) => {
        event.preventDefault();
        navigateToLocation(location);
        sendLocationSwitcherEvent({
          parameters: {
            action: GaEventActions.BreadcrumbClick,
            label: location.name,
            ui_location: currentTopicName
          }
        });
      }}
    >
      <Typography fontWeight={600} component="span" variant="body1">
        {location.name}
      </Typography>
    </MuiLink>
  );
};
