import { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { MhcGeographyEnum } from "graphqlApi/types";

import { GoToCommunityButton } from "./GoToCommunityButton";
import { ViewCommunityProfileReportButton } from "./ViewCommunityProfileReportButton";

const AvailablePageTypes = [
  "topics",
  "ecdc",
  "flu",
  "cpr",
  "indicator-browser",
  "stories"
] as const;

export type AvailablePageType = (typeof AvailablePageTypes)[number];

export const getAvailablePageTypeFromPath = (path: string): AvailablePageType | undefined => {
  if (!path) return undefined;
  for (const pType of AvailablePageTypes) {
    if (path.includes(pType)) {
      return pType;
    }
  }
  return undefined;
};

interface SideNavCprButtonProps {
  locationId: string;
  currentGeography: MhcGeographyEnum;
  page?: AvailablePageType;
}

export const SideNavCprButton: React.FC<SideNavCprButtonProps> = ({
  locationId,
  currentGeography,
  page
}) => {
  const locationHasCprReport = useMemo(() => {
    return currentGeography === "census_tract" || currentGeography === "zip_code";
  }, [currentGeography]);

  if (page !== "cpr" && !locationHasCprReport && currentGeography !== "state") {
    return null;
  }

  return (
    <Box sx={{ py: "3px" }}>
      {page === "cpr" && (
        <Box
          sx={{
            backgroundColor: "rgba(242, 242, 241, 0.5)",
            height: "58px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Typography
            variant="caption"
            lineHeight="18px"
            sx={{ my: "auto", maxWidth: "150px" }}
            textAlign="center"
          >
            Currently Viewing Community Profile Report
          </Typography>
        </Box>
      )}
      {page !== "cpr" && currentGeography === "state" && (
        <ViewCommunityProfileReportButton href="/portals/cpr">
          View Community Profile Reports
        </ViewCommunityProfileReportButton>
      )}
      {page !== "cpr" && locationHasCprReport && (
        <GoToCommunityButton href={`/portals/cpr/${locationId}`}>
          Go to Community Profile Report
        </GoToCommunityButton>
      )}
    </Box>
  );
};
