import { MhcLocationFragment } from "graphqlApi/types";

export const mostPopularLocations: MhcLocationFragment[] = [
  {
    __typename: "MhcLocation",
    name: "Sussex County",
    id: "county-sussex",
    geography: "county"
  },
  {
    __typename: "MhcLocation",
    name: "New Castle County",
    id: "county-new-castle",
    geography: "county"
  },
  {
    __typename: "MhcLocation",
    name: "Kent County",
    id: "county-kent",
    geography: "county"
  },
  {
    __typename: "MhcLocation",
    name: "Zip Code 19703",
    id: "zip-code-19703",
    geography: "zip_code"
  },
  {
    __typename: "MhcLocation",
    name: "Wilmington",
    id: "wilmington",
    geography: "municipality"
  },
  {
    __typename: "MhcLocation",
    name: "Zip Code 19801",
    id: "zip-code-19801",
    geography: "zip_code"
  },
  {
    __typename: "MhcLocation",
    name: "Zip Code 19720",
    id: "zip-code-19720",
    geography: "zip_code"
  }
  // {
  //   __typename: "MhcLocation",
  //   name: "Zip Code 19802",
  //   id: "zip-code-19802",
  //   geography: "zip_code"
  // }
];
