import { MhcCategoryFragment, MhcPageFragment } from "graphqlApi/types";

export const dataDownload: MhcCategoryFragment & { children: MhcPageFragment[] } = {
  __typename: "MhcCategory",
  id: "data_download",
  label: "Data Download",
  icon: null,
  href: null,
  children: [
    {
      __typename: "MhcTopic",
      parent: null,
      children: [
        {
          __typename: "MhcTopic",
          id: "42942",
          children: [],
          href: "/data-dictionary/data-sources",
          name: "Data Sources",
          pageId: null,
          slug: "data-sources",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42943",
          children: [],
          href: "/data-dictionary/dataset-metadata",
          name: "Datasets",
          pageId: null,
          slug: "dataset-metadata",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42944",
          children: [],
          href: "/data-dictionary/indicators",
          name: "Indicators",
          pageId: null,
          slug: "indicators",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42945",
          children: [],
          href: "/data-dictionary/targets",
          name: "Targets",
          pageId: null,
          slug: "targets",
          topicType: "topic"
        },
        {
          __typename: "MhcTopic",
          id: "42946",
          children: [],
          href: "/data-dictionary/coming-soon",
          name: "Coming Soon",
          pageId: null,
          slug: "coming-soon",
          topicType: "topic"
        }
      ],
      id: "42941",
      category: "data_download",
      href: "/data-dictionary",
      name: "Data Download",
      pageId: "dataDictionary",
      slug: "data-dictionary",
      topicType: "topic",
      description: ""
    }
  ]
};
