import { useCallback, useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNextOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import isNil from "lodash/isNil";

import { BreadcrumbLink } from "./types";
import { MhcPageFragment, MhcTopic } from "graphqlApi/types";

import useScrollSpy from "common/util/hooks/useScrollSpy";

import { BreadcrumbLinkComponent } from "./BreadcrumbLink";

export type PageSection = {
  name: string;
  id: string;
};

interface TopicBreadcrumbProps {
  topic?: MhcPageFragment | MhcPageFragment;
  locationId?: string;
  subLocationName?: string;
  subLocationId?: string;
  subTopic?: PageSection;
  sectionId?: string;
  sectionName?: string;
  pageSections?: PageSection[];
}

interface BuildLinksProps {
  topic: TopicBreadcrumbProps["topic"];
  locationId: TopicBreadcrumbProps["locationId"];
  subLocationId: TopicBreadcrumbProps["subLocationId"];
  subLocationName: TopicBreadcrumbProps["subLocationName"];
  activePageSection: PageSection | null;
  subTopic?: PageSection;
}

const getParent = (topic: MhcTopic): BreadcrumbLink | null => {
  if (topic.parent) {
    return {
      href: topic.parent.href,
      title: topic.parent.name,
      last: false
    };
  }
  return null;
};

const buildLinks = ({
  topic,
  locationId,
  subLocationId,
  activePageSection,
  subLocationName,
  subTopic
}: BuildLinksProps) => {
  const homeLink: BreadcrumbLink = {
    href: "/home",
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    title: "Home"
  };
  const links: BreadcrumbLink[] = [homeLink];
  const parentLinks: BreadcrumbLink[] = [];
  let _topic = topic;
  if (_topic && topic) {
    let hasParent = !isNil(_topic.parent);
    while (hasParent) {
      const parentLink = getParent(_topic as MhcTopic);
      if (parentLink) parentLinks.push(parentLink);
      if (isNil(parent)) hasParent = false;
      else {
        _topic = _topic?.parent as MhcPageFragment;
        hasParent = !isNil(_topic?.parent);
      }
    }
    parentLinks.reverse().forEach((link) => links.push(link));
    links.push({
      href: topic.href,
      title: topic.name,
      last: locationId || subLocationId ? false : true
    });
  }

  if (subLocationId || activePageSection) {
    if (activePageSection) {
      links.push({
        href: `#${activePageSection.id}`,
        title: activePageSection.name,
        last: true
      });
    } else {
      links.push({
        href: [topic?.href, subLocationId].join("/"),
        title: subLocationName,
        last: true
      });
    }
  }

  subTopic && links.push({ title: subTopic.name, last: true });

  return links;
};

export const TopicBreadcrumbs = ({
  topic,
  locationId,
  sectionId,
  sectionName,
  subLocationId,
  subLocationName,
  subTopic,
  pageSections = []
}: TopicBreadcrumbProps) => {
  const pathname = usePathname();
  const [activePageSection, setActivePageSection] = useState<PageSection | null>(
    sectionId && sectionName ? { name: sectionName, id: sectionId } : null
  );
  const [links, setLinks] = useState<BreadcrumbLink[]>([]);
  const onActiveItemChange = useCallback(
    (itemId: string) => {
      setActivePageSection(pageSections.find(({ id }) => id === itemId) ?? null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSections, pathname]
  );

  useScrollSpy({
    items: pageSections.map(({ id }) => id),
    onActiveItemChange,
    offset: 5
  });

  useEffect(() => {
    setLinks(
      buildLinks({ topic, locationId, subLocationId, activePageSection, subLocationName, subTopic })
    );
  }, [
    activePageSection,
    locationId,
    pathname,
    subLocationId,
    subLocationName,
    subTopic,
    topic,
    topic?.id
  ]);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{ alignItems: "center" }}
    >
      {links.map((link, index) => (
        <BreadcrumbLinkComponent key={index} link={link} />
      ))}
    </Breadcrumbs>
  );
};
